import React, { useEffect } from 'react';
import HeaderLogin from './global-components/login-header';
import ForgetPassword from './section-components/forgetPassword';
import Footer from './global-components/footer';


const LoginPage = (props) => {

    
    return <div>
        <HeaderLogin />
        <ForgetPassword history={props.history}/>
        <Footer 
            footerCss={{position:'fixed', bottom:0, width:'100%'}}
        />
    </div>
}

export default LoginPage

