import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getDevelopment } from './../../actions'
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from 'redux';
import { API_URL } from './../../url/apiUrl';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import './custom.css';
class Slider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: 'marketing',
			tempSelect: 'brochure',
			vimeoLink: false,
			isImage: false,
			isVideo: false,
			linkCopyModalOpen: false,
			linkCopy: null,
			textCoppied: false
		}
	}

	componentDidMount() {
		// NotificationManager.success('Success message', 'Title here');
	}

	openShareButton = (linkCopy, sliderdata) => {
		if (sliderdata.vimeo_share_url) {
			this.setState({ linkCopy: sliderdata.vimeo_share_url });
			setTimeout(() => {
				const copyText = sliderdata.vimeo_share_url;
				navigator.clipboard.writeText(copyText);
				document.execCommand("copy");
				NotificationManager.success('link is copied');
				this.setState({ linkCopy: null });
			}, 1000);
		} else {
			if (sliderdata.flipbookLink && sliderdata.flipbookLink.trim() !== '') {
				this.setState({ linkCopy: sliderdata.flipbookLink });
				setTimeout(() => {
					const copyText = sliderdata.flipbookLink;
					navigator.clipboard.writeText(copyText);
					document.execCommand("copy");
					NotificationManager.success('link is copied');
					this.setState({ linkCopy: null });
				}, 1000);
			}
			else {
				this.setState({ linkCopy });
				setTimeout(() => {
					const copyText = linkCopy;
					navigator.clipboard.writeText(copyText);
					document.execCommand("copy");
					NotificationManager.success('link is copied');
					this.setState({ linkCopy: null });
				}, 1000);
			}
		}
	}
	closeModalLink = () => this.setState({ linkCopyModalOpen: false, linkCopy: null, textCoppied: false });
	/********************** Copy Text Script **************/
	coppiedText = () => {
		const copyText = this.inputVal;
		copyText.select();
		copyText.setSelectionRange(0, 99999); /*For mobile devices*/
		document.execCommand("copy");
		this.setState({ textCoppied: true });
		setTimeout(() => {
			this.closeModalLink();
		}, 2000);
	}
	/********************** Copy Text Script **************/
	download(link) {
		var filename = link.split('/').pop().split('#')[0].split('?')[0];
		document.getElementById('preloader').style.display = 'block';
		fetch(link, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
			},
		})
			.then((response) => response.blob())
			.then((blob) => {

				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					filename,
				);
				// Append to html link element page
				document.body.appendChild(link);
				//////////////////// Start download
				link.click();
				// Clean up and remove the link
				link.parentNode.removeChild(link);
				document.getElementById('preloader').style.display = 'none';
			});
		// var filename = link.split('/').pop().split('#')[0].split('?')[0];
		// var a = document.createElement('a');
		// a.setAttribute('href', link);
		// a.setAttribute('target', '_blank');
		// a.setAttribute('download', filename);
		// a.style.display = 'none';
		// document.body.appendChild(a);
		// a.click();
		// a.download = true;
		// document.body.removeChild(a);
	}

	openModal = (link, type) => this.setState({ isModalOpen: true, link: link, type: type });
	closeModal = () => this.setState({ isModalOpen: false, link: null, type: null, vimeoLink: false });
	showView = (link, typeview, sliderdata) => {
		if (sliderdata.flipbookLink && sliderdata.flipbookLink.trim() !== '') {
			var a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display: none";
			a.href = sliderdata.flipbookLink;
			a.target = '_blank';
			a.download = true;
			a.click();
			window.URL.revokeObjectURL(sliderdata.flipbookLink);
		} else
			if (typeview !== 'cgi' && typeview !== 'video' && typeview !== 'vimeoVideo') {
				window.open("/#/doc-view?url=" + link, "_blank")
				// var a = document.createElement("a");
				// document.body.appendChild(a);
				// a.style = "display: none";
				// a.href = 'https://docs.google.com/viewer?url=' + link + "&response-content-disposition=inline&embedded=true"
				// a.target = '_blank';
				// a.download = true;
				// a.click();
				// window.URL.revokeObjectURL(link);
			}
			else if (typeview === 'cgi') {
				this.openModal(link, 'image')
			}
			else if (typeview === 'video' || typeview === 'vimeoVideo') {
				if (sliderdata.vimeo_url) {
					link = sliderdata.vimeo_url
					this.setState({ vimeoLink: true })
				}
				this.openModal(link, sliderdata.type)
			}
	}
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/';
		let imagealt = 'image';
		const responsive = {
			superLargeDesktop: {
				breakpoint: { max: 4000, min: 3000 },
				items: 6,
				slidesToSlide: 1,
				partialVisibilityGutter: 40
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 6,
				slidesToSlide: 1,
				partialVisibilityGutter: 40
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
				slidesToSlide: 1,
				partialVisibilityGutter: 30
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
				slidesToSlide: 1
			}
		};
		const { developmentDetail, parentSelect, childSelect, agentU } = this.props;
		const { isModalOpen, link, type, linkCopyModalOpen, linkCopy, vimeoLink } = this.state
		return <div className="slider-section padding-slide">
			<div className="container">
				<Modal
					open={isModalOpen}
					onClose={() => { this.closeModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share-d" style={{ width: '80%' }}>
						<div className="close-div"><span class="close" onClick={this.closeModal}>&times;</span></div>
						<div className="modal-custom">
							<div className="modal-content-custom">
								{type == 'image' && <img src={link} />}
								{type == 'video' && vimeoLink == false &&
									<video width="100%" height="auto" controls>
										<source src={link} ></source>
										Your browser does not support the video tag.
									</video>

								}
								{vimeoLink && vimeoLink == true &&
									<><div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
										<iframe src={link}
											frameborder="0"
											allow="autoplay; fullscreen; picture-in-picture"
											allowfullscreen
											style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
											title="Redditch Development | Construction Update | June 2021"></iframe>
									</div>
										<script src="https://player.vimeo.com/api/player.js"></script>
									</>
								}
							</div>
						</div>
					</Grid>
				</Modal>
				{
					developmentDetail && developmentDetail[parentSelect] && developmentDetail[parentSelect][childSelect] &&
					<Carousel
						showDots={false}
						arrows={true}
						autoPlaySpeed={3000}
						autoPlay={false}
						centerMode={false}
						className="slider"
						responsive={responsive}>
						{developmentDetail[parentSelect][childSelect] && developmentDetail[parentSelect][childSelect].map((sliderdata) => (
							<div className="slider-item">
								<img src={sliderdata.thumbnail ? (API_URL.BucketURl + sliderdata.thumbnail.filename) : (publicUrl + 'assets/img/development/broucher.png')} alt={imagealt} />
								<div className="slider-metadata">
									<p>{sliderdata.name}</p>
									<ul>
										{(sliderdata.fileInfo || sliderdata?.vimeo_url) && <li><a target="_blank" onClick={(e) => this.showView(API_URL.BucketURl + sliderdata?.fileInfo?.filename || sliderdata.vimeo_url, childSelect, sliderdata)}><i className="fa fa-eye shareTypeIcon" /></a></li>}
										{(sliderdata.fileInfo) && <li><a target="_blank" onClick={(e) => { this.download(API_URL.BucketURl + sliderdata?.fileInfo?.filename) }}><i className="fa fa-download shareTypeIcon" /></a></li>}
										{(sliderdata.fileInfo || sliderdata?.vimeo_share_url) && <li onClick={(e) => this.openShareButton(API_URL.BucketURl + sliderdata?.fileInfo?.filename, sliderdata)}><i className="fa fa-share shareTypeIcon" /></li>}
									</ul>
									<ReactTooltip />
								</div>
							</div>))}
					</Carousel>
				}
			</div >
			<Modal
				open={linkCopyModalOpen}
				onClose={() => { this.closeModalLink() }}
				className={'modal-classes-data'}
			>
				<Grid className="rejectBoxCntnt">
					<span class="close" onClick={this.closeModalLink}>&times;</span>
					<div className="copyButton" onClick={() => { this.coppiedText() }}> Copy Link </div>
					<div className="modal-custom">
						{this.state.textCoppied &&
							<div className="submitPdfSuccessMsg">
								Link is Copied
							</div>
						}
						<div id="shareLinkText">
							{linkCopy}
						</div>
						{linkCopy != null &&
							<input
								ref={(inputVal) => this.inputVal = inputVal}
								type="text"
								className="copyTextValue"
								value={linkCopy}
							/>
						}
					</div>
				</Grid>
			</Modal>
		</div >
	}
}
const mapStateToProps = (state) => {
	const { developmentDetail, parentSelect, childSelect } = state.development
	const { user, token, userType } = state.authUser;
	return {
		developmentDetail, user, token, userType, parentSelect, childSelect
	};
}
const mapDispatchToProps = dispatch => bindActionCreators({ getDevelopment }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Slider);