import React, { useState, useEffect } from 'react';
import './PdfControls.css';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
const FIT_LABELS = {
    page: 'Fit to Page',
    width: 'Fit to Width'
};
const noop = () => {};
const nextFit = fitTo => (fitTo === 'page' ? 'width' : 'page');

export default class PdfControls extends React.PureComponent 
 {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.props.onZoomToFit(nextFit(this.props.fitTo))
    }
    
    render(){
    const { fitTo, onZoomToFit = noop, onZoomIn, onZoomOut,page, pageCount } = this.props
    return( <div className="pdf-controls">
        <div className="pdf-controls-container">
            <div  className="pageCount">Page: {page} / {pageCount}</div> 
           
            <button className="zoom-in" onClick={onZoomIn} title="Zoom In">
            <AddIcon/>
                <span className="sr-only">Zoom In</span>
            </button>
            <button
                className="zoom-to-fit"
                onClick={() => onZoomToFit(nextFit(fitTo))}
                title={FIT_LABELS[nextFit(fitTo)]}
            >
                <span aria-hidden>&#9713;</span>
                <span className="sr-only">{FIT_LABELS[nextFit(fitTo)]}</span>
            </button>
            <button className="zoom-out" onClick={onZoomOut} title="Zoom Out">
                <RemoveIcon></RemoveIcon>
                <span className="sr-only">Zoom Out</span>
            </button>
        </div>
    </div>
    )
    }
 };
