import { API_URL } from './../url/apiUrl';
import axios from 'axios'
export const getSearchResult = async (token, searchform) => {
	return (axios.get(`${API_URL.getSearchResult + '?bedrooms=' + searchform.bedrooms + '&development=' + searchform.development + '&max_price=' + searchform.max_price + '&min_price=' + searchform.min_price + '&getHold=' + searchform.getHold + '&status=' + searchform.status + '&type=' + searchform.type + '&getAvail=' + searchform.getAvail}`,
		{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'token': `Bearer ` + token
			}
		})
	)
}
export const convertHtmlToPdf = async (token, htmlFormData) => {
	return (axios.post(`${API_URL.getHtmlResult}`, htmlFormData,
		{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'token': `Bearer ` + token
			}
		})
	)
}
export const getOneCalculation = async (token) => {
	return (axios.get(`${API_URL.getOneCalculation}`,
		{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'token': `Bearer ` + token
			}
		})
	);
}
export const getAllCalculations = async (token) => {
	return (axios.get(`${API_URL.getAllCalculations}`,
		{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'token': `Bearer ` + token
			}
		})
	);
}
export const signin = async (email, password) => {
	return (axios.post(API_URL.loginUser + "?email=" + email + "&password=" + password, {
		email: email,
		password: password
	}, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	})
	)
}

export const getDevelopmentDetails = async (token, devid) => {
	return (axios.get(API_URL.getDevelopmentDetails + "?id=" + devid, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'token': `Bearer ` + token
		}
	})
	)
}

export const getAllDevelopmentDetails = async (token) => {
	return (axios.get(API_URL.getAgentDevelopment, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'token': `Bearer ` + token
		}
	})

	)
}

export const getTokenForVerification = async (token) => {
	return (axios.get(`${API_URL.checkToken}=${token}`, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'token': `Bearer ` + token
		}
	})
	)
}

export const getDevelopmentUnitDetails = async (token, id, unitid) => {
	return (axios.get(`${API_URL.getDevelopmentUnitDetails}?unitid=${unitid}${id ? '&id=' + id : ''}`, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'token': `Bearer ` + token
		}
	})
	)
}

export const setHoldDevStatus = async (token, id, unitid, status, parkingSpaces) => {
	return (axios.put(`${API_URL.updateOneUnitStatusDevelopment}?unitid=${unitid}${id ? '&id=' + id : ''}&status=${status}`,
		{
			status: status,
			id: id,
			unitid: unitid,
			parkingSpaces: parkingSpaces
		}, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'token': `Bearer ` + token
		}
	})
	)
}


export const getNotificationApi = async (token) => {
	return (axios.get(`${API_URL.getNotification}`,
		{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'token': `Bearer ` + token
			}
		})
	)
}


export const updateNotificationApi = async (token) => {
	return (axios.post(`${API_URL.updateNotification}`, {},
		{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'token': `Bearer ` + token
			}
		})
	)
}



export const getUserType = (callback) => {

	let user_type = null
	let userToken = localStorage.getItem('tokenweb');
	axios.get(API_URL.getRole, {
		headers: {
			'token': "bearer " + userToken,
			'Content-Type': 'application/json'
		}
	}).then(res => {
		if (res.data.success) {
			user_type = res.data.data.userType

			callback(res.data.data, user_type, null)
		}
		else
			if (res.data.type === 'UNAUTHORIZATION_ACCESS') {
				callback(res.data.data, user_type, res.data.type)
			}
	}).catch(err => { console.log("Error", err) })

}



export const setParkiSpacestoLS = async (development) => {
	let unitParkingFromLS = JSON.parse(localStorage.getItem("unitParkingSpace"))
	let filteredData = []
	if (unitParkingFromLS && unitParkingFromLS.length > 0) {

	} else {
		await development.parking && development.parking.map(async (park, i) => {
			if (park.status.value != 'available') {
				if (unitParkingFromLS && unitParkingFromLS.length > 0) {
					// await unitParkingFromLS.map((parkLS, index) => {
					// 	if (parkLS.value !== park._id) {

					// 	}
					// })
				} else {
					let data = {
						"value": park._id,
						"label": park.parking_no,
						"unit_id": park.unit_id
					}
					filteredData.push(data)
				}
			}
		})
		localStorage.setItem("unitParkingSpace", JSON.stringify(filteredData))
	}

}