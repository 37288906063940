import React, { useEffect } from 'react';
import HeaderLogin from './global-components/login-header';
import Login from './section-components/login';
import Footer from './global-components/footer';


const LoginPage = (props) => {

    let inputStyle = {
        position: "fixed", bottom: 0, width: '100%'
    };
    return <div>
        <HeaderLogin />
        <Login history={props.history} />
        <Footer
            footerCss={"footerCSS"}
        />
    </div>
}

export default LoginPage

