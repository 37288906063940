import React, { useEffect } from 'react';
import Sample from './section-components/sample';
import Footer from './global-components/footer';


const SamplePage = (props) => {
    return <div>
        <Sample history={props} />
        <Footer SampleCSS={"SampleCSS"}/>
    </div>
}

export default SamplePage

