import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch, Redirect } from "react-router-dom";

import HomeV1 from './../components/home-v1';
import { getUserType } from './../api'
import DevelopmentDetails from './../components/development-details';
import About from './../components/about';

import Error from './../components/error';
import Faq from './../components/faq';
import SendMail from './../components/sendMail';
import Contact from './../components/contact';

import LoginPage from './../components/login-page';
import forgetPassword from "./../components/forget-password-page"
import resetPassword from "./../components/reset-password-page"
import { connect } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { userTypeSuccess } from './../actions/AuthActions';
import { bindActionCreators } from 'redux';
import DocView from "../components/global-components/doc-view"
import SamplePage from "../components/sample-page";
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, authUser, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />}
    />;

class App extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        getUserType((user, usertype, error) => {
            let paths = this.props.location.pathname.split('/')
            if (paths.includes('signin') || paths.includes('change-password')) {

            }
            else {
                if (!error) this.props.userTypeSuccess(user, usertype)
                else this.props.logoutUserFromFirebase()
            }

        })
    }



    render() {
        const { location, match, user, token } = this.props;

        if (location && location.pathname === '/') {
            if (token === null) {
                return (<Redirect to={'/login'} />);
            } else {
                return (<Redirect to={'/home-v1'} />);
            }
        }
        return (
            <div>
                {/* <NotificationContainer /> */}
                <Route exact path="/login" component={LoginPage} />
                {/* <Route path="/sample" component={SamplePage} /> */}
                {/* <Route exact path="/" component={HomeV1} /> */}
                <InitialPath
                    path={`${match.url}home-v1`}
                    authUser={token}
                    component={HomeV1}
                />


                <Route path="/forget-password" component={forgetPassword} />
                <Route path="/reset-password" component={resetPassword} />
                <Route path="/sample/:id" component={SamplePage} />

                <Route path="/about" component={About} />


                <Route path="/error" component={Error} />
                <Route path="/faq" component={Faq} />

                <Route path="/contact" component={Contact} />
                <Route path="/sendmail" component={SendMail} />

                <InitialPath
                    path={`${match.url}development-details`}
                    authUser={token}
                    component={DevelopmentDetails}
                />
                <Route exact path="/doc-view" component={DocView} />
                {/* <Route path="/development-details" component={DevelopmentDetails} /> */}

                <NotificationContainer />
            </div>
        )
    }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
    const { token, user } = authUser;
    return { token, user };
};
const mapDispatchToProps = dispatch => bindActionCreators({ userTypeSuccess }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);


