/**
 * Root Sagas
 */
import { all } from 'redux-saga/effects';

// sagas
import authSagas from './Auth';
import searchSagas from './Search';
import developmentSagas from './Development';
import notificationSagas from './Notification'

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        searchSagas(),
        developmentSagas(),
        notificationSagas()
    ]);
}