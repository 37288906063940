import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { API_URL } from '../../url/apiUrl';
import './custom.css';
export const getCurrentDate = () => {
	var today = new Date();
	var dd = String(today.getDate()).padStart(2, '0');
	var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	var yyyy = today.getFullYear();
	today = dd + '/' + mm + '/' + yyyy;
	return today;
}

export const returnPdfHtml = (selectedItem, getPdfFormValues, agentUserData, dataUsage, ternsAndCond, fullLogoUrl, locationUnit) => {
	let htmlData = `<!DOCTYPE html>\
 <html style="margin: 0 auto !important;  line-height: 1.8; padding: 0 !important;height: 100% !important;width: 100% !important;" lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">\
 <head>\
				 <meta charset="utf-8">\
				 <meta name="viewport" content="width=device-width">\
				 <meta http-equiv="X-UA-Compatible" content="IE=edge">\
				 <meta name="x-apple-disable-message-reformatting">\
				 <title></title>\
				 <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap" rel="stylesheet">\
					<style>
					h1,h2,h3,h4,h5,h6{
						font-family: 'Open Sans', sans-serif;
						color: #000000;
						margin-top: 0;
					}
					html,body{
						font-family: "Open Sans";
						font-family: 'Open Sans', sans-serif;
						font-weight: 400;
					}
					.btn{
						padding: 10px 15px;
					}
					.btn.btn-primary{
						border-radius: 30px;
						background: #fff;
						color: #ffffff;
					}
				.checkbox {
						width:10px;
						height:10px;
						border: 1px solid #cdcdcd;
						display: inline-block;
						border-radius:2px;
						margin-top:10px;
				}
				</style>
 </head>\
 <body style="margin: 0; line-height: 1.7; padding: 0 !important; margin: 40px 50px!important; font-size:11px; mso-line-height-rule: exactly; color:#586772; background-color: #fff;">\
	 <center style="background-color: #fff;">\
				 <div style="max-width: 1000px; margin: 0 auto; background-color: #fff;" class="email-container">\
						 <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">\
				 <tr>\
						<td valign="top" style="padding-top:5px;">\
								<table cellspacing="0" cellpadding="0" border="0" >\
										<tr>\
												<td style="vertical-align: center;width: 310px; max-width: 319px;">${fullLogoUrl ? `<img src="${fullLogoUrl}" alt="" style="max-width: 319px; height: 'auto'">` : '' }</td>\
												<td align="right" style="padding-left:10px;vertical-align: center;">\
														<p style="margin:0; padding:0; font-size:15px; line-height: 22px;">CONFIDENTIAL DOCUMENT</p>\
														<p style="margin:0; padding:0; font-size:25px; line-height: 35px;">PROPERTY RESERVATION AGREEMENT</p>\
														<p style="margin:0; padding:0; font-size:12px; line-height: 18px; padding-top:5px; color:#000;">PLEASE USE BLOCK CAPITALS</p>\
												</td>\
										</tr>\
								</table>\
						</td>\
						</tr>\
						<tr>\
								<td>\
										<table align="center" role="presentation" cellspacing="0" cellpadding="7" border="0" style="margin: auto;">\
												<tr>\
														<td colspan="4" style="background-color: #697680; color: #fff; padding-left:12px;">PROPERTY DETAILS</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea; width: 100px; border-bottom: 1px solid #6c6e71; padding-left:12px;">DEVELOPMENT:</td>\
														<td colspan="3" style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${selectedItem?.title ?? ''} - ${locationUnit ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;  width: 150px;border-bottom: 1px solid #6c6e71; padding-left:12px;">FLOOR:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px; width: 200px;">${selectedItem?.floor ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71; width: 180px;border-bottom: 1px solid #6c6e71; padding-left:12px;">UNIT:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${selectedItem?.type ?? ''} - ${selectedItem?.unit_number ?? ''}</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">PURCHASER 1 DETAILS</td>\
														<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">PURCHASER 2 DETAILS</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; width: 180px; padding-left:12px;">Surname:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;width: 220px;">${getPdfFormValues?.surname_1 ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;width: 170px;border-bottom: 1px solid #6c6e71; padding-left:12px;">Surname:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;width: 230px;">${getPdfFormValues?.surname_2 ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">First Name:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.firstname_1 ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">First Name:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.firstname_2 ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">Passport Number:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.passport_1 ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Passport Number:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.passport_2 ?? ''}</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">Current Home Address:</td>\
														<td colspan="2" style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Current Home Address:</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.homeaddress_1 ?? '&nbsp;'}</td>\
														<td colspan="2" style="border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.homeaddress_2 ?? '&nbsp;'}</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="border-bottom: 1px solid #6c6e71; padding-left:12px;">&nbsp;</td>\
														<td colspan="2" style="border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">&nbsp;</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="border-bottom: 1px solid #6c6e71; padding-left:12px;">&nbsp;</td>\
														<td colspan="2" style="border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">&nbsp;</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">Contact Number:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.contactnumber_1 ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Contact Number:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.contactnumber_2 ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">Email Address:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.emailadd_1 ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Email Address:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.emailadd_2 ?? ''}</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">PURCHASE DETAILS</td>\
														<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">PURCHASER’S LAWYER</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">Purchase Price £:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${selectedItem?.investor_price ? selectedItem?.investor_price.toLocaleString() ?? '' : ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Name:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.lawyername ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">Sale Date:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getCurrentDate()}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Phone:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.lawyerphone ?? ''}</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">EXCHANGE OF CONTRACTS</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Email:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.contract_email ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;">Expected Date of Exchange:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.expected_date ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Website:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.website ?? ''}</td>\
												</tr>\
												<tr>\
														<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">PAYMENT STRUCTURE</td>\
														<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">CLIENT ACCOUNT DETAILS</td>\
												</tr>\
												<tr>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">On Reservation:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;border-left: 1px solid #6c6e71;">${getPdfFormValues?.on_reservation ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Bank:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.bank ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">Date:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;border-left: 1px solid #6c6e71;">${getPdfFormValues?.date_new ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Address:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.address_new ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">On Exchange of Contracts:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;border-left: 1px solid #6c6e71;">${getPdfFormValues?.exchange_of ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Account Name:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.acc_name ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">&nbsp;</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;border-left: 1px solid #6c6e71;"></td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Sort Code:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.sort_code ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">On Completion:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;border-left: 1px solid #6c6e71;">${getPdfFormValues?.on_Completion ?? ''}</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">Account No.:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.acc_num ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">&nbsp;</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;border-left: 1px solid #6c6e71;"></td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">IBAN:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.iban ?? ''}</td>\
												</tr>\
												<tr>\
														<td colspan="2" rowspan="2" style="padding-left:12px; border-bottom: 1px solid #6c6e71; line-height: 15px; font-size:12">IMPORTANT: When sending monies to us, please use our full file reference/plot\
																or property number, address and your full name. Failure to do so may mean a\
																significant delay in processing your payment to your client ledger.</td>\
														<td style="background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;">BIC:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.bic ?? ''}</td>\
												</tr>\
												<tr>\
														<td style="background-color: #e6e8ea; border-bottom: 1px solid #6c6e71; border-left: 1px solid #6c6e71; padding-left:12px;">REF:</td>\
														<td style="border-bottom: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.refer ?? ''}</td>\
												</tr>\
										</table>\
								</td>\
						</tr>\
					</table>\
			</div>\
			<div style="max-width: 1000px; margin: 0 auto; background-color: #fff;" class="email-container">\
					<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" style="margin: auto;">\
						<tr>\
							<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px; height:30px">NOTES</td>\
					</tr>\
					<tr>\
							<td colspan="2" style="border-bottom: 1px solid #6c6e71; border-right: 1px solid #6c6e71; height: 60px; border-left: 1px solid #6c6e71; padding-left:12px;">${getPdfFormValues?.notes ?? ''}</td>\
					</tr>\
					<tr>\
							<td>\
									<table align="center" role="presentation" cellspacing="0" cellpadding="7" border="0" width="100%" style="margin: auto;">\
											<div style="page-break-before:always">&nbsp;</div>\
											<tr>\
													<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">RESERVATION CHECKLIST</td>\
											</tr>\
											<tr>\
													<td style="width: 50%; vertical-align: top;">\
															<div style="padding-right:20px;">\
																<p style="margin:0; padding:0;">I would like to go through the following checklist with you to confirm the reservation:</p>\
																<div>\
																${getPdfFormValues?.buying_appartment == true ?
																		`<input type="checkbox" name="" id="" style="margin-right:10px; border: 1px #ccc solid;" value=""  checked/>`:
																		`<div class="checkbox checked"></div>`
																	}
																		The unit that you are buying is\
																</div>\
																<div style="padding-left:25px;"><input type="text" name="" id="" value="${selectedItem?.type ?? ''}, ${selectedItem?.unit_number ?? ''} - Floor ${selectedItem?.floor}" style="width: 100%; padding:8px 8px; border: 1px #ccc solid;" /></div>\
																<div style="padding-left:25px; padding-top:8px;">\
																	<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">\
																			<tr>\
																					<td style="vertical-align: bottom; width:25px;">of</td>\
																					<td><input type="text" name="" id="" value="${selectedItem?.title ?? ''} - ${locationUnit ?? ''}" style="width: 100%; padding:8px 8px; border: 1px #ccc solid;" /></td>\
																			</tr> \
																	</table>\
																</div>\
																<div style="padding-top: 12px;">\
																	<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">\
																			<tr>\
																					<td style="width: 60%;">\
																							<div>\
																							${getPdfFormValues?.fee_check == true ?
																									`<input type="checkbox" name="" id="" style="margin-right:10px; border: 1px #ccc solid;" value=""  checked/>` :
																									`<div class="checkbox checked"></div>`
																							}
																									The reservation fee of £\
																									<div style="padding-left: 25px;"><input type="text" name="" id="" value="${selectedItem?.reservation_fee ? selectedItem?.reservation_fee.toLocaleString() ?? '' : ''}" style="width: 100%; padding:8px 8px; border: 1px #ccc solid;" /></div>\
																							</div>\
																					</td>\
																					<td style="width: 40%;">\
																							<div style="padding-left: 30px;">\
																									<div>Sq. Ft.</div>\
																									<div><input type="text" name="" id="" value="${Math.round(selectedItem?.aream2*10.764) ?? ''}" style="width: 60px; padding:8px 8px;  border: 1px #ccc solid;" /></div>\
																							</div>\
																					</td>\
																			</tr>\
																		</table>\
																</div>\
																<div style="padding-top:14px;">\
																	<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">\
																			<tr>\
																					<td style="vertical-align: top; width:25px;">
																					${getPdfFormValues?.once_the == true ?
																					`<input type="checkbox" name="" id="" style="border: 1px #ccc solid;" value=""  checked />` :
																					`<div class="checkbox checked"></div>`
																					}
																					</td>\
																					<td style="line-height: 19px; padding-top:4px;">\
																							Once the purchaser’s lawyer has registered your details into\
																							their system, they will contact you to explain the contract\
																							signing process.<br /><br />\
																							Upon receiving the sales contract from the lawyer, you are\
																							required to sign and send the originals back to the lawyer.\
																							You are also required to pay the balance of the deposit, after\
																							deducting the reservation fee, within 28 days of reserving\
																							the unit.\
																					</td>\
																			</tr> \
																			<tr><td style="height: 8px;"></td></tr>\
																			<tr>\
																					<td style="vertical-align: top; width:25px;">
																					${getPdfFormValues?.units_check == true ?
																					`<input type="checkbox" name="" id="" style="border: 1px #ccc solid;" value=""  checked />`:
																					`<div class="checkbox checked"></div>`
																				}
																					</td>\
																					<td style="line-height: 19px; padding-top:4px;">\
																							Units will not be guaranteed as reserved until the reservation fee\
																							has been paid.\
																					</td>\
																			</tr> \
																			<tr><td style="height: 8px;"></td></tr>\
																			<tr>\
																					<td style="vertical-align: top; width:25px;">
																					${getPdfFormValues?.the_buyer_check == true ?
																						`<input type="checkbox" name="" id="" style="border: 1px #ccc solid;" value=""  checked />`:
																						`<div class="checkbox checked"></div>`
																					}
																					</td>\
																					<td style="line-height: 19px; padding-top:4px;">\
																							The buyer acknowledges that:\
																							<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">\
																									<tr><td style="height: 8px;"></td></tr>\
																									<tr>\
																											<td style="vertical-align: top; width:25px;">a.</td>\
																											<td style="line-height: 19px; padding-top:4px;">\
																													the buyer has not been induced to enter into this Agreement\
by any representation, verbal or otherwise, made by or on\
behalf of the seller which is not set out in this Agreement; and\
																											</td>\
																									</tr>\
																									<tr><td style="height: 8px;"></td></tr> \
																									<tr>\
																											<td style="vertical-align: top; width:25px;">b.</td>\
																											<td style="line-height: 19px; padding-top:4px;">\
																													the buyer has read and understood the terms and conditions of this Agreement.\
																											</td>\
																									</tr> \
																							</table>\
																					</td>\
																			</tr> \
																	</table>\
																</div>\
															</div>\
													</td>\
													<td style="width: 50%; vertical-align: top;">\
															<div style="padding-left:10px;">\
																	<p style="margin:0; padding:0;">If you have any questions, please do not hesitate to contact me.</p>\
																	<p style="margin:0; padding:0; padding-top:8px;"><b>Sales Agent</b></p>\
																	<div>\
																			<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">\
																					<tr><td style="height: 8px;"></td></tr> \
																					<tr>\
																							<td style="vertical-align: center; width:80px;">Name:</td>\
																							<td><input type="text" name="" id="" value="${agentUserData?.firstName ?? ''} ${agentUserData?.lastName ?? ''}" style="width: 95%; padding:8px 8px; border: 1px #ccc solid;" /></td>\
																					</tr> \
																					<tr><td style="height: 7px;"></td></tr> \
																					<tr>\
																							<td style="vertical-align: center;">Agency:</td>\
																							<td><input type="text" name="" id="" value="${agentUserData?.sales_agency ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" /></td>\
																					</tr> \
																					<tr><td style="height: 7px;"></td></tr> \
																					<tr>\
																							<td style="vertical-align: center;">Contact No.:</td>\
																							<td><input type="text" name="" id="" value="${agentUserData?.sales_agent_contact ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" /></td>\
																					</tr> \
																					<tr><td style="height: 7px;"></td></tr> \
																					<tr>\
																							<td style="vertical-align: center;">Email:</td>\
																							<td><input type="text" name="" id="" value="${agentUserData?.email ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" /></td>\
																					</tr> \
																					<tr><td style="height: 7px;"></td></tr> \
																					<tr>\
																							<td style="vertical-align: center;">Signature:</td>\
																							<td><input type="text" name="" id="" value="${getPdfFormValues?.sales_agent_signature ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" /></td>\
																					</tr> \
																			</table>\
																	</div>\
																	<p style="margin:0; padding:0; padding-top:25px;">The purchase is intended by way of:</p>\
																	<div>\
																			<span style="display: inline-block;">Cash
																			${getPdfFormValues?.cash_check == true ?
																			`<input type="checkbox" style="margin-left:10px; border: 1px #ccc solid;" name="" id="" value=""  checked/>`:
																			`<div style="margin-left:10px;" class="checkbox checked"></div>`
																				}
																			</span>\
																			<span style="display: inline-block; padding-left:10px;">Mortgage
																			${getPdfFormValues?.mortage_check == true ?
																			`<input type="checkbox" style="margin-left:10px; border: 1px #ccc solid;" name="" id="" value=""  checked />`:
																			`<div style="margin-left:10px;" class="checkbox checked"></div>`
																			}
																			</span>\
																			<span style="display: inline-block; padding-left:10px;">Other
																			${getPdfFormValues?.other_check == true ?
																			`<input type="checkbox" style="margin-left:10px; border: 1px #ccc solid;" name="" id="" value=""  checked />`:
																			`<div style="margin-left:10px;" class="checkbox checked"></div>`
																			}
																			</span>\
																	</div>\
																	<p style="margin:0; padding:0; padding-top:12px;">If other, please state:</p>\
																	<div>\
																			<input type="text" name="" id="" value="${getPdfFormValues?.if_other_state ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" />\
																	</div>\
																	<p style="margin:0; padding:0; padding-top:8px;">Broker Details</p>\
																	<div style="padding-top: 3px;">\
																			<input type="text" name="" id="" value="${getPdfFormValues?.brocker_details_1 ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" />\
																	</div>\
																	<div style="padding-top: 8px;">\
																			<input type="text" name="" id="" value="${getPdfFormValues?.brocker_details_2 ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" />\
																	</div>\
																	<div style="padding-top: 8px;">\
																			<input type="text" name="" id="" value="${getPdfFormValues?.brocker_details_3 ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" />\
																	</div>\
																	<div style="padding-top: 8px;">\
																			<input type="text" name="" id="" value="${getPdfFormValues?.brocker_details_4 ?? ''}" style="width: 95%; padding:8px 8px;  border: 1px #ccc solid;" />\
																	</div>\
															</div>\
												</td>\
											</tr>\
												<tr><td colspan="2" style="height: 15px;"></td></tr>\
											<tr><td style="height: 0px;"></td></tr> \
											<tr>\
													<td style="width: 50%; vertical-align: top; line-height: 19px;"> \
															<div style="padding-right:32px;">\
															Purchaser’s Signature(s):<br />\
															<div style="border-bottom: 1px dotted #000; height:30px; margin-bottom: 17px;"></div>\
															</div>\
													</td>\
													<td style="width: 50%; vertical-align: top;  line-height: 15px;">\
															<div style="padding-left:32px;">  \
																	<div>\
																			<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">\
																			<tr>\
																				<td style="vertical-align: center; width:80px;">Print Name:</td>\
																				<td><input type="text" name="" id="" value="${getPdfFormValues?.print_name ?? ''}" style="width:95%; padding:8px 8px;  border: 1px #ccc solid;" /></td>\
																		</tr>\
																			<tr><td style="height: 20px;"></td></tr>  \
																					<tr>\
																							<td style="vertical-align: center;">Date:</td>\
																							<td></td>\
																					</tr> \
																			</table>\
																	</div>\
																	<div style="border-bottom: 1px dotted #000; height:30px; margin-bottom: 17px;"></div>\
															</div>\
													</td>\
											</tr>\
											<tr>\
													<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">TERMS AND CONDITIONS</td>\
											</tr>\
											<tr><td colspan="2" style="height: 0px;"></td></tr>\
											<tr>\
													<td colspan="2" style="vertical-align: top; line-height: 17px;"> \
															<div style="padding-right:20px;">\
															${ternsAndCond ?? ''}
															</div>\
													</td>\
													</tr>
													<tr><td colspan="2" style="height: 5px;"></td></tr>\
												<tr>\
													<td colspan="2" style="background-color: #697680; color: #fff; padding-left:12px;">DATA USAGE</td>\
											</tr>\
											<tr><td colspan="2" style="height: 0px;"></td></tr>\
											<tr>\
													<td colspan="2" style="vertical-align: top; line-height: 17px;"> \
															<div style="padding-right:20px;">\
															${dataUsage ?? ''}
															</div>\
													</td>\
													</tr>
											<tr><td style="padding-top: 30px;"></td></tr>\
										</table>\
								</td>\
						</tr>\
				</table>\
		</div>\
</center>\
 </body>\
	</html>`;
	return htmlData;
}