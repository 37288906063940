// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const LOGIN_FACEBOOK_USER = 'LOGIN_FACEBOOK_USER';
export const LOGIN_GOOGLE_USER = 'LOGIN_GOOGLE_USER';
export const LOGIN_TWITTER_USER = 'LOGIN_TWITTER_USER';
export const LOGIN_GITHUB_USER = 'LOGIN_GITHUB_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';
export const USER_TYPE_SUCCESS = 'USER_TYPE_SUCCESS';
export const TOKEN_VERIFICATION = 'TOKEN_VERIFICATION';
export const TOKEN_VERIFICATION_SUCCESS = 'TOKEN_VERIFICATION_SUCCESS';
export const TOKEN_VERIFICATION_FAILURE = 'TOKEN_VERIFICATION_FAILURE';


// Searching Actions
export const SEARCH_UNIT = 'SEARCH_UNIT';
export const SEARCH_UNIT_SUCCESS = 'SEARCH_UNIT_SUCCESS';
export const SEARCH_UNIT_FAILURE = 'SEARCH_UNIT_FAILURE';
export const AVAILABLE_SEARCH_UNIT= 'AVAILABLE_SEARCH_UNIT';
export const AVAILABLE_SEARCH_UNIT_SUCCESS= 'AVAILABLE_SEARCH_UNIT_SUCCESS';
export const AVAILABLE_SEARCH_UNIT_FAILURE= 'AVAILABLE_SEARCH_UNIT_FAILURE';


//Development details Actions
export const GET_DEVELOPMENT = 'GET_DEVELOPMENT';
export const GET_DEVELOPMENT_SUCCESS = 'GET_DEVELOPMENT_SUCCESS';
export const GET_DEVELOPMENT_FAILURE = 'GET_DEVELOPMENT_FAILURE';
export const SELECT_DEVELOPMENT_OPTION = 'SELECT_DEVELOPMENT_OPTION';
export const GET_ALL_DEVELOPMENT = 'GET_ALL_DEVELOPMENT';
export const GET_ALL_DEVELOPMENT_SUCCESS= 'GET_ALL_DEVELOPMENT_SUCCESS';
export const GET_ALL_DEVELOPMENT_FAILURE = 'GET_ALL_DEVELOPMENT_FAILURE';

export const GET_DEVELOPMENT_UNIT = 'GET_DEVELOPMENT_UNIT';
export const GET_DEVELOPMENT_UNIT_SUCCESS = 'GET_DEVELOPMENT_UNIT_SUCCESS';
export const GET_DEVELOPMENT_UNIT_FAILURE = 'GET_DEVELOPMENT_UNIT_FAILURE';

export const SET_STATUS = 'SET_STATUS';
export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';
export const SET_STATUS_FAILURE = 'SET_STATUS_FAILURE';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

//Unauthorized User 
export const UNAUTHORIZATION_ACCESS = 'UNAUTHORIZATION_ACCESS';