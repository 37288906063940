/**
 * Todo App Reducer
 */

// action types
import {
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    GET_NOTIFICATION,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE
    
} from '../actions/types';

// initial state
const INIT_STATE = {
    notification: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        // get notification
        case GET_NOTIFICATION:
            return { ...state, notification: null };

        // get notification success
        case GET_NOTIFICATION_SUCCESS:
            return { ...state, notification: action.payload };

        // get notification failure
        case GET_NOTIFICATION_FAILURE:
            return {...state, notification: null }

       
        // update notification
        case UPDATE_NOTIFICATION:
            return { ...state, notification: null };

        // update notification success
        case UPDATE_NOTIFICATION_SUCCESS:
            return { ...state, notification: action.payload };

        // update notification failure
        case UPDATE_NOTIFICATION_FAILURE:
            return {...state, notification: null }
            
        default: return { ...state };
    }
}
