import React, { Component } from 'react';
import HeaderLogo from './global-components/header-logo';
import Banner from './section-components/banner';
import SearchForm from './section-components/search-form';
import SearchResult from './section-components/search-result';
import Developments from './section-components/developments';
import Footer from './global-components/footer';
import { connect } from 'react-redux';
import { signinUserSuccess,getDevlopmentFailure, getSearchFailure, getDevelopmentUnitFailure } from "./../actions"


class Home_V1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    componentDidMount() {
        this.props.getDevlopmentFailure('empty')
        this.props.getSearchFailure();
        this.props.getDevelopmentUnitFailure();
        if (this.props.user == 'Success') {
            this.setState({ successMessage: "User logged in!" })
            setTimeout(() => {
                this.setState({ successMessage: '' })
            }, 5000)
        }
    }

    render() {
        const { searchUnits, token, history } = this.props;
        return <div>
            <HeaderLogo props={history} />
            <Banner history={history} />
            <SearchForm />
            {searchUnits && <SearchResult history={history} />}
            <Developments history={history} />
            <Footer />
        </div>
    }
}


const mapStateToProps = (state) => {
    const { searchUnits } = state.search;
    const { user, token, userType } = state.authUser;
    return {
        searchUnits, user, token, userType
    };
}


export default connect(mapStateToProps, { signinUserSuccess, getDevlopmentFailure, getSearchFailure, getDevelopmentUnitFailure },)(Home_V1);

