import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getDevelopmentUnit, setHoldStatus } from './../../actions';
import { setHoldDevStatus } from './../../api';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Select from 'react-select';
import { API_URL } from '../../url/apiUrl';
import { ParkingPopupModel } from '../blog-components/parkingPopup'
class SearchResult extends Component {
	state = {
		searchUnits: null,
		isHoldModalOpen: false,
		selectedItem: {},
		availableParking: []
	}
	componentDidMount() {
		// let i = this.props.searchUnits
		// let j = i.filter(data => {
		//     if (data.status == "available") {
		//         return data;
		//     }
		// })
		// this.setState({ searchUnits: j })
		this.setState({ searchUnits: this.props.searchUnits })
		const $ = window.$;
		let self = this
		if ($('.sortBy').length) {
			$('.sortBy').niceSelect();
			$('.sortBy').change(function (e) {
				self.onSort(e)
			})
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({ searchUnits: this.props.searchUnits })
		}
	}

	redirecTo = (redirecturl, id, unitid) => {
		this.props.getDevelopmentUnit(this.props.token, id, unitid, redirecturl, this.props.history)
	}

	openHoldModal = (unitData, index) => this.setState({ isModalHoldOpen: true, unitSelect: unitData, indexSelect: index });
	closeHoldModal = () => this.setState({ isModalHoldOpen: false, unitSelect: null, indexSelect: -1 });
	openModal = () => this.setState({ isModalOpen: true });
	closeModal = () => this.setState({ isModalOpen: false });
	openAlreadyonholdModal = () => this.setState({ isHoldModalOpen: true });
	closeAlreadyonholdModal = () => this.setState({ isHoldModalOpen: false });

	// holdUnhold = async (unitData) => {
	//     let devlopmentid = unitData.id,
	//         unitid = unitData.unit_id,
	//         status = unitData.status !== 'hold' ? 'hold' : 'available'
	//     this.props.setHoldStatus(this.props.token, devlopmentid, unitid, status);
	// }

	holdUnhold = async (unitData, index) => {
		if (unitData.status == 'hold' && !unitData['hold_by_me']) {
		}
		else { this.openHoldModal(unitData, index) }
	}

	onSort(e) {
		let sortUnit = this.state.searchUnits
		sortUnit.sort(function (a, b) {
			let nameA, nameB
			if (e.target.name == "sort_by_investor") {
				nameA = typeof a.investor_price == NaN ? parseInt(a.investor_price) : a.investor_price
				nameB = typeof b.investor_price == NaN ? parseInt(b.investor_price) : b.investor_price
			} else {
				nameA = parseInt(a.unit_number)
				nameB = parseInt(b.unit_number)
			}
			if (e.target.value == "ascendinng") {
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			}
			if (e.target.value == "descending") {
				if (nameA > nameB) {
					return -1;
				}
				if (nameA < nameB) {
					return 1;
				}
				return 0;
			}
		});
		this.setState({ searchUnits: sortUnit })
	}


	confirmHoldOnhold = () => {
		let unitData = this.state.unitSelect
		let index = this.state.indexSelect
		let devlopmentid = unitData.id,
			unitid = unitData.unit_id,
			status = unitData.status !== 'hold' ? 'hold' : 'available';
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let parkingSpaces = unitParkingSpace?.length > 0 && unitParkingSpace.filter(space => space.unit_id == unitData.unit_id)

		unitData['status'] = status
		unitData['hold_by_me'] = true
		let searchUnits = this.props.searchUnits;
		searchUnits[index]['status'] = status

		// this.props.setHoldStatus(this.props.token, devlopmentid, unitid, status);
		this.closeHoldModal()
		setHoldDevStatus(this.props.token, devlopmentid, unitid, status, parkingSpaces)
			.then((resp) => {
				if (resp.data.success) {
					if (status === 'hold') this.openModal()
				}
				else if (status === 'hold' && resp.data.type == "UNABLE_TO_UPDATE_ALREADY_HOLD") {
					this.openAlreadyonholdModal()
				}
			})
		// setTimeout(() => {
		// 	// this.closeHoldModal()
		// 	if (status === 'hold') this.openModal()
		// }, 1000);
	}


	calcParkingPrice = (item) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let price = 0
		unitParkingSpace?.length > 0 && unitParkingSpace.map(space => {
			if (item.unit_id == space.unit_id) price += item.parkingPrice
		})
		return price
	}

	retParkingSPace = (item) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let arr = []

		unitParkingSpace && unitParkingSpace.length > 0 && unitParkingSpace.map(res => {
			if (item.unit_id == res.unit_id) {
				arr.push(res.label)
			}
		})
		return arr
	}

	addParkingModel = async (item) => {
		let agentUserData = localStorage.getItem('agentUserData');
		agentUserData = JSON.parse(agentUserData);
		let availableParking = await this.availableParkingUnits(item.parkingUnits)
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let parkingSpaces = unitParkingSpace?.length > 0 && unitParkingSpace.filter(space => space.unit_id == item.unit_id)
		this.setState({ openParkingPopup: true, selectedItem: item, availableParking: availableParking, agentUserData, parkingSpaces: parkingSpaces ?? [] });
	}

	handleAddParking = async (event) => {
		let { selectedItem } = this.state
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let data = await event.map(parking => {
			parking["unit_id"] = selectedItem.unit_id
			return parking
		})
		if (unitParkingSpace?.length > 0) {
			let checkUnitUse = unitParkingSpace.filter(spaces => spaces.unit_id != selectedItem.unit_id)
			let checkUnit = unitParkingSpace.filter(spaces => spaces.unit_id == selectedItem.unit_id)
			if (checkUnit.length > data.length) {
				unitParkingSpace = [...checkUnitUse, ...data]
			} else if (checkUnit.length < data.length) {
				data.map((dat, i) => {
					if (data.length == i + 1)
						unitParkingSpace.push(dat)
				})
			}
			localStorage.setItem("unitParkingSpace", JSON.stringify(unitParkingSpace))
		} else {
			localStorage.setItem("unitParkingSpace", JSON.stringify(data))
		}
		this.setState({ parkingSpaces: event })

	}

	handleClosePopup = () => {
		this.setState({ openPopupForm: false, openParkingPopup: false })
	}

	parkingOption = (data) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		if (unitParkingSpace && unitParkingSpace.length > 0) {

			unitParkingSpace.filter(space => {
				data.map((val, index) => {
					if (val.value == space.value) data.splice(index, 1)
				})
			})
			return data
		} else {
			return data
		}
	}

	availableParkingUnits = (punits) => {
		let data = []
		punits?.length > 0 && punits.map(punit => {
			if (punit.status.value == "available") {
				data.push({ value: punit?._id, label: punit?.parking_no })
			}
		})
		return data
	}

	render() {
		const { isModalHoldOpen, isModalOpen, isHoldModalOpen, parkingSpaces, selectedItem, availableParking } = this.state
		let { searchUnits, developmentDetail } = this.props
		let availableParkingList = []
		// developmentDetail?.parking?.length > 0 && developmentDetail.parking.map(async park => {
		// 	if (park?.status?.value == 'available') {
		// 		availableParkingList.push({ value: park?._id, label: park?.parking_no })
		// 	}
		// })
		// searchUnits = searchUnits.filter(data => {
		// 	if (data.status == "available") return data;
		// })
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'
		let Customclass = this.props.Customclass ? this.props.Customclass : ''
		return <div className={"featured-area  " + Customclass}>
			<div className="container">
				<div className="section-title text-center">
					<h2 className="title">{'Search Result'}</h2>
				</div>
				{!searchUnits || searchUnits.length <= 0 && <p className="NosortResult">No result found.</p>}
				<div className="rld-main-search">
					<div className="row">
						<div className="inline_conatiner">
							<div className="inline_view">
								<div className="rld-single-select">
									<select className="select sortBy" name="sort_by_investor" onChange={(e) => this.onSort(e)} >
										<option value="" selected disabled>Sort by Investor price:</option>
										<option value="ascendinng">Price low to high</option>
										<option value="descending">Price high to low</option>
									</select>
								</div>
							</div>
							<div className="inline_view">
								<div className="rld-single-select">
									<select className="select sortBy" name="sort_by_unit" onChange={(e) => this.onSort(e)} >
										<option value="" selected disabled>Sort by Unit</option>
										<option value="ascendinng">Ascending</option>
										<option value="descending">Descending</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row justify-content-center">
					{searchUnits && searchUnits?.length > 0 && searchUnits.map((item, i) =>
						<div key={i} className="col-xl-6 col-lg-6">
							<div className="single-feature style-two">
								<div className="thumb">
									<img src={item.image} alt="img" />
									{item.icon &&
										<a href="#" className="feature-logo">
											<img src={item.image} alt={imagealt} />
										</a>
									}
								</div>
								<div className="details">
									<div className="details-wrap">
										{item.isMultiAgent && <span><img src={publicUrl + 'assets/img/multi_agent.png'} className="multi-imgs" data-tip data-for='agentid' /></span>}
										<h6 className="title readeal-top"><Link to={item.url}>{item.title}</Link></h6>
										<p className="sale_price_txt"><b>{item.type}</b></p>
										<p className="sale_price_txt"><b>Unit {item.unit_number} {item.floor} floor</b></p>
										<p className="sale_price_txt">Sale price <b>{item.sales_price ? '£' + ((250 * Math.ceil(item.sales_price / 250)) + item.totalParkingPrice).toLocaleString() : ''}</b></p>
										<p className="discount_txt">Discount <b>{item.discount ? '£' + ((250 * Math.ceil(item.sales_price / 250)) - (250 * Math.ceil(item.investor_price / 250))).toLocaleString() : ''}</b> </p>
										<p className="investor_price_txt"><span>Investor price</span> <b>{item.investor_price ? '£' + ((250 * Math.ceil(item.investor_price / 250)) + item.totalParkingPrice).toLocaleString() : ''}</b></p>
										{this.calcParkingPrice(item) > 0 && <p><a className="parkingLabel"><span>Parking Include</span></a> <b>£{item.investor_price != undefined && this.calcParkingPrice(item).toLocaleString()}</b></p>}

										<ul className="info-list">
											{item.features.map((features, i) =>
												<li key={i} ><i className={features.icon} data-tip={features.icon == "fa fa-bed" ? "Bedroom" : features.icon == "fa fa-bath" ? "Bathroom" : ''} /> {features.title}</li>
											)}
											{item.study && <li>
												<img width='5px' height='15px' src={require("../../assets/img/studyblue.svg")} data-tip="Study room" />{item.study}
											</li>}
											{item.balcony && <li>
												<img width='15px' height='15px' src={require("../../assets/img/balconyblue.svg")} data-tip="Balcony" />{item.balcony}
											</li>}
											<li><img src={publicUrl + "assets/img/icons/7.png"} alt={imagealt} /> {Math.round(item.aream2 * 10.764)} sq ft<sup>2</sup></li>
											{item.aream2 && <li><img src={publicUrl + "assets/img/icons/7.png"} alt={imagealt} /> {item.aream2} sq m<sup>2</sup></li>}
										</ul>
										<ul className="contact-list">
											<li className="readeal-top"><a className="btn btn-yellow" onClick={() => this.redirecTo(item.url, item.id, item.unit_id)} >View Details</a></li>
											{item.availStatus !== 'available' && <li className="readeal-top"><a className={item.status == 'hold' ? item['hold_by_me'] ? "hold-by-me btn" : "hold-by-other btn" : 'btn btn-gray'} disabled={item.status !== 'hold' && !item['hold_by_me']} onClick={e => this.holdUnhold(item, i)}>{item.status == 'hold' ? 'On Hold' : 'Hold'}</a></li>}

										</ul>
										<ul className="contact-list">
											{item.parking == true && this.availableParkingUnits(item.parkingUnits)?.length > 0 && <a onClick={e => this.addParkingModel(item)} className="addparkingatunit">{'+ Parking'}</a>}

										</ul>
										<ReactTooltip id='agentid' type='warning' effect='solid'>
											<span>Multi agent listing</span>
										</ReactTooltip>
									</div>
								</div>
							</div>
							<ReactTooltip />
						</div>
					)}
				</div>
				<Modal
					open={isModalHoldOpen}
					onClose={() => { this.closeHoldModal() }}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeHoldModal}>&times;</span>
						<div className="modal-custom">
							{this.state.unitSelect && <div className="modal-content-custom">
								<p>Do you want to {this.state.unitSelect.status !== 'hold' ? 'hold' : 'release'} the unit {this.state.unitSelect.unit_number}</p>
								<a onClick={e => this.confirmHoldOnhold()} className="imgHold">Yes</a>&nbsp;
								<a onClick={() => this.setState({ isModalHoldOpen: false })} className="imgHold">Cancel</a>
							</div>}
						</div>
					</Grid>
				</Modal>
				<Modal
					open={isModalOpen}
					onClose={() => { this.closeModal() }}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeModal}>&times;</span>
						<div className="modal-custom">
							<div className="modal-content-custom">
								We’ve held this apartment for you. It will be released in 48 hours unless we receive a reservation payment.
							</div>
						</div>
					</Grid>

				</Modal>
				<Modal
					open={isHoldModalOpen}
					onClose={() => { this.closeAlreadyonholdModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeAlreadyonholdModal}>&times;</span>
						<div className="modal-custom">
							<div className="modal-content-custom">
								Unfortunately this property is now on hold with another agent. The reason the 'hold' button was still displaying is because your page has not been refreshed recently.
							</div>
						</div>
					</Grid>

				</Modal>

				{/* <Modal
					open={this.state.openParkingPopup}
					onClose={() => { this.handleClosePopup() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt">
						<div className="SPCAvilPoup">
							<div ><a className='parkingtilte'>Parking for  </a>{selectedItem?.title}</div>
							<div>
								<a><img src={selectedItem?.parkingPlan?.filename ? API_URL.BucketURl + selectedItem?.parkingPlan?.filename : ''} title="" alt="" width="800px" /></a>
							</div>
							<div className="SELtSals">
								Select from the available and then click next to the sales price
							</div>
							<Grid className="SpcAvail_Container">
								<Grid container direction="row">
									<Grid item xs={12} md={6}>
										<div className="SpcaVailLeft">
											<div className="rld-single-select">
												<Select
													isMulti
													name="colors"
													onChange={this.handleAddParking}
													options={this.parkingOption(availableParking)}
													className="basic-multi-select"
													classNamePrefix="select"
													value={parkingSpaces}
												/>
											</div>

										</div>
									</Grid>
									<Grid item xs={12} md={6}>
									</Grid>
									<Grid item xs={12} md={12}>
										<div className="inline_view_btn sBMT">
											<div className="AdDMor">
												<div className="btn btn-yellow BtnAVILSPC" onClick={() => this.handleClosePopup()}>SUBMIT</div>
											</div>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Modal> */}

				<ParkingPopupModel
					handleClosePopup={this.handleClosePopup}
					submitParkingSpaces={this.handleClosePopup}
					parkingSpaces={parkingSpaces}
					parkingOption={this.parkingOption}
					availableParkingList={availableParkingList}
					openParkingPopup={this.state.openParkingPopup}
					selectedItem={selectedItem}
					developmentDetail={developmentDetail}
					searchResult={true}
					handleAddParking={this.handleAddParking} />

			</div>
		</div>



	}
}

const mapStateToProps = (state) => {
	const { searchUnits } = state.search;
	const { developmentDetail } = state.development
	const { user, token, userType } = state.authUser;
	return {
		searchUnits, user, token, userType, developmentDetail
	};
}

const mapDispatchToProps = dispatch => bindActionCreators({ getDevelopmentUnit, setHoldStatus }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);