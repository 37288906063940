import React from "react";
import { API_URL } from '../../url/apiUrl'
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';

export const PopupFrom = ({ pdfLogo,
    handleClosePopup,
    openPopupForm,
    selectedItem,
    getPdfFormValues,
    handleChangeForm,
    getCurrentDate,
    retParkingSPace,
    calcParkingPrice,
    locationUnit,
    ternsAndCond,
    dataUsage,
    showPdfSuccessMsg,
    retCalculationInvest,
    agentUserData,
    sendMail }) => {
    return (
        <Modal
            open={openPopupForm}
            onClose={() => { handleClosePopup() }}
            className={'modal-classes-data'}
        >
            <Grid className="rejectBoxCntnt" style={{ width: '80%' }}>
                <body className="pdf_body" width="100%">
                    <center className="pdf_center">
                        <div className="email-container">
                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                <tr>
                                    <td valign="top" className="table_1_tr_td">
                                        <table cellspacing="0" cellpadding="0" border="0" width="100%">
                                            <tr>
                                                <td className="verticalAlign" ><img src={API_URL.BucketURl + pdfLogo} alt="" className="top_logo" /></td>
                                                <td align="right" className="verticalAlignPad">
                                                    <p className="pTop">CONFIDENTIAL DOCUMENT</p>
                                                    <p className="pTop2">PROPERTY RESERVATION AGREEMENT</p>
                                                    <p className="pTop3">PLEASE USE BLOCK CAPITALS</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="paddingClass">
                                        <table align="center" role="presentation" cellspacing="0" cellpadding="7" border="0" width="100%" className="table_1">
                                            <tr>
                                                <td colspan="4" className="pDetails">PROPERTY DETAILS</td>
                                            </tr>
                                            <tr>
                                                <td className="developmentClass">DEVELOPMENT:</td>
                                                <td colspan="3" className="developmentShow">{`${selectedItem?.title ?? ''} - ${locationUnit ?? ''}`}</td>
                                            </tr>
                                            <tr>
                                                <td className="floorClass">FLOOR:</td>
                                                <td className="floorClass2">{selectedItem?.floor ?? null}</td>
                                                <td className="apartmentClass">UNIT:</td>
                                                <td className="developmentShow">{`${selectedItem?.type ?? ''} - ${selectedItem?.unit_number ?? ''}`}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="pDetails">PURCHASER 1 DETAILS</td>
                                                <td colspan="2" className="pDetails">PURCHASER 2 DETAILS</td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Surname:</td>
                                                <td className="developmentShow"><input type="text" name="surname_1" id="surname_1" value={getPdfFormValues?.surname_1 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Surname:</td>
                                                <td className="developmentShow"><input type="text" name="surname_2" id="surname_2" value={getPdfFormValues?.surname_2 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">First Name:</td>
                                                <td className="developmentShow"><input type="text" name="firstname_1" id="firstname_1" value={getPdfFormValues?.firstname_1 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">First Name:</td>
                                                <td className="developmentShow"><input type="text" name="firstname_2" id="firstname_2" value={getPdfFormValues?.firstname_2 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Passport Number:</td>
                                                <td className="developmentShow"><input type="text" name="passport_1" id="passport_1" value={getPdfFormValues?.passport_1 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Passport Number:</td>
                                                <td className="developmentShow"><input type="text" name="passport_2" id="passport_2" value={getPdfFormValues?.passport_2 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="borderWithBack">Current Home Address:</td>
                                                <td colspan="2" className="borderWithBack2">Current Home Address:</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="developmentShow"><input type="text" name="homeaddress_1" id="homeaddress_1" value={getPdfFormValues?.homeaddress_1 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td colspan="2" className="borderbotWithLeft"><input type="text" name="homeaddress_2" id="homeaddress_2" value={getPdfFormValues?.homeaddress_2 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="developmentShow">&nbsp;</td>
                                                <td colspan="2" className="borderbotWithLeft">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="developmentShow">&nbsp;</td>
                                                <td colspan="2" className="borderbotWithLeft">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Contact Number:</td>
                                                <td className="developmentShow"><input type="text" name="contactnumber_1" id="contactnumber_1" value={getPdfFormValues?.contactnumber_1 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Contact Number:</td>
                                                <td className="developmentShow"><input type="text" name="contactnumber_2" id="contactnumber_2" value={getPdfFormValues?.contactnumber_2 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Email Address:</td>
                                                <td className="developmentShow"><input type="email" name="emailadd_1" id="emailadd_1" value={getPdfFormValues?.emailadd_1 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Email Address:</td>
                                                <td className="developmentShow"><input type="email" name="emailadd_2" id="emailadd_2" value={getPdfFormValues?.emailadd_2 ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="pDetails">PURCHASE DETAILS</td>
                                                <td colspan="2" className="pDetails">PURCHASER’S LAWYER</td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Purchase Price £:</td>
                                                <td className="developmentShow"><input type="text" name="purchaseprice" id="purchaseprice" value={`${selectedItem?.investor_price ? selectedItem?.investor_price.toLocaleString() ?? '' : ''}`} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Name:</td>
                                                <td className="developmentShow"><input type="text" name="lawyername" id="lawyername" value={getPdfFormValues?.lawyername ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Sale Date:</td>
                                                <td className="developmentShow"><input type="text" name="sale_date" id="sale_date" value={getCurrentDate()} onChange={handleChangeForm} className="inputTypeClass_input" disabled /></td>
                                                <td className="borderWithBack2">Phone:</td>
                                                <td className="developmentShow"><input type="text" name="lawyerphone" id="lawyerphone" value={getPdfFormValues?.lawyerphone ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="pDetails">EXCHANGE OF CONTRACTS</td>

                                                <td className="borderWithBack2">Email:</td>
                                                <td className="developmentShow"><input type="email" name="contract_email" id="contract_email" value={getPdfFormValues?.contract_email ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Expected Date of Exchange:</td>
                                                <td className="developmentShow"><input type="email" name="expected_date" id="expected_date" value={getPdfFormValues?.expected_date ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Website:</td>
                                                <td className="developmentShow"><input type="url" name="website" id="website" value={getPdfFormValues?.website ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="pDetails">PAYMENT STRUCTURE</td>
                                                <td colspan="2" className="pDetails">CLIENT ACCOUNT DETAILS</td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">On Reservation:</td>
                                                <td className="developmentShow"><input type="email" name="on_reservation" id="on_reservation" value={getPdfFormValues?.on_reservation ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Bank:</td>
                                                <td className="developmentShow"><input type="text" name="bank" id="bank" value={getPdfFormValues?.bank ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">Date:</td>
                                                <td className="developmentShow"><input type="text" name="date_new" id="date_new" value={getPdfFormValues?.date_new ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Address:</td>
                                                <td className="developmentShow"><input type="text" name="address_new" id="address_new" value={getPdfFormValues?.address_new ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">On Exchange of Contracts:</td>
                                                <td className="developmentShow"><input type="text" name="exchange_of" id="exchange_of" value={getPdfFormValues?.exchange_of ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Account Name:</td>
                                                <td className="developmentShow"><input type="text" name="acc_name" id="acc_name" value={getPdfFormValues?.acc_name ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">&nbsp;</td>
                                                <td className="developmentShow"></td>
                                                <td className="borderWithBack2">Sort Code:</td>
                                                <td className="developmentShow"><input type="text" name="sort_code" id="sort_code" value={getPdfFormValues?.sort_code ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">On Completion:</td>
                                                <td className="developmentShow"><input type="text" name="on_Completion" id="on_Completion" value={getPdfFormValues?.on_Completion ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                                <td className="borderWithBack2">Account No.:</td>
                                                <td className="developmentShow"><input type="text" name="acc_num" id="acc_num" value={getPdfFormValues?.acc_num ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td className="borderWithBack">&nbsp;</td>
                                                <td className="developmentShow"></td>
                                                <td className="borderWithBack2">IBAN:</td>
                                                <td className="developmentShow"><input type="text" name="iban" id="iban" value={getPdfFormValues?.iban ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" rowspan="2" className="padLeftLineHeight">IMPORTANT: When sending monies to us, please use our full file reference/plot
                                                    or property number, address and your full name. Failure to do so may mean a
                                                    significant delay in processing your payment to your client ledger.</td>
                                                <td className="borderWithBack2">BIC:</td>
                                                <td className="developmentShow">
                                                    <input type="text" name="bic" id="bic" value={getPdfFormValues?.bic ?? null} onChange={handleChangeForm} className="inputTypeClass_input" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="borderLeftBack">REF:</td>
                                                <td className="developmentShow"><input type="text" name="refer" id="refer" value={getPdfFormValues?.refer ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" className="pDetails">NOTES</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" className="borderBottomRight"><textarea name="notes" id="notes" value={getPdfFormValues?.notes ?? null} onChange={handleChangeForm} className="inputTypeClass_input" /></td>
                                            </tr>
                                            <tr><td className="table_1_tr_td"></td></tr>
                                        </table>
                                    </td>
                                </tr>

                            </table>
                        </div>
                        <div className="email-container">
                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                <tr>
                                    <td className="paddingClass">
                                        <table align="center" role="presentation" cellspacing="0" cellpadding="7" border="0" width="100%" className="table_1">
                                            <tr>
                                                <td colspan="2" className="pDetails">RESERVATION CHECKLIST</td>
                                            </tr>
                                            <tr>
                                                <td className="width50Vert">
                                                    <div className="paddRightClass">
                                                        <p className="marPad">I would like to go through the following checklist with you to confirm the reservation:</p>
                                                        <div>
                                                            <input type="checkbox" name="buying_appartment" className="checkBoxShow" id="buying_appartment" value="" checked={getPdfFormValues?.buying_appartment} onChange={handleChangeForm} />
                                                            The unit that you are buying is
                                                        </div>
                                                        <div className="padLeftClass"><input type="text" name="buyingApartment" id="buyingApartment" value={`${selectedItem?.type ?? ''}, ${selectedItem?.unit_number ?? ''} - Floor ${selectedItem?.floor}`} onChange={handleChangeForm} className="inputTypeClass" disabled /></div>
                                                        <div className="padLeftTop">
                                                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                <tr>
                                                                    <td className="vertWidth">of</td>
                                                                    <td><input type="text" name="buyingApartment_of" id="buyingApartment_of" value={`${selectedItem?.title ?? ''} - ${locationUnit ?? ''}`} onChange={handleChangeForm} className="inputTypeClass" disabled /></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="padLeftTop">
                                                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                <tr>
                                                                    <td className="vertWidthParking">Parking if selected £</td>
                                                                    <td><input type="text" name="buyingApartment_of" id="buyingApartment_of" value={calcParkingPrice(selectedItem)} onChange={handleChangeForm} className="parkingIfSelect" disabled /></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="padLeftTop">
                                                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                <tr>
                                                                    <td className="vertWidthParkingSpc">Space/s</td>
                                                                    <td><input type="text" name="buyingApartment_of" id="buyingApartment_of" value={retParkingSPace(selectedItem) || "NA"} onChange={handleChangeForm} className="parkingSpc" disabled /></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="padTop12">
                                                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                <tr>
                                                                    <td className="onlyWidth">
                                                                        <div>
                                                                            <input type="checkbox" name="fee_check" id="fee_check" className="checkBoxShow" value="" checked={getPdfFormValues?.fee_check} onChange={handleChangeForm} />
                                                                            The reservation fee of £
                                                                            <div className="padLeftClass"><input type="text" name="reservation_fee" id="reservation_fee" value={`${selectedItem?.reservation_fee ? retCalculationInvest(selectedItem, 5, 'reservation') : ''}`} onChange={handleChangeForm} className="inputTypeClass2" /></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="onlyWidth">
                                                                        <div className="padLeft120">
                                                                            <div>Sq. Ft.</div>
                                                                            <div><input type="text" name="square_fit" id="square_fit" value={Math.round(selectedItem?.aream2 * 10.764) ?? ''} onChange={handleChangeForm} className="inputTypeClass" disabled /></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="padTop14">
                                                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                <tr>
                                                                    <td className="verWith"><input type="checkbox" name="once_the" id="once_the" value="" checked={getPdfFormValues?.once_the} onChange={handleChangeForm} /></td>
                                                                    <td className="linePad">
                                                                        Once the purchaser’s lawyer has registered your details into
                                                                        their system, they will contact you to explain the contract
                                                                        signing process.<br /><br />
                                                                        Upon receiving the sales contract from the lawyer, you are
                                                                        required to sign and send the originals back to the lawyer.
                                                                        You are also required to pay the balance of the deposit, after
                                                                        deducting the reservation fee, within 28 days of reserving
                                                                        the unit.
                                                                    </td>
                                                                </tr>
                                                                <tr><td className="heightClass"></td></tr>
                                                                <tr>
                                                                    <td className="verWith"><input type="checkbox" name="units_check" id="units_check" value="" checked={getPdfFormValues?.units_check} onChange={handleChangeForm} /></td>
                                                                    <td className="linePad">
                                                                        Units will not be guaranteed as reserved until the reservation fee
                                                                        has been paid.
                                                                    </td>
                                                                </tr>
                                                                <tr><td className="heightClass"></td></tr>
                                                                <tr>
                                                                    <td className="verWith"><input type="checkbox" name="the_buyer_check" id="the_buyer_check" value="" checked={getPdfFormValues?.the_buyer_check} onChange={handleChangeForm} /></td>
                                                                    <td className="linePad">
                                                                        The buyer acknowledges that:
                                                                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                            <tr><td className="heightClass"></td></tr>
                                                                            <tr>
                                                                                <td className="verWith">a.</td>
                                                                                <td className="linePad">
                                                                                    the buyer has not been induced to enter into this Agreement
                                                                                    by any representation, verbal or otherwise, made by or on
                                                                                    behalf of the seller which is not set out in this Agreement; and
                                                                                </td>
                                                                            </tr>
                                                                            <tr><td className="heightClass"></td></tr>
                                                                            <tr>
                                                                                <td className="verWith">b.</td>
                                                                                <td className="linePad">
                                                                                    the buyer has read and understood the terms and conditions of this Agreement.
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="width50Vert">
                                                    <div className="padLeft32">
                                                        <p className="marPad">If you have any questions, please do not hesitate to contact me.</p>
                                                        <p className="marPadTop"><b>Sales Agent</b></p>
                                                        <div>
                                                            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                <tr><td className="heightClass"></td></tr>
                                                                <tr>
                                                                    <td className="verticalAlignWidth">Name:</td>
                                                                    <td><input type="text" name="sales_agent_name" id="sales_agent_name" value={`${agentUserData?.firstName ?? ''} ${agentUserData?.lastName ?? ''}`} onChange={handleChangeForm} className="inputTypeClass" disabled /></td>
                                                                </tr>
                                                                <tr><td className="heightClass7"></td></tr>
                                                                <tr>
                                                                    <td className="verticalAlign">Agency:</td>
                                                                    <td><input type="text" name="sales_agency" id="sales_agency" value={agentUserData?.sales_agency ?? null} onChange={handleChangeForm} className="inputTypeClass" disabled /></td>
                                                                </tr>
                                                                <tr><td className="heightClass7"></td></tr>
                                                                <tr>
                                                                    <td className="verticalAlign">Contact No.:</td>
                                                                    <td><input type="text" name="sales_agent_contact" id="sales_agent_contact" value={agentUserData?.sales_agent_contact ?? null} onChange={handleChangeForm} className="inputTypeClass" disabled /></td>
                                                                </tr>
                                                                <tr><td className="heightClass7"></td></tr>
                                                                <tr>
                                                                    <td className="verticalAlign">Email:</td>
                                                                    <td><input type="text" name="sales_agent_email" id="sales_agent_email" value={agentUserData?.email ?? ''} onChange={handleChangeForm} className="inputTypeClass" disabled /></td>
                                                                </tr>
                                                                <tr><td className="heightClass7"></td></tr>
                                                                <tr>
                                                                    <td className="verticalAlign">Signature:</td>
                                                                    <td><input type="text" name="sales_agent_signature" id="sales_agent_signature" value={getPdfFormValues?.sales_agent_signature ?? null} onChange={handleChangeForm} className="inputTypeClass" /></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <p className="marginPadd">The purchase is intended by way of:</p>
                                                        <div>
                                                            <span className="disInline">Cash<input type="checkbox" style={{ marginLeft: 10 }} className="checkBoxShow" name="cash_check" id="cash_check" value="" checked={getPdfFormValues?.cash_check} onChange={handleChangeForm} /></span>
                                                            <span className="disPad">Mortgage<input type="checkbox" style={{ marginLeft: 10 }} className="checkBoxShow" name="mortage_check" id="mortage_check" value="" checked={getPdfFormValues?.mortage_check} onChange={handleChangeForm} /></span>
                                                            <span className="disPad">Other<input type="checkbox" style={{ marginLeft: 10 }} className="checkBoxShow" name="other_check" id="other_check" value="" checked={getPdfFormValues?.other_check} onChange={handleChangeForm} /></span>
                                                        </div>
                                                        <p className="marginPadd2">If other, please state:</p>
                                                        <div>
                                                            <input type="text" name="if_other_state" id="if_other_state" value={getPdfFormValues?.if_other_state ?? null} onChange={handleChangeForm} className="inputTypeClass" />
                                                        </div>
                                                        <p className="marPadTop">Broker Details</p>
                                                        <div className="pad3">
                                                            <input type="text" name="brocker_details_1" id="brocker_details_1" value={getPdfFormValues?.brocker_details_1 ?? null} onChange={handleChangeForm} className="inputTypeClass" />
                                                        </div>
                                                        <div className="pad8">
                                                            <input type="text" name="brocker_details_2" id="brocker_details_2" value={getPdfFormValues?.brocker_details_2 ?? null} onChange={handleChangeForm} className="inputTypeClass" />
                                                        </div>
                                                        <div className="pad8">
                                                            <input type="text" name="brocker_details_3" id="brocker_details_3" value={getPdfFormValues?.brocker_details_3 ?? null} onChange={handleChangeForm} className="inputTypeClass" />
                                                        </div>
                                                        <div className="pad8">
                                                            <input type="text" name="brocker_details_4" id="brocker_details_4" value={getPdfFormValues?.brocker_details_4 ?? null} onChange={handleChangeForm} className="inputTypeClass" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr><td colspan="2" className="height25"></td></tr>
                                            <tr>
                                                <td className="widthLineVert">
                                                    <div className="paddRightClass">
                                                        Purchaser’s Signature(s):<br />
                                                        <div className="borderDotted"></div>
                                                    </div>
                                                </td>
                                                <td className="widthLineVert">
                                                    <div className="padLeft32">
                                                        <div>
                                                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" className="table_1">
                                                                <tr>
                                                                    <td className="verticalAlignWidth">Print Name:</td>
                                                                    <td><input type="text" name="print_name" id="print_name" value={getPdfFormValues?.print_name ?? null} onChange={handleChangeForm} className="inputTypeClass" /></td>
                                                                </tr>
                                                                <tr><td className="heightClass20"></td></tr>
                                                                <tr>
                                                                    <td className="verticalAlign">Date:</td>
                                                                    <td></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div className="borderDotted"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="pDetails">TERMS AND CONDITIONS</td>
                                            </tr>
                                            <tr><td className="height0"></td></tr>
                                            <tr>
                                                <td colspan="2">
                                                    <div className="paddRightClass">
                                                        {ternsAndCond.replace(/<\/?[^>]+(>|$)/g, "") ?? ''}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr><td className="height0"></td></tr>
                                            <tr>
                                                <td colspan="2" className="pDetails">DATA USAGE</td>
                                            </tr>
                                            <tr><td className="height0"></td></tr>
                                            <tr>
                                                <td colspan="2">
                                                    <div className="paddRightClass">
                                                        {dataUsage ?? ''}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr><td className="padTop35"></td></tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            {showPdfSuccessMsg &&
                                <div className="submitPdfSuccessMsg">
                                    Please check your mail, pdf successfully sent to your mail id
                                </div>
                            }
                            <div className="submitPdfButton">
                                <a onClick={() => { sendMail() }} className="imgHold floorPlanNew" >Send mail</a>
                            </div>
                        </div>
                    </center>
                </body>
            </Grid>
        </Modal>
    )
}