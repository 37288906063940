import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { ParkingPopupModel } from '../blog-components/parkingPopup';
import { connect } from 'react-redux';
import { getDevelopmentUnit, setHoldStatus } from './../../actions';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { API_URL } from '../../url/apiUrl';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { convertHtmlToPdf, getOneCalculation, getAllCalculations, setHoldDevStatus, setParkiSpacestoLS } from '../../api';
import './custom.css';
import ReactTooltip from 'react-tooltip';
import { getCurrentDate, returnPdfHtml } from './pdfModal';
import { returnPrintHtml } from './../../common/print'
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from "@material-ui/core/Tooltip";
import TitleToolTip from './TitleToolTip';
import { PopupFrom } from '../blog-components/PopupForm';


const checkArray = ['buying_appartment', 'fee_check', 'once_the', 'units_check',
	'the_buyer_check', 'cash_check', 'mortage_check', 'other_check'];
class UnitResult extends Component {
	constructor(props) {
		super(props);
		this.state = {
			unitid: null,
			isModalOpen: false,
			isHoldModalOpen: false,
			isModalHoldOpen: false,
			isModalPrintOpen: false,
			openPopupForm: false,
			selectedItem: {},
			getPdfFormValues: {
				buying_appartment: false, fee_check: false, once_the: false, units_check: false,
				the_buyer_check: false, cash_check: false, mortage_check: false, other_check: false
			},
			agentUserData: {},
			loading: false,
			sendMail: { fullname: '', message: '', email: '', unitid: '', developmentid: '', discount: '', unitImage: '', floorplan: '' },
			marketingBrochure: [],
			sendDevBroucher: [],
			openParkingPopup: false,
			isHighRisk: false
		}
	}
	componentDidMount() {
		this.setState({ unitid: this.props.unitid })
		this.setUnitDetail(this.props.unitid);
		this.getCalculation()
		setParkiSpacestoLS(this.props.developmentDetail)
		// this.getOneCalculationData(this.props.unitid);
	}
	setUnitDetail = (id, forceUpdate = false) => {
		if (!this.props.developmentUnitDetail || forceUpdate) {
			this.props.getDevelopmentUnit(this.props.token, null, id, null, null)
		}
	}

	getCalculation() {
		getOneCalculation(this.props.token).then(res => {
			if (res.data.success) {
				this.setState({ settingValues: res.data.data })
			}
		})
	}

	/******************* start of get calculation settings *************/
	// getOneCalculationData = (unitid) =>{
	// 	let getToken = localStorage.getItem('tokenweb');
	// 	getAllCalculations(getToken).then((request) => {
	// 		console.log(request.data, ' ___getToken');
	// 	})
	// 	.catch((error) => {
	// 		console.log(error, ' ___error');
	// 	});
	// }
	/******************* end of get calculation settings *************/
	/******************* start of show pdf model form **************/
	showPdfModelForm = (selectedItem) => {
		let agentUserData = localStorage.getItem('agentUserData');
		agentUserData = JSON.parse(agentUserData);
		// console.log(selectedItem, ' __selectedItem')
		selectedItem.reservation_fee = selectedItem.investor_price ? Math.ceil(selectedItem.investor_price * 5 / 100) ?? 0 : 0;
		this.setState({ openPopupForm: true, selectedItem, agentUserData });
	}
	handleClosePopup = () => this.setState({ openPopupForm: false });
	/******************* end of show pdf model form ***************/
	/******************* start of send pdf mail **************/
	sendMail = () => {
		const { selectedItem, getPdfFormValues, agentUserData, settingValues } = this.state;
		const { developmentDetail } = this.props;
		let dataUsage = developmentDetail.reservation && developmentDetail.reservation.defaultSetting ? settingValues?.data_usage ?? '' : developmentDetail?.reservation?.dataUsage ?? settingValues?.data_usage ?? '';
		let ternsAndCond = developmentDetail.reservation && developmentDetail.reservation.defaultSetting ? settingValues?.terms ?? '' : developmentDetail?.reservation?.termsConditions ?? settingValues?.terms ?? '';
		let pdfLogo = developmentDetail?.reservation?.logo ?? '';
		let fullLogoUrl = null;
		if (pdfLogo != '') {
			fullLogoUrl = API_URL.BucketURl + pdfLogo;
		}
		let locationUnit = developmentDetail?.details?.location ?? '';
		let getData = localStorage.getItem('tokenweb');
		let formData = {
			htmldata: returnPdfHtml(selectedItem, getPdfFormValues, agentUserData, dataUsage, ternsAndCond, fullLogoUrl, locationUnit),
			type: selectedItem?.type ?? '',
			unit_number: selectedItem?.unit_number ?? '',
			development: selectedItem?.title ?? ''
		}
		document.getElementById('preloader').style.display = 'block';
		convertHtmlToPdf(getData, formData).then((request) => {
			if (request.data?.hassuccessed == true) {
				this.setState({ showPdfSuccessMsg: true });
				document.getElementById('preloader').style.display = 'none';
				setTimeout(() => {
					this.setState({
						openPopupForm: false, showPdfSuccessMsg: false, selectedItem: {},
						getPdfFormValues: {
							buying_appartment: false, fee_check: false, once_the: false, units_check: false,
							the_buyer_check: false, cash_check: false, mortage_check: false, other_check: false
						}
					});
				}, 2000);
			}
		})
			.catch((error) => {
				console.log(error);
			});
	}
	/******************* end of send pdf mail ***************/
	/******************* start of handleChangeForm **************/
	handleChangeForm = (event) => {
		const { getPdfFormValues, selectedItem } = this.state;
		if (checkArray.includes(event.target.name)) {
			if (getPdfFormValues[event.target.name] == false) {
				getPdfFormValues[event.target.name] = true;
			}
			else {
				getPdfFormValues[event.target.name] = false;
			}
		}
		else {
			if (event.target.name === 'reservation_fee') {
				selectedItem.reservation_fee = event.target.value;
			}
			else if (event.target.name === 'purchaseprice') {
				selectedItem.investor_price = event.target.value;
			}
			else
				getPdfFormValues[event.target.name] = event.target.value;
		}
		this.setState({ getPdfFormValues, selectedItem });
	}
	/******************* end of handleChangeForm **************/
	openHoldModal = (unitData) => this.setState({ isModalHoldOpen: true, unitSelect: unitData });
	closeHoldModal = () => this.setState({ isModalHoldOpen: false, unitSelect: null, indexSelect: -1 });
	openModal = (high_risk) => this.setState({ isModalOpen: true, isHighRisk: high_risk });
	closeModal = () => this.setState({ isModalOpen: false, isHighRisk: false });
	openAlreadyonholdModal = () => this.setState({ isHoldModalOpen: true });
	closeAlreadyonholdModal = () => this.setState({ isHoldModalOpen: false });

	holdUnhold = async (unitData) => {
		if (unitData.status == 'hold' && !unitData['hold_by_me']) {
		}
		else { this.openHoldModal(unitData) }
	}

	confirmHoldOnhold = () => {
		let developmentUnitDetail = this.state.unitSelect
		let devlopmentid = developmentUnitDetail.id,
			unitid = developmentUnitDetail.unit_id,
			status = developmentUnitDetail.status !== 'hold' ? 'hold' : 'available';
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let parkingSpaces = unitParkingSpace?.length > 0 && unitParkingSpace.filter(space => space.unit_id == developmentUnitDetail.unit_id)

		developmentUnitDetail['status'] = status;
		developmentUnitDetail['hold_by_me'] = true;
		this.closeHoldModal()
		setHoldDevStatus(this.props.token, devlopmentid, unitid, status, parkingSpaces)
			.then((resp) => {
				if (resp.data.success) {
					if (status === 'hold') this.openModal(developmentUnitDetail?.high_risk)
				}
				else if (status === 'hold' && resp.data.type == "UNABLE_TO_UPDATE_ALREADY_HOLD") {
					this.openAlreadyonholdModal()
				}
			})
		// this.props.setHoldStatus(this.props.token, devlopmentid, unitid, status);

		// setTimeout(() => {
		// 	// this.closeHoldModal()
		// 	if (status === 'hold') this.openModal()
		// }, 1000);
	}

	openShareButton = async (selectedUnit) => {
		this.openShareModal(selectedUnit);
	}

	openShareModal = (unitData, index) => {
		let sendMail = this.state.sendMail
		sendMail.message = `Here’s some more information about apartment ${unitData.unit_number}, ${unitData.title}, ${unitData.location}.\n
		For more detailed information about this opportunity, please click-through the links below to download the development’s floor plan and brochures`
		this.setState({ isModalShareOpen: true, unitSelect: unitData, sendMail: sendMail });
	}

	closeShareModal = () => {
		const { sendMail } = this.state
		sendMail['development_brochure'] = []
		this.setState({ isModalShareOpen: false, unitSelect: null, sendDevBroucher: [], sendMail: sendMail })
	};

	openPrintModal = (unitData) => {
		let sendMail = this.state.sendMail
		sendMail['email'] = '';
		sendMail['fullname'] = '';
		sendMail.message = `Here’s some more information about apartment ${unitData.unit_number}, ${unitData.title}, ${unitData.location}.\n
		For more detailed information about this opportunity, please click-through the links below to download the development’s floor plan and brochures`
		this.setState({ isModalPrintOpen: true, unitSelect: unitData, sendMail: sendMail });
	}
	closePrintModal = () => this.setState({ isModalPrintOpen: false, unitSelect: null });


	handleChangeShare = (event, file) => {
		let { sendMail, sendDevBroucher } = this.state;
		const { name, value, checked } = event.target
		if (name == 'development_brochure') {
			if (checked == true) {
				sendDevBroucher.push(file)
				sendMail[name] = sendDevBroucher;
			} else {
				const filterBrochure = sendDevBroucher.filter((val) => val !== file.name);
				sendMail[name] = filterBrochure;
			}
		} else {
			sendMail[name] = value;
		}
		this.setState({ sendMail })
	}

	confirmShare = async () => {
		let { sendMail, unitSelect } = this.state
		let filteredParking = await this.filterParkingUnits(unitSelect)
		sendMail['unitid'] = unitSelect.unit_id;
		sendMail['developmentid'] = unitSelect.id;
		sendMail['discount'] = unitSelect.discount;
		sendMail['unitImage'] = unitSelect.image;
		sendMail['parkingSpaces'] = filteredParking;
		sendMail['floorplan'] = unitSelect.floorplan
		this.setState({ loading: true })
		const headers = { 'token': `Bearer ${this.props.token}` }
		const response = await axios.post(`${API_URL.sendShareMail}`, sendMail, { headers });
		if (response) {
			this.setState({ loading: false })
			let res = response.data
			if (res.statusCode === 200) {
				this.setState({ successMsg: 'Email is Sent to the user' })
				setTimeout(() => {
					this.setState({ successMsg: null })
					this.closeShareModal()
				}, 2000);
			}
			// console.log("response", response)
		}
		else {
			this.setState({ loading: false })
		}
	}

	filterParkingUnits = async (unitSelect) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let filteredParking = unitParkingSpace && unitParkingSpace.length > 0 && await unitParkingSpace.filter(park => park.unit_id == unitSelect.unit_id)
		return filteredParking
	}

	confirmPrint = async () => {
		let { sendMail, unitSelect } = this.state
		let filteredParking = await this.filterParkingUnits(unitSelect)

		sendMail['unitid'] = unitSelect.unit_id;
		sendMail['developmentid'] = unitSelect.id;
		sendMail['discount'] = unitSelect.discount;
		sendMail['unitImage'] = unitSelect.image;
		sendMail['parkingSpaces'] = filteredParking
		sendMail['floorplan'] = unitSelect.floorplan
		this.setState({ loading: true })
		const headers = { 'token': `Bearer ${this.props.token}` }
		const response = await axios.post(`${API_URL.sendPrintData}`, sendMail, { headers });
		if (response) {
			this.setState({ loading: false })
			let res = response.data
			if (res.statusCode === 200) {
				var myWindow = window.open('', '', '');
				myWindow.document.write(res.data);
				myWindow.document.close(); //missing code
				myWindow.focus();
				setTimeout(() => { myWindow.print(); }, 200)

			}
		}
		else {
			this.setState({ loading: false })
		}
	}

	seeView = (link) => {
		window.open("/#/doc-view?url=" + link, "_blank")
	}

	retCalculationInvest = (item, per, useFor) => {
		const cal = this.calcParkingPrice(item)
		let amount = cal + parseInt(item.investor_price)
		if (item.overRideValus == true) {
			per = useFor == 'completion' ? item?.overridePercentage['completion'] : useFor == 'reservation' ? item?.overridePercentage['reservation'] : useFor == 'exchange' ? item?.overridePercentage['exchange'] : per
		}
		const retValue = (amount / 100) * per
		return Math.round(retValue).toLocaleString()
	}

	calcParkingPrice = (item) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let price = 0
		unitParkingSpace?.length > 0 && unitParkingSpace.map(space => {
			if (item.unit_id == space.unit_id) price += item.parkingPrice
		})
		return price
	}

	retParkingSPace = (item) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let arr = []

		unitParkingSpace && unitParkingSpace.length > 0 && unitParkingSpace.map(res => {
			if (item.unit_id == res.unit_id) {
				arr.push(res.label)
			}
		})
		return arr
	}

	addParkingModel = (item) => {
		let agentUserData = localStorage.getItem('agentUserData');
		agentUserData = JSON.parse(agentUserData);
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let parkingSpaces = unitParkingSpace?.length > 0 && unitParkingSpace.filter(space => space.unit_id == item.unit_id)
		this.setState({ openParkingPopup: true, selectedItem: item, agentUserData, parkingSpaces: parkingSpaces ?? [] });
	}

	handleAddParking = async (event) => {
		let { selectedItem } = this.state
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let data = await event.map(parking => {
			parking["unit_id"] = selectedItem.unit_id
			return parking
		})
		if (unitParkingSpace?.length > 0) {
			let checkUnitUse = unitParkingSpace.filter(spaces => spaces.unit_id != selectedItem.unit_id)
			let checkUnit = unitParkingSpace.filter(spaces => spaces.unit_id == selectedItem.unit_id)
			if (checkUnit.length > data.length) {
				unitParkingSpace = [...checkUnitUse, ...data]
			} else if (checkUnit.length < data.length) {
				data.map((dat, i) => {
					if (data.length == i + 1)
						unitParkingSpace.push(dat)
				})
			}
			localStorage.setItem("unitParkingSpace", JSON.stringify(unitParkingSpace))
		} else {
			localStorage.setItem("unitParkingSpace", JSON.stringify(data))
		}
		this.setState({ parkingSpaces: event })

	}

	handleClosePopup = () => {
		this.setState({ openPopupForm: false, openParkingPopup: false })
	}

	parkingOption = (data) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		if (unitParkingSpace && unitParkingSpace.length > 0) {

			unitParkingSpace.filter(space => {
				data.map((val, index) => {
					if (val.value == space.value) data.splice(index, 1)
				})
			})
			return data
		} else {
			return data
		}
	}

	render() {
		const { isModalPrintOpen, isModalHoldOpen, isHoldModalOpen, isModalOpen, openPopupForm, loading, parkingSpaces, isHighRisk,
			showPdfSuccessMsg, selectedItem, agentUserData, isModalShareOpen, successMsg, settingValues, marketingBrochure } = this.state;
		let user_detail = JSON.parse(localStorage.getItem("agentUserData"))

		const { developmentUnitDetail, developmentDetail } = this.props;
		let availableParkingList = []
		developmentDetail?.parking?.length > 0 && developmentDetail.parking.map(async park => {
			if (park?.status?.value == 'available') {
				availableParkingList.push({ value: park?._id, label: park?.parking_no })
			}
		})

		developmentDetail?.marketing?.brochure?.length > 0 && developmentDetail.marketing.brochure.map(data => {
			if (data.selectedAgent && data.selectedAgent.selected === 'seleted') {
				let containdata = data.selectedAgent.agentids.find(ele => user_detail.userType == 'agent' ? (ele.value === '' + user_detail['admin_id']) : (ele.value === '' + user_detail['id']))
				if (containdata && !marketingBrochure.includes(data.name)) {
					marketingBrochure.push(data.name)
					this.setState({ marketingBrochure })
				}
			} else if (data.fileInfo && data.fileInfo.filename) {
				if (!marketingBrochure.includes(data.name)) {
					marketingBrochure.push(data.name)
					this.setState({ marketingBrochure })
				}
			}
		})
		let publicUrl = process.env.PUBLIC_URL + '/';
		let imagealt = 'image';
		let data = sectiondata.unitproperties;
		let Customclass = this.props.Customclass ? this.props.Customclass : '';
		let dataUsage = developmentDetail.reservation && developmentDetail.reservation.defaultSetting ? settingValues?.data_usage ?? '' : developmentDetail?.reservation?.dataUsage ?? settingValues?.data_usage ?? '';
		let calculationFigure = developmentDetail.details && developmentDetail.details?.calculationFigure != '<p><br></p>' ? developmentDetail.details.calculationFigure : settingValues?.calculation_figure ?? ''
		let ternsAndCond = developmentDetail.reservation && developmentDetail.reservation.defaultSetting ? settingValues?.terms ?? '' : developmentDetail?.reservation?.termsConditions ?? settingValues?.terms ?? '';
		let pdfLogo = developmentDetail?.reservation?.logo ?? '';
		let locationUnit = developmentDetail?.details?.location ?? '';
		return <div className={"featured-area  " + Customclass}>
			<div className="container">
				<div className="section-title text-center">
					{/* <h2 className="title">{'Unit Detail'}</h2> */}
				</div>
				{developmentUnitDetail && developmentUnitDetail && <div className="row justify-content-center">
					<div className="col-xl-7 col-lg-7">
						<div className="single-feature style-two">
							<div className="thumb">
								<img src={developmentUnitDetail.image} alt="img" />
								{developmentUnitDetail && developmentUnitDetail.icon &&
									<a href="#" className="feature-logo">
										<img src={developmentUnitDetail} alt={imagealt} />
									</a>
								}
							</div>
							<div className="details">
								<div className="details-wrap">
									<div className="holdShrRght holdShrRghtNew holdShrRghtNewFor">
										<a onClick={e => this.openShareButton(developmentUnitDetail)} data-tip="Share Unit via mail"><i class="fas fa-share-alt"></i></a>
										<a onClick={e => this.openPrintModal(developmentUnitDetail)} data-tip="Print Unit"><i class="fas fa-print"></i></a>
									</div>
									<h6 className="title readeal-top"><Link >{developmentUnitDetail.title}</Link></h6>
									<p className="sale_price_txt"><b>{developmentUnitDetail.type}</b></p>
									<p className="sale_price_txt"><b>Unit {developmentUnitDetail.unit_number} {developmentUnitDetail.floor} floor</b></p>
									<p className="sale_price_txt">Sale price  <b>£{developmentUnitDetail.sales_price ? ((250 * Math.ceil(developmentUnitDetail.sales_price / 250)) + developmentUnitDetail?.totalParkingPrice).toLocaleString() : 0}</b></p>
									<p className="discount_txt">Discount <b>£{developmentUnitDetail.discount != undefined && developmentUnitDetail.sales_price && ((250 * Math.ceil(developmentUnitDetail.sales_price / 250)) - (250 * Math.ceil(developmentUnitDetail.investor_price / 250))).toLocaleString()}</b> </p>
									<p className="investor_price_txt"><span>Investor price</span> <b>£{developmentUnitDetail.investor_price ? ((250 * Math.ceil(developmentUnitDetail.investor_price / 250)) + developmentUnitDetail?.totalParkingPrice).toLocaleString() : 0}</b></p>
									{this.calcParkingPrice(developmentUnitDetail) > 0 && <p><a className="parkingLabel"><span>Parking Include</span></a> <b>£{developmentUnitDetail.investor_price != undefined && this.calcParkingPrice(developmentUnitDetail).toLocaleString()}</b></p>}

									<ul className="info-list">
										{developmentUnitDetail.features.map((features, i) =>
											<li key={i} ><i className={features.icon} data-tip={features.icon == "fa fa-bed" ? "Bedroom" : features.icon == "fa fa-bath" ? "Bathroom" : ''} /> {features.title}</li>
										)}
										{developmentUnitDetail.study && <li>
											<img width='5px' height='15px' src={require("../../assets/img/studyblue.svg")} data-tip="Study room" />{developmentUnitDetail.study}
										</li>}
										{developmentUnitDetail.balcony && <li>
											<img width='15px' height='15px' src={require("../../assets/img/balconyblue.svg")} data-tip="Balcony" />{developmentUnitDetail.balcony}
										</li>}
										<li><img src={publicUrl + "assets/img/icons/7.png"} alt={imagealt} /> {Math.round((developmentUnitDetail.aream2 * 10.764))} sq ft<sup>2</sup> </li>
										<li><img src={publicUrl + "assets/img/icons/7.png"} alt={imagealt} /> {developmentUnitDetail.aream2} sq m<sup>2</sup> </li>
									</ul>
									<ul className="contact-list">
										{developmentUnitDetail.floorplan && <li className="readeal-top"><a className="btn btn-yellow" onClick={() => this.seeView(API_URL.BucketURl + developmentUnitDetail.floorplan)} >Floorplan</a></li>}
										{(developmentUnitDetail.availStatus !== 'available' && developmentUnitDetail.status == 'available' || developmentUnitDetail.status == 'hold') && <li className="readeal-top"><a disabled={developmentUnitDetail.status == 'hold' && !developmentUnitDetail['hold_by_me']} className={developmentUnitDetail.status == 'hold' ? developmentUnitDetail['hold_by_me'] ? "hold-by-me btn" : "hold-by-other btn" : 'btn btn-gray'} onClick={e => this.holdUnhold(developmentUnitDetail)}>{developmentUnitDetail.status !== 'hold' ? 'Hold' : 'On Hold'}</a></li>}
										{(developmentUnitDetail.availStatus !== 'available' && developmentUnitDetail.status == 'available' || (developmentUnitDetail.status == 'hold' && developmentUnitDetail['hold_by_me'])) && <li className="readeal-top"><a className={'btn btn-gray reserve'} onClick={e => { this.showPdfModelForm(developmentUnitDetail) }}>{'Reserve'}</a></li>}
										{developmentUnitDetail.isMultiAgent && <img data-tip data-for='agentid' src={publicUrl + 'assets/img/multi_agent.png'} className="multi-img" />}
										{developmentUnitDetail.parking == true && availableParkingList && availableParkingList.length > 0 && <a onClick={e => this.addParkingModel(developmentUnitDetail)} className="addparkingatunit">{'+ Parking'}</a>}

										{/* <li className="share_development"><img src={publicUrl + data.share_icon} /></li>
											<li className="print_development"><img src={publicUrl + data.print_icon} /></li> */}
									</ul>
									<ReactTooltip id='agentid' type='warning' effect='solid'>
										<span>Multi agent listing</span>
									</ReactTooltip>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-5 col-lg-5 details">
						<div className="devContainer">
							<h5>Development Profile</h5>
							<Tooltip title={<TitleToolTip calculation_figure={calculationFigure} />} arrow placement="top">
								<img src={publicUrl + "assets/img/Group 186.png"} alt="sample" title="" />
							</Tooltip>
						</div>
						<div className="row background-change headingColor">
							<div className="col-xl-6 col-lg-6"><b>Planning Consent</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{developmentUnitDetail.planning_consent}</div>
						</div>
						<div className="row headingColor">
							<div className="col-xl-6 col-lg-6"><b>Projected Completion Date</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{developmentUnitDetail.completion_date}</div>
						</div>
						<div className="row background-change headingColor">
							<div className="col-xl-6 col-lg-6"><b>Units</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{developmentUnitDetail.unit_count}</div>
						</div>
						<div className="row headingColor">
							<div className="col-xl-6 col-lg-6"><b>On-site Parking</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{developmentUnitDetail.onSiteParking}</div>
						</div>
						<div className="row background-change headingColor">
							<div className="col-xl-6 col-lg-6"><b>Assured Rental Return</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{"Starting from " + developmentUnitDetail.assured_return}%</div>
						</div>
						<div className="row headingColor">
							<div className="col-xl-6 col-lg-6"><b>Ground Rent</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{developmentUnitDetail.costReturn.groundRentOption == 'amount' ? "£" + developmentUnitDetail?.costReturn?.groundRent : developmentUnitDetail?.costReturn?.groundRent + '%'}</div>
						</div>
						<div className="row background-change headingColor">
							<div className="col-xl-6 col-lg-6"><b>Service Charge ft<sup>2</sup>/yr</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{developmentUnitDetail.service_charge ? '£' + developmentUnitDetail.service_charge : ''}</div>
						</div>
						<div className="row headingColor">
							<div className="col-xl-6 col-lg-6"><b>Management Fee %/yr</b></div>
							<div className="col-xl-6 col-lg-6 headingValueColor">{developmentUnitDetail.management_charge ? (developmentUnitDetail.management_charge + '%') : ''}</div>
							{/* <div className="col-xl-6 col-lg-6 headingValueColor">{(developmentUnitDetail.costReturn?.assuredReturn && developmentUnitDetail.investor_price) ? '£ '+(developmentUnitDetail.costReturn?.assuredReturn* developmentUnitDetail.investor_price/100).toLocaleString()  : ''}</div> */}
						</div>
					</div>

				</div>}
				<Modal
					open={isModalShareOpen}
					onClose={() => { this.closeShareModal() }}
					className={'modal-classes-data'}
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeShareModal}>&times;</span>
						<div className="col-sm-12">
							{successMsg && <span>{successMsg}</span>}
							<div className="row">
								<div className="col-sm-4">
									<label>Email: </label>
								</div>
								<div className="col-sm-8">
									<input type="email" name="email" id="email" value={this.state.sendMail?.email ?? ''} onChange={this.handleChangeShare} className="" />
								</div>

							</div>
							<div className="row">
								<div className="col-sm-4">
									<label>First name: </label>
								</div>
								<div className="col-sm-8">
									<input type="text" name="fullname" id="fullname" value={this.state.sendMail?.fullname ?? ''} onChange={this.handleChangeShare} className="" />
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									<label>Message: </label>
								</div>
								<div className="col-sm-8">
									<textarea name="message" id="message" value={this.state.sendMail?.message ?? ''} onChange={this.handleChangeShare} className="" />
								</div>
							</div>
							<div className="row">
								<div className="col-sm-8">
									<div><label>Include link too: </label></div>
									{marketingBrochure && marketingBrochure.length > 0 && marketingBrochure.map(file => (
										<div className='optioninShare'>
											<a><input type='checkbox' name="development_brochure" id="development_brochure" className="" onChange={(e) => this.handleChangeShare(e, file)} /></a>
											<label>{file} </label>
										</div>
									))}
								</div>
							</div>
							<div className="row" style={{ justifyContent: 'center' }}>
								{!loading && <a onClick={this.confirmShare} className="floorPlan imgHold">Send</a>}
								{loading && <CircularProgress />}
							</div>
						</div>
					</Grid>
				</Modal>
				<Modal
					open={isModalPrintOpen}
					onClose={() => { this.closePrintModal() }}
					className={'modal-classes-data'}
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closePrintModal}>&times;</span>
						<div className="col-sm-12">
							{successMsg && <span>{successMsg}</span>}
							<div className="row">
								<div className="col-sm-4">
									<label>First name: </label>
								</div>
								<div className="col-sm-8">
									<input type="text" name="fullname" id="fullname" value={this.state.sendMail?.fullname ?? ''} onChange={this.handleChangeShare} className="" />
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									<label>Message: </label>
								</div>
								<div className="col-sm-8">
									<textarea name="message" id="message" value={this.state.sendMail?.message ?? ''} onChange={this.handleChangeShare} className="" />
								</div>
							</div>

							<div className="row" style={{ justifyContent: 'center' }}>
								{!loading && <a onClick={this.confirmPrint} className="floorPlan imgHold">Print</a>}
								{loading && <CircularProgress />}
							</div>
						</div>
					</Grid>
				</Modal>

				<Modal
					open={isModalHoldOpen}
					onClose={() => { this.closeHoldModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeHoldModal}>&times;</span>
						<div className="modal-custom">
							{this.state.unitSelect && <div className="modal-content-custom">
								<p>Do you want to {this.state.unitSelect.status !== 'hold' ? 'hold' : 'release'} the unit {this.state.unitSelect.unit_number}</p>
								<a onClick={e => this.confirmHoldOnhold()} className="imgHold">Yes</a>&nbsp;
								<a className="imgHold" onClick={() => this.setState({ isModalHoldOpen: false })} >Cancel</a>
							</div>}
						</div>
					</Grid>
				</Modal>
				<Modal
					open={isModalOpen}
					onClose={() => { this.closeModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeModal}>&times;</span>
						<div className="modal-custom">
							<div className="modal-content-custom">
								We’ve held this apartment for you. It will be released in {isHighRisk ? '120' :'48'} hours unless we receive a reservation payment.
							</div>
						</div>
					</Grid>

				</Modal>
				<Modal
					open={isHoldModalOpen}
					onClose={() => { this.closeAlreadyonholdModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeAlreadyonholdModal}>&times;</span>
						<div className="modal-custom">
							<div className="modal-content-custom">
								Unfortunately this property is now on hold with another agent. The reason the 'hold' button was still displaying is because your page has not been refreshed recently.
							</div>
						</div>
					</Grid>

				</Modal>
			</div>
			<ReactTooltip />


			<PopupFrom
				pdfLogo={pdfLogo}
				handleClosePopup={this.handleClosePopup}
				openPopupForm={openPopupForm}
				selectedItem={selectedItem}
				getPdfFormValues={this.state.getPdfFormValues}
				handleChangeForm={this.handleChangeForm}
				getCurrentDate={getCurrentDate}
				retParkingSPace={this.retParkingSPace}
				calcParkingPrice={this.calcParkingPrice}
				locationUnit={locationUnit}
				ternsAndCond={ternsAndCond}
				dataUsage={dataUsage}
				showPdfSuccessMsg={showPdfSuccessMsg}
				agentUserData={agentUserData}
				retCalculationInvest={this.retCalculationInvest}
				sendMail={this.sendMail}
			/>

			<ParkingPopupModel
				handleClosePopup={this.handleClosePopup}
				submitParkingSpaces={this.handleClosePopup}
				parkingSpaces={parkingSpaces}
				parkingOption={this.parkingOption}
				availableParkingList={availableParkingList}
				openParkingPopup={this.state.openParkingPopup}
				selectedItem={selectedItem}
				developmentDetail={developmentDetail}
				handleAddParking={this.handleAddParking} />
		</div >
	}
}

const mapStateToProps = (state) => {
	const { developmentUnitDetail, developmentDetail } = state.development;
	const { user, token, userType } = state.authUser;

	return {
		developmentUnitDetail, user, token, userType, developmentDetail
	};
}

const mapDispatchToProps = dispatch => bindActionCreators({ getDevelopmentUnit, setHoldStatus }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UnitResult);