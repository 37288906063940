import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { API_URL } from '../../url/apiUrl';
import AlertMessage from './alertMessage';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { bindActionCreators } from 'redux';
const publicUrl = process.env.PUBLIC_URL + '/'


class Index extends Component {
	constructor(props) {
		super(props)
		this.state = {
			new_password: "",
			confirm_password: "",
			errorMessage: "",
			successMessage: "",
			loading: false
		}
	}

	componentDidMount = async () => {
		let uid = window.location.href.split('=')[1]
		this.setState({ uid })
		// await this.getTokenForVerification()
	}
	componentDidUpdate(prevProps) {
		if (prevProps.message !== this.props.message) {
			this.setState({ errorMessage: this.props.message })
			setTimeout(() => {
				this.setState({ errorMessage: null })
			}, 3000)
		}
	}

	getTokenForVerification = async () => {
		//	this.props.checkToken(this.props.token, this.props.history)
	}

	handleChange = (event) => {
		this.setState({loading: false})
		const isCheckbox = event.target.type === 'checkbox'
		this.setState({
			[event.target.name]: isCheckbox ? event.target.checked : event.target.value
		})
	}

	resetPassword() {
		let self = this
		let { new_password, confirm_password, uid } = this.state

		this.setState({loading: true})
		if (new_password == '')
			NotificationManager.error("Please enter New password");
		else if (confirm_password == '')
			NotificationManager.error("Please enter Confirm password");
		else if (new_password != confirm_password)
			NotificationManager.error("Password did not match");
		else {
			let body = {
				password: new_password,
				pwd_token: uid
			}
			let url = API_URL.setpassword
			axios.put(url, body, {
				headers: {
					'token': 'Bearer ' + this.props.token,
					'Content-Type': 'application/json'
				}
			})
				.then(res => {
					this.setState({loading: false})
					if (res.data.success) {
						NotificationManager.success('Passord reset Succefully.' )
						setTimeout(() => {
							this.props.history.push('/login');
						}, 2000);
					}
					else if(res.data.message=="Password Don't Match") {
						NotificationManager.error('Session expired.' )
						setTimeout(() => {
							this.props.history.push('/forget-password');
						}, 2000);
					}
				})
		}
	}

	render() {
		const { errorMessage, successMessage } = this.state
		return (
			<div className="container">
				{/* <AlertMessage errorMessage={errorMessage} successMessage={successMessage} /> */}
				<div className="mt-5 col-md-6" style={{ backgroundColor: '#e3e3e3', padding: '30px', marginBottom: '59px' }}>
					<form >
						<div className="form-group">
							<input type="password" name="new_password" placeholder="New Password" onChange={this.handleChange} className="form-control" />
						</div>
						<div className="form-group">
							<input type="password" name="confirm_password" placeholder="Confirm Password" onChange={this.handleChange} className="form-control" />
						</div>
						<div>
							<button type="button" className="btn floorplan btn-blue" onClick={this.resetPassword.bind(this)} disabled={this.state.loading}>Reset Password</button>
						</div>

					</form>
				</div>
			</div>
		)
	}
}


export default Index;