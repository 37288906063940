/**
 * Todo Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// api
import { getDevelopmentDetails, getAllDevelopmentDetails, getDevelopmentUnitDetails, setHoldDevStatus, getSearchResult } from './../api';

import {
	GET_DEVELOPMENT,
	LOGOUT_USER,
	UNAUTHORIZATION_ACCESS,
	GET_ALL_DEVELOPMENT,
	GET_DEVELOPMENT_UNIT,
	SET_STATUS,
	AVAILABLE_SEARCH_UNIT
} from './../actions/types';
import { API_URL } from '../url/apiUrl';
import {
	getDevelopmentSuccess,
	getDevlopmentFailure,
	getAllDevelopmentSuccess,
	getAllDevlopmentFailure,
	logoutUserFromFirebaseSuccess,
	logoutUserFromFirebaseFailure,
	getDevelopmentUnitSuccess,
	getDevelopmentUnitFailure,
	getSearchFailure,
	setHoldStatusSuccess,
	setHoldStatusFailure,
	getAvailableSearchSuccess,
	getAvailableSearchFailure
} from './../actions';


const special = ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth'];
const deca = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet'];


/**
 * Send Todos Request To Server
 */
const getDevelopmentRequest = async (token, developmentid) =>
	await getDevelopmentDetails(token, developmentid)
		.then(response => response)
		.catch(error => error);

/**
 * Send Todos Request To Server
 */
const getDevelopmentUnitRequest = async (token, id, unitid) =>
	await getDevelopmentUnitDetails(token, id, unitid)
		.then(response => response)
		.catch(error => error);


/**
* Send Todos Request To Server
*/
const getSearchRequest = async (token, searchForm) =>
	await getSearchResult(token, searchForm)
		.then(response => response)
		.catch(error => error);

/**
 * Send Todos Request To Server
 */
const getAllDevelopmentRequest = async (token, id, unitid) =>
	await getAllDevelopmentDetails(token, id, unitid)
		.then(response => response)
		.catch(error => error);

/**
 * Send Todos Request To Server
 */
const setStatusUnit = async (token, id, unitid, status) =>
	await setHoldDevStatus(token, id, unitid, status)
		.then(response => response)
		.catch(error => error);


/**
 * Get Todos From Server 
 */
function* getDevelopmentDetailFromServer({ payload }) {
	const { token, developmentid, redirectUrl, history } = payload;
	try {
		const response = yield call(getDevelopmentRequest, token, developmentid);
		if (response.data.success) {
			let res = response.data.data;
			yield put(getDevelopmentSuccess(res));
			yield put(getSearchFailure())
			yield put(getDevelopmentUnitFailure())
			if (history) history.push(redirectUrl)
		}
		else if (response.data.type == UNAUTHORIZATION_ACCESS) {
			yield put(getDevlopmentFailure(response.data.message));
		}
		else {
			yield put(getDevlopmentFailure(response.data.message));
		}
	} catch (error) {
		yield put(getDevlopmentFailure(error));
	}
}

/**
 * Get Todos From Server 
 */
function* getDevelopmentAllDetailFromServer({ payload }) {
	const { token } = payload;
	try {
		const response = yield call(getAllDevelopmentRequest, token);
		if (response.data.success) {
			let res = response.data.data;
			yield put(getAllDevelopmentSuccess(res));
		}
		else if (response.data.type == UNAUTHORIZATION_ACCESS) {
			yield put(getAllDevlopmentFailure(response.data.message));
		}
		else {
			yield put(getAllDevlopmentFailure(response.data.message));
		}
	} catch (error) {
		yield put(getAllDevlopmentFailure(error));
	}
}

function* getDevelopmentUnitFromServer({ payload }) {
	const { id, unitid, token, redirectUrl, history } = payload;
	document.getElementById("backToTopPage").click();
	document.getElementById('loaderImageSearch').style.display = 'block';
	try {
		const response = yield call(getDevelopmentUnitRequest, token, id, unitid);
		if (response.data.success) {
			setTimeout(() => {
				document.getElementById('loaderImageSearch').style.display = 'none';
			}, 2000)
			let res = response.data.data;
			let parkingPrice = res?.details?.parkingPrice ?? 0
			let units = res.unitDetail[0]
			let beds = units.bedrooms ? units.bedrooms + (units.bedrooms == 1 ? ' Bed' : ' Beds') : null;
			let bath = units.bathrooms ? units.bathrooms + (units.bathrooms == '1' ? ' Bathroom' : ' Bathrooms') : null;
			let study = units.study ? true : false;
			let balcony = units.balcony ? true : false;
			let discount = 0;
			discount = res.costReturn && res.costReturn.discount && res.costReturn.discount != "" ? res.costReturn.discount : res.rental_return ? res.rental_return : 0;
			let bedsCount = []
			for (let i = 1; i <= units.bedrooms; i++) {
				bedsCount.push(i)
			}
			let decafloor = ''

			let floornumber = units.floor ? !isNaN(units.floor.value) ? parseInt(units.floor.value) : units.floor.value : 'Not Available'

			if (floornumber === 'groundfloor') {
				decafloor = floornumber === 'groundfloor' ? 'Ground' : 'Not Defined ';
			} else
				if (floornumber === 'basement') {
					decafloor = floornumber === 'basement' ? 'Basement' : 'Not Defined ';
				} else
					// if (floornumber === 'basement1' || isNaN(floornumber)) {
					// 	decafloor = floornumber === 'basement1' ? 'Basement1' : 'Not Defined ';
					// }else
					if (floornumber === 'basement2') {
						decafloor = floornumber === 'basement2' ? 'Basement2' : 'Not Defined ';
					} else
						if (floornumber === 'basement3') {
							decafloor = floornumber === 'basement3' ? 'Basement3' : 'Not Defined ';
						} else
							if (floornumber === 'basement4') {
								decafloor = floornumber === 'basement4' ? 'Basement4' : 'Not Defined ';
							}
							else
								if (floornumber < 20) { decafloor = special[floornumber]; }
								else if (floornumber % 10 === 0) { decafloor = deca[Math.floor(floornumber / 10) - 2] + 'ieth'; }
								else { decafloor = deca[Math.floor(floornumber / 10) - 2] + 'y-' + special[floornumber % 10]; }

			let image = process.env.PUBLIC_URL + '/' + 'assets/img/development/9.png';
			if (!units.assets || !units.assets.image) {
				if (res.genericAppartment && units.property_type) {
					let tempimage = res.genericAppartment.find(ele => ele.appartmentType && ele.appartmentType.value === units.property_type.value && units.bedrooms && ele.number_of_bedrooms === units.bedrooms)
					if (tempimage) image = API_URL.BucketURl + tempimage.thumbnail.filename
				}
			}
			else if (units.assets && units.assets.image) {
				image = API_URL.BucketURl + units.assets.image
			}
			let searchItem = {
				"title": res.details.name,
				"availStatus": units.availStatus,
				"location": res.details.location,
				"unit_number": units.unit_number,
				"id": res._id,
				"unit_id": units._id,
				"floor": decafloor,
				"discount": units.cost_and_returns?.discount ? units.cost_and_returns?.discount : 0,
				"sales_price": units.cost_and_returns && units.cost_and_returns.sales_price ? units.cost_and_returns.sales_price : Math.round(100 / parseFloat(100 - discount) * units.cost_and_returns.investor_price),
				// "investor_price": units.cost_and_returns && parseInt(units.cost_and_returns.investor_price),
				"investor_price": units.cost_and_returns && units.cost_and_returns.investor_price ? parseInt(units.cost_and_returns.investor_price) : units.cost_and_returns && units.cost_and_returns.sales_price ? ((units.cost_and_returns.sales_price) - Math.round((discount / 100) * units.cost_and_returns.sales_price)) ?? 0 : 'Not defined',
				"image": image,
				"planning_consent": res.details.planningConsent,
				"completion_date": res.details.completionDate,
				"onSiteParking": res.details.onSiteParking,
				"unit_count": res.details.unitNumber,
				"assured_return": res.costReturn.assuredReturn ? res.costReturn.assuredReturn : res.rental_return,
				"ground_rent": res.costReturn.groundRent ? res.costReturn.groundRent : res.ground_rent,
				"service_charge": res.costReturn.serviceCharge ? res.costReturn.serviceCharge : res.service_charge,
				"management_charge": res.costReturn.managementFee ? res.costReturn.managementFee : res.management_charge,
				"area": units.floor_area_ft2 ? parseInt(units.floor_area_ft2) : '',
				"aream2": units.floor_area_m2 ? parseInt(units.floor_area_m2) : '',
				"status": units.status ? units.status : 'available',
				"parkingSpaces": units?.parkingSpaces ?? [],
				"parkingPrice": parkingPrice,
				'overRideValus': units?.overRideValus ?? false,
				'overridePercentage': units?.overridePercentage ?? {},
				'totalParkingPrice': parkingPrice * (units?.parkingSpaces?.length ?? 0),
				"type": units.property_type ? units.property_type.label : 'Apartment',
				"status": units.status,
				"hold_by_me": units.hold_by_me,
				"costReturn": res?.costReturn ?? {},
				'study': study,
				'balcony': balcony,
				"high_risk" : units.high_risk || false,
				"features": [
					{
						"icon": "fa fa-bed",
						"title": beds
					},
					{
						"icon": "fa fa-bath",
						"title": bath
					}
				],
				"floorplan": units.assets ? units.assets.floorplan : null,
				"bedrooms": bedsCount,
				"parking": units?.parking ?? false,
				"isMultiAgent": units.isMultiAgent ? units.isMultiAgent : false
			}

			yield put(getDevelopmentUnitSuccess(searchItem, res));
			yield put(getSearchFailure())
			if (history) history.push(redirectUrl)
		}
		else if (response.data.type == UNAUTHORIZATION_ACCESS) {

		}
		else {
			setTimeout(() => {
				document.getElementById('loaderImageSearch').style.display = 'none';
			}, 2000)
			yield put(getDevelopmentUnitFailure(response.data.message));
		}
	} catch (error) {
		console.log("error", error)
		setTimeout(() => {
			document.getElementById('loaderImageSearch').style.display = 'none';
		}, 2000)
		yield put(getDevelopmentUnitFailure(error));
	}
}

/**
 * Get Todos From Server 
 */



// /**
//  * Signout User
//  */
// function* signOuts() {
// 	try {
// 		localStorage.removeItem('user_id');
// 		localStorage.removeItem('tokenweb');
// 		localStorage.removeItem('userid');
// 		console.log("why iam here")
// 		yield put(logoutUserFromFirebaseSuccess())
// 	} catch (error) {
// 		yield put(logoutUserFromFirebaseFailure());
// 	}
// }

/**
 * Get Todos From Server 
 */
function* setStatusUnitFromServer({ payload }) {
	const { token, developmentid, unitid, status } = payload;
	try {

		const response = yield call(setStatusUnit, token, developmentid, unitid, status);
		if (response.data.success) {
			let res = response.data.data;
			yield put(setHoldStatusSuccess(res));
		}
		else if (response.data.type == UNAUTHORIZATION_ACCESS) {
			yield put(setHoldStatusFailure(response.data.message));
		}
		else {
			yield put(setHoldStatusFailure(response.data.message));
		}
	} catch (error) {
		yield put(setHoldStatusFailure(error));
	}
}

/**
 * Get Todos From Server 
 */
function* getAvailableSearchFromServer({ payload }) {
	const { searchForm, token } = payload;
	document.getElementById('loaderImageSearch').style.display = 'block';
	try {
		const response = yield call(getSearchRequest, token, searchForm);
		if (response.data.success) {
			document.getElementById('loaderImageSearch').style.display = 'none';
			let data = []
			let resArray = response.data.data;
			resArray.map(res => {
				let discount = 0;
				let ground_rent = res.development.costReturn?.groundRent
				let groundRentOption = res.development.costReturn?.groundRentOption
				let parkingPrice = res?.development?.details?.parkingPrice ?? 0
				let assuredRentel = res.development?.costReturn?.assuredReturn ?? res.rental_return ?? 0
				discount = res.development && res.development.costReturn && res.development.costReturn.discount && res.development.costReturn.discount != '' ? res.development.costReturn.discount : res.rental_return ? res.rental_return : 0
				res.units.map(units => {
					let beds = units.bedrooms ? units.bedrooms + (units.bedrooms == 1 ? ' Bed' : ' Beds') : null;
					let bath = units.bathrooms ? units.bathrooms + (units.bathrooms == '1' ? ' Bathroom' : ' Bathrooms') : null;
					let study = units.study ? ' Study' : null;
					let balcony = units.balcony ? ' Balcony' : null;
					let bedsCount = [];
					for (let i = 1; i <= units.bedrooms; i++) {
						bedsCount.push(i)
					}
					let decafloor = ''

					let floornumber = units.floor ? !isNaN(units.floor.value) ? parseInt(units.floor.value) : units.floor.value : 'Not Available'
					if (floornumber === 'groundfloor') {
						decafloor = floornumber === 'groundfloor' ? 'Ground' : 'Not Defined ';
					} else
						if (floornumber === 'basement') {
							decafloor = floornumber === 'basement' ? 'Basement' : 'Not Defined ';
						} else
							// if (floornumber === 'basement1' || isNaN(floornumber)) {
							// 	decafloor = floornumber === 'basement1' ? 'Basement1' : 'Not Defined ';
							// }else
							if (floornumber === 'basement2') {
								decafloor = floornumber === 'basement2' ? 'Basement2' : 'Not Defined ';
							} else
								if (floornumber === 'basement3') {
									decafloor = floornumber === 'basement3' ? 'Basement3' : 'Not Defined ';
								} else
									if (floornumber === 'basement4') {
										decafloor = floornumber === 'basement4' ? 'Basement4' : 'Not Defined ';
									}
									else
										if (floornumber < 20) { decafloor = special[floornumber]; }
										else if (floornumber % 10 === 0) { decafloor = deca[Math.floor(floornumber / 10) - 2] + 'ieth'; }
										else { decafloor = deca[Math.floor(floornumber / 10) - 2] + 'y-' + special[floornumber % 10]; }

					let image = process.env.PUBLIC_URL + '/' + 'assets/img/development/9.png';
					if (!units.assets || !units.assets.image) {
						if (res.development && res.development.genericAppartment && units.property_type) {
							let tempimage = res.development.genericAppartment.find(ele => ele.appartmentType && ele.appartmentType.value === units.property_type.value && units.bedrooms && ele.number_of_bedrooms === units.bedrooms)
							if (tempimage) image = API_URL.BucketURl + tempimage.thumbnail.filename
						}
					}
					else if (units.assets && units.assets.image) {
						image = API_URL.BucketURl + units.assets.image
					}
					let costRetun = GetCostReturn({ cost_and_returns: units.cost_and_returns, discount });
					let searchItem = {
						"title": res.development.details.name,
						"availStatus": units.availStatus,
						"location": res.development.details.location,
						"unit_number": units.unit_number,
						"id": res.development._id,
						"unit_id": units._id,
						"floor": decafloor,
						'ground_rent': ground_rent,
						'serviceCharge': res.development.costReturn?.serviceCharge,
						'ground_rent_option': groundRentOption,
						"sales_price": units.cost_and_returns && units.cost_and_returns.sales_price ? units.cost_and_returns.sales_price : Math.round(100 / parseFloat(100 - discount) * units.cost_and_returns.investor_price),
						"discount": units.cost_and_returns?.discount ? units.cost_and_returns?.discount : 0,
						// "investor_price": units.cost_and_returns ? parseInt(units.cost_and_returns.investor_price) : 0,
						"investor_price": units.cost_and_returns && units.cost_and_returns.investor_price ? units.cost_and_returns.investor_price : units.cost_and_returns && units.cost_and_returns.sales_price ? ((units.cost_and_returns.sales_price) - Math.round((discount / 100) * units.cost_and_returns.sales_price)) ?? 0 : 'Not defined',
						"reservation": costRetun.reservation,
						"assuredRentel": assuredRentel,
						"exchange": costRetun.exchange,
						'overRideValus': units?.overRideValus ?? false,
						'overridePercentage': units?.overridePercentage ?? {},
						"completion": costRetun.completion,
						"image": image,
						"url": "/development-details?unit=" + units._id,
						"area": units.floor_area_ft2 ? parseInt(units.floor_area_ft2) : '',
						"aream2": units.floor_area_m2 ? parseInt(units.floor_area_m2) : '',
						"status": units.status ? units.status : 'available',
						"floorplan": units.assets && units.assets.floorplan ? units.assets.floorplan : null,
						"type": units.property_type ? units.property_type.label : 'Not defined',
						"hold_by_me": units.hold_by_me,
						beds: beds,
						bath: bath,
						"study": study,
						"balcony": balcony,
						"parking": units?.parking ?? false,
						"parkingSpaces": units?.parkingSpaces ?? [],
						"parkingPrice": parkingPrice,
						'totalParkingPrice': parkingPrice * (units?.parkingSpaces?.length ?? 0),
						"isMultiAgent": units.isMultiAgent ? units.isMultiAgent : false,
						"extraColumn": units?.extracolumn,
						"high_risk" : units.high_risk || false
					}

					data.push(searchItem)

				})

			})
			yield put(getAvailableSearchSuccess(data));
		}
		else {
			document.getElementById('loaderImageSearch').style.display = 'none';
			yield put(getAvailableSearchFailure(response.data.message));
		}
	} catch (error) {
		document.getElementById('loaderImageSearch').style.display = 'none';
		console.log("error", error)
		yield put(getSearchFailure(error));
	}
}


function GetCostReturn(req) {
	let { cost_and_returns, discount } = req;
	let investor_price = 0, reservation = 0, exchange = 0, completion = 0
	if (cost_and_returns && discount)
		investor_price = cost_and_returns.sales_price ? ((cost_and_returns.sales_price) - Math.round((discount / 100) * cost_and_returns.sales_price)) ?? 0 : 'Not defined';
	if (investor_price && cost_and_returns.sales_price && discount) {
		reservation = Math.round(investor_price * (5 / 100));
		exchange = Math.round(investor_price * (25 / 100));
		completion = Math.round(investor_price * (70 / 100));

	}
	return ({
		investor_price, reservation, exchange, completion
	})
}

/**
 * Get development
 */
export function* getDevelopment() {
	yield takeEvery(GET_DEVELOPMENT, getDevelopmentDetailFromServer);
}

/**
 * Get All development
 */
export function* getAllDevelopment() {
	yield takeEvery(GET_ALL_DEVELOPMENT, getDevelopmentAllDetailFromServer);
}

/**
 * Signout User From Firebase
 */
// export function* signOutUsers() {
// 	yield takeEvery(LOGOUT_USER, signOut);
// }

/**
 * Signout User From Firebase
 */
export function* getDevelopmentUnit() {
	yield takeEvery(GET_DEVELOPMENT_UNIT, getDevelopmentUnitFromServer);
}

/**
 * Signout User From Firebase
 */
export function* setHoldStatus() {
	yield takeEvery(SET_STATUS, setStatusUnitFromServer);
}


/**
 * Ger Emails
 */
export function* getAvailableSearch() {
	yield takeEvery(AVAILABLE_SEARCH_UNIT, getAvailableSearchFromServer);
}
/**
 * Email Root Saga
 */
export default function* rootSaga() {
	yield all([
		fork(getDevelopment),
		fork(getAllDevelopment),
		fork(getDevelopmentUnit),
		fork(setHoldStatus),
		fork(getAvailableSearch)
	]);
}