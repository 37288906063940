import React, { Component, Fragment } from 'react'
import Grid from '@material-ui/core/Grid';
const TitleToolTip = ({ calculation_figure }) => {
    return (
        <Fragment>
            <Grid className="toolTipItems">
                <h5 >How the figures are calculated</h5>
                <div dangerouslySetInnerHTML={{ __html: calculation_figure }}></div>
            </Grid>
        </Fragment>
    )
}

export default TitleToolTip
