/**
 * App Reducers
 */
import { combineReducers } from 'redux';


import authUserReducer from './AuthUserReducer';
import searchReducer from './SearchReducer';
import developmentReducer from './DevelopmentReducer'
import NotificationReducer from './NotificationReducer';


const reducers = combineReducers({
  authUser: authUserReducer,
  search: searchReducer,
  development: developmentReducer,
  notification: NotificationReducer
});

export default reducers;
