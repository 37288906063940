import React, { Component, Fragment } from 'react';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { API_URL } from '../../url/apiUrl'
import axios from 'axios';
import { ParkingPopupModel } from '../blog-components/parkingPopup'
import './custom.css';
import { connect } from 'react-redux';
import { getAvailableSearch, setHoldStatus } from '../../actions'
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import HyperModal from 'react-hyper-modal';
import ReactTooltip from 'react-tooltip';
// import { Link } from 'react-router-dom';
import { convertHtmlToPdf, getOneCalculation, setHoldDevStatus, setParkiSpacestoLS } from '../../api';
import { getCurrentDate, returnPdfHtml } from './pdfModal';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Tooltip from "@material-ui/core/Tooltip";
// import TitleToolTip from './TitleToolTip';
import { CSVLink } from 'react-csv';
import Sample from "../section-components/sample"
import Checkbox from "@material-ui/core/Checkbox";
import { PopupFrom } from '../blog-components/PopupForm';

const validUser = ["super_admin", 'sip_admin']
const validAgentAdmin = ['Solomon New Homes Ltd', 'Solomon Investment Partners Ltd']

const checkArray = ['buying_appartment', 'fee_check', 'once_the', 'units_check',
	'the_buyer_check', 'cash_check', 'mortage_check', 'other_check'];
var csvData = [];
var headers = [
	{ label: 'Unit No', key: 'Unit No' },
	{ label: 'Floor No', key: 'Floor No' },
	{ label: 'Development Name', key: 'Development Name' },
	{ label: 'Status', key: 'Status' },
	{ label: 'Type of Unit', key: 'Type of Unit' },
	{ label: 'BedRoom', key: 'BedRoom' },
	{ label: 'Bathroom', key: 'Bathroom' },
	{ label: 'Floor Area msq', key: 'Floor Area msq' },
	{ label: 'Floor Area ftsq', key: 'Floor Area ftsq' },
	{ label: 'Sale price', key: 'Sale price' },
	{ label: 'Discount', key: 'Discount' },
	{ label: 'Investor price', key: 'Investor price' },
	{ label: '5% Reservation', key: '5% Reservation' },
	{ label: '25% Exchange', key: '25% Exchange' },
	{ label: '70% Completion', key: '70% Completion' }
];

// var csvReport = {
// 	filename:'Report.csv',
// 	headers: headers,
// 	data: csvData
// };
class Avaibility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			developments: [],
			bedrooms: [],
			price: [],
			searchform: { getAvail: true },
			type: [], status: [],
			devid: '',
			openPopupForm: false,
			sendDevBroucher: [],
			toolTipStatus: false,
			openClose: false,
			selectedItem: {},
			getPdfFormValues: {
				buying_appartment: false, fee_check: false, once_the: false, units_check: false,
				the_buyer_check: false, cash_check: false, mortage_check: false, other_check: false
			},
			isModalOpen: false,
			tileView: false,
			unitSelect: null,
			indexSelect: -1,
			isModalShareOpen: false,
			isModalMultiShareOpen: false,
			isModalMultiPrintOpen: false,
			showPdfSuccessMsg: false,
			agentUserData: {},
			isModalPrintOpen: false,
			isHoldModalOpen: false,
			selectedMultiUnits: [],
			view: false,
			buttonText: "Grid View",
			sendMail: { fullname: '', message: '', email: '', unitid: '', developmentid: '', discount: '', unitImage: '', floorplan: '' },
			csvReport: {
				filename: 'Report.csv',
				headers: headers,
				data: csvData
			},
			unitsForCSV: [],
			extraDevState: 0,
			openParkingPopup: false,
			marketingBrochure: [],
			parkingPrice: 0,
			parkingSpaces: [],
			parkingTotalPrice: 0,
			isUnitHighRisk: false
		}
	}

	// UNSAFE_componentWillReceiveProps = async (nextProps) => {
	// 	const { extraDevState } = this.state
	// 	const { availablesearchUnits } = nextProps
	// 	if (extraDevState <= 0) {
	// 		this.setState({
	// 			unitsForCSV: availablesearchUnits,
	// 			extraDevState: extraDevState + 1
	// 		})
	// 	}
	// }

	componentDidMount() {
		this.getCalculation()
		this.updateCSV();
		setParkiSpacestoLS(this.props.developmentDetail)

		// this.setState({ availablesearchUnits: this.props.availablesearchUnits })
		this.getAgentDevelopment()
		this.changeSort()
		let { parkingPrice } = this.state
		parkingPrice = this.props.developmentDetail && this.props.developmentDetail?.details?.parkingPrice ? this.props.developmentDetail.details.parkingPrice : 0
		let type = [{ label: 'All', value: 'undefined' }], status = [{ label: 'All', value: 'all' }]
		let bedrooms = [{ label: 'All', value: 'undefined' }, { value: 1, label: 'One' }, { value: 2, label: 'Two' }, { value: 3, label: 'Three' }, { value: 4, label: 'Four' }]
		let rate = 50000;
		let price = []
		for (let i = 1; rate <= 500000; i++) {
			price.push({ value: rate, label: "£" + (rate).toLocaleString() });
			rate = rate + 25000;
		}
		status = [
			{ label: 'All', value: 'all' },
			{ label: 'Available', value: 'available' },
			{ label: 'Not Available', value: 'notAvailable' }
		]
		type = [{ label: 'All', value: 'undefined' }, { label: 'Apartment', value: 'apartment' },
		{ label: 'Penthouse', value: 'penthouse' },
		{ label: 'Studio', value: 'studio' }, { label: 'Duplex', value: 'duplex' }]
		this.setState({ price: price, bedrooms: bedrooms, type: type, status: status, parkingPrice })

		if (this.props.location && this.props.location.search) {
			let getdata = this.props.location.search.split("?")
			let id = getdata[1].split('=')
			if (getdata.length > 1) {
				if (id[0] == 'devid') {
					let searchform = this.state.searchform
					searchform['development'] = id[1]
					this.setState({ devid: id[1], searchform: searchform })
				}
				else {
					let searchform = this.state.searchform
					searchform['development'] = this.props.developmentDetail?._id
					this.setState({ devid: this.props.developmentDetail._id, searchform: searchform })
				}
			}
		}
	}

	getCalculation() {
		getOneCalculation(this.props.token).then(res => {
			if (res.data.success) {
				this.setState({ settingValues: res.data.data })
			}
		})
	}

	componentDidUpdate(prevProps) {
		if (prevProps.availablesearchUnits !== this.props.availablesearchUnits) {
			this.updateCSV();
		}
	}

	changeSort() {
		const $ = window.$;
		let self = this
		if ($('.sortBy').length) {
			$('.sortBy').niceSelect();
			$('.sortBy').change(function (e) {
				self.onSort(e)
			});
		}
	}

	onSort(e) {
		let avlblSortUnit = this.props.availablesearchUnits
		avlblSortUnit && Array.isArray(avlblSortUnit) && avlblSortUnit.length > 1 && avlblSortUnit.sort(function (a, b) {
			let nameA, nameB
			if (e.target.name == "sort_by_investor") {
				nameA = typeof a.investor_price == NaN ? parseInt(a.investor_price) : a.investor_price
				nameB = typeof b.investor_price == NaN ? parseInt(b.investor_price) : b.investor_price
				if (e.target.value == "ascendinng") {
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				}
				if (e.target.value == "descending") {
					if (nameA > nameB) {
						return -1;
					}
					if (nameA < nameB) {
						return 1;
					}
					return 0;
				}
			} else {
				var reA = /[^a-zA-Z]/g;
				var reN = /[^0-9]/g;
				nameA = a.unit_number
				nameB = b.unit_number
				var regex = /^([a-z]*)(\d*)/i;
				var _a = nameA.match(regex);
				var _b = nameB.match(regex);
				if (e.target.value == "ascendinng") {
					// if the alphabetic part of a is less than that of b => -1
					if (_a[1] < _b[1]) return -1;
					// if the alphabetic part of a is greater than that of b => 1
					if (_a[1] > _b[1]) return 1;
					var _n = parseInt(_a[2]) - parseInt(_b[2]);
					if (_n == 0) // if the number parts are equal start a recursive test on the rest
						return 0;
					// else, just sort using the numbers parts
					return _n;
				}
				else if (e.target.value == "descending") {
					if (_a[1] < _b[1]) return 1;
					// if the alphabetic part of a is greater than that of b => 1
					if (_a[1] > _b[1]) return -1;
					var _n = parseInt(_b[2]) - parseInt(_a[2]);
					if (_n == 0) // if the number parts are equal start a recursive test on the rest
						return 0;
					// else, just sort using the numbers parts
					return _n;
				}
				// if the alphabetic parts are equal, check the number parts


			}

		});
		this.setState({ availablesearchUnits: avlblSortUnit })
	}

	openShareButton = async (selectedUnit) => {
		let { sendMail } = this.state;
		this.openShareModal(selectedUnit);
	}

	getAgentDevelopment = async () => {
		try {
			const token = localStorage.getItem('tokenweb');
			const headers = { 'token': `Bearer ${token}` }
			const response = await axios.get(`${API_URL.getAssignedDevelopment}`, { headers });
			if (response) {
				this.setState({ developments: response.data.data.developments })
			}
			const $ = window.$;
			let self = this
			if ($('.single-select').length) {
				$('.single-select').niceSelect();
				$('.single-select').change(function (e) {
					self.onSelect(e)
				})
			}
		}
		catch (error) {
			console.log(error);
		}
		let searchform = this.state.searchform
		this.props.getAvailableSearch(this.props.token, searchform)
	}

	onSelect = (event) => {
		csvData = [];
		let { searchform } = this.state;
		const { value, name, checked } = event.target
		if (event.target.type == 'checkbox') searchform[name] = checked ? checked : false
		else searchform[name] = value;
		this.setState({ searchform: searchform })
	}
	handleToolTip = () => {
		if (this.state.openClose) {
			this.setState({ toolTipStatus: false })
			this.setState({ openClose: false })
		}
		else {
			this.setState({ toolTipStatus: true })
			this.setState({ openClose: true })
		}
	}
	search = async () => {
		csvData = [];
		let { searchform } = this.state;
		this.props.getAvailableSearch(this.props.token, searchform)
		this.updateCSV();

	}
	handleDownload = async () => {
		csvData = [];
	}
	/******************* close popup **************/
	handleClosePopup = () => {
		this.setState({ openPopupForm: false, openParkingPopup: false })
	}
	/******************* end of close popup **************/
	/******************* start of show selected items **************/
	showModel = (item) => {
		let agentUserData = localStorage.getItem('agentUserData');
		agentUserData = JSON.parse(agentUserData);
		item.reservation_fee = item.investor_price ? (item.investor_price * 5 / 100).toFixed(2) ?? 0 : 0;
		this.setState({ openPopupForm: true, selectedItem: item, agentUserData });
	}

	openModal = (high_risk) => this.setState({ isModalOpen: true, isUnitHighRisk: high_risk || false });
	closeModal = () => this.setState({ isModalOpen: false, isUnitHighRisk: false });
	openHoldModal = (unitData, index) => this.setState({ isModalHoldOpen: true, unitSelect: unitData, indexSelect: index });
	closeHoldModal = () => this.setState({ isModalHoldOpen: false, unitSelect: null, indexSelect: -1 });
	openAlreadyonholdModal = () => this.setState({ isHoldModalOpen: true });
	closeAlreadyonholdModal = () => this.setState({ isHoldModalOpen: false });

	openShareModal = (unitData, index) => {
		let sendMail = this.state.sendMail
		sendMail['email'] = '';
		sendMail['fullname'] = '';
		sendMail.message = `Here’s some more information about apartment ${unitData.unit_number}, ${unitData.title}, ${unitData.location}.\n
		For more detailed information about this opportunity, please click-through the links below to download the development’s floor plan and brochures`
		this.setState({ isModalShareOpen: true, unitSelect: unitData, sendMail: sendMail });
	}

	closeShareModal = () => {
		const { sendMail } = this.state
		sendMail['development_brochure'] = []
		this.setState({ isModalShareOpen: false, isModalMultiShareOpen: false, unitSelect: null, sendDevBroucher: [], sendMail: sendMail })
	};



	//update csv
	updateCSV = () => {
		csvData = [];
		let { availablesearchUnits } = this.props;
		// if (availablesearchUnits && availablesearchUnits.length > 0) {
		availablesearchUnits && availablesearchUnits.map((item, i) => {
			// if (item.status == 'available' && item.availStatus !== 'available') {
			csvData.push({
				"Unit No": item.unit_number, "Floor No": item.floor,
				"Development Name": item.title,
				"Status": item.status == 'available' ? 'Available' : 'Not available',
				"Type of Unit": item.type,
				"BedRoom": item.beds || "N/A",
				"Bathroom": item.bath || "N/A",
				"Floor Area msq": item.aream2,
				"Floor Area ftsq": item.area,
				"Sale price": item.sales_price != undefined && "£" + (250 * Math.ceil(item.sales_price / 250)).toLocaleString(),
				"Discount": item.discount && item.sales_price && item.investor_price && "£" + ((250 * Math.ceil(item.sales_price / 250)) - (250 * Math.ceil(item.investor_price / 250))).toLocaleString(),
				"Investor price": item.investor_price != undefined && "£" + (250 * Math.ceil(item.investor_price / 250)).toLocaleString(),
				"5% Reservation": item.reservation != undefined && "£" + item.reservation.toLocaleString(),
				"25% Exchange": item.exchange != undefined && "£" + item.exchange.toLocaleString(),
				"70% Completion": item.completion != undefined && "£" + item.completion.toLocaleString()
			})
		}
			// }
		);
		// csvData.push({"UnitNo": availablesearchUnits})
		var fileData = this.state.csvReport
		fileData['data'] = csvData;
		this.setState({ csvReport: fileData })
		// }
	}

	openPrintModal = (unitData) => {
		let sendMail = this.state.sendMail
		sendMail['email'] = '';
		sendMail['fullname'] = '';
		sendMail.message = `Here’s some more information about apartment ${unitData.unit_number}, ${unitData.title}, ${unitData.location}.\n
		For more detailed information about this opportunity, please click-through the links below to download the development’s floor plan and brochures`
		this.setState({ isModalPrintOpen: true, unitSelect: unitData, sendMail: sendMail });
	}
	closePrintModal = () => this.setState({ isModalPrintOpen: false, isModalMultiPrintOpen: false, unitSelect: null });


	sendMail = () => {
		const { selectedItem, getPdfFormValues, agentUserData, settingValues } = this.state;
		const { developmentDetail } = this.props;
		let dataUsage = developmentDetail?.reservation && developmentDetail?.reservation.defaultSetting ? settingValues?.data_usage ?? '' : developmentDetail?.reservation?.dataUsage ?? settingValues?.data_usage ?? '';
		let ternsAndCond = developmentDetail?.reservation && developmentDetail?.reservation.defaultSetting ? settingValues?.terms ?? '' : developmentDetail?.reservation?.termsConditions ?? settingValues?.terms ?? '';
		let pdfLogo = developmentDetail?.reservation?.logo ?? '';
		let fullLogoUrl = null;
		if (pdfLogo != '') {
			fullLogoUrl = API_URL.BucketURl + pdfLogo;
		}
		let locationUnit = developmentDetail?.details?.location ?? '';
		let getData = localStorage.getItem('tokenweb');
		let formData = {
			htmldata: returnPdfHtml(selectedItem, getPdfFormValues, agentUserData, dataUsage, ternsAndCond, fullLogoUrl, locationUnit),
			type: selectedItem?.type,
			unit_number: selectedItem?.unit_number,
			development: selectedItem?.title
		}
		document.getElementById('preloader').style.display = 'block';
		convertHtmlToPdf(getData, formData).then((request) => {
			if (request.data?.hassuccessed == true) {
				this.setState({ showPdfSuccessMsg: true });
				document.getElementById('preloader').style.display = 'none';
				setTimeout(() => {
					this.setState({
						openPopupForm: false, showPdfSuccessMsg: false, selectedItem: {},
						getPdfFormValues: {
							buying_appartment: false, fee_check: false, once_the: false, units_check: false,
							the_buyer_check: false, cash_check: false, mortage_check: false, other_check: false
						}
					});
				}, 2000);
			}
			else document.getElementById('preloader').style.display = 'none';
		}).catch((error) => {
			console.log(error);
		});
	}

	handleChangeShare = (event, file) => {
		let { sendMail, sendDevBroucher } = this.state;
		const { name, value, checked } = event.target
		if (name == 'development_brochure') {
			if (checked == true) {
				sendDevBroucher.push(file)
				sendMail[name] = sendDevBroucher;
			} else {
				const filterBrochure = sendDevBroucher.filter((val) => val !== file.name);
				sendMail[name] = filterBrochure;
			}
		} else {
			sendMail[name] = value;
		}
		this.setState({ sendMail })
	}

	handleChangeForm = (event) => {
		const { getPdfFormValues, selectedItem } = this.state;
		if (checkArray.includes(event.target.name)) {
			if (getPdfFormValues[event.target.name] == false) {
				getPdfFormValues[event.target.name] = true;
			}
			else {
				getPdfFormValues[event.target.name] = false;
			}
		}
		else {
			if (event.target.name === 'reservation_fee') {
				selectedItem.reservation_fee = event.target.value;
			}
			else if (event.target.name === 'purchaseprice') {
				selectedItem.investor_price = event.target.value;
			}
			else
				getPdfFormValues[event.target.name] = event.target.value;
		}
		this.setState({ getPdfFormValues, selectedItem });
	}
	/******************* end of show selected items **************/
	holdUnhold = async (unitData, index) => {

		if (unitData.status == 'hold' && !unitData['hold_by_me']) {
		}
		else { this.openHoldModal(unitData, index) }
	}

	confirmHoldOnhold = () => {
		let unitData = this.state.unitSelect;
		let index = this.state.indexSelect;
		let devlopmentid = unitData.id,
			unitid = unitData.unit_id,
			status = unitData.status !== 'hold' ? 'hold' : 'available';
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let parkingSpaces = unitParkingSpace?.length > 0 && unitParkingSpace.filter(space => space.unit_id == unitData.unit_id)
		unitData['status'] = status
		unitData['hold_by_me'] = true
		let availablesearchUnits = this.props.availablesearchUnits;
		availablesearchUnits[index]['status'] = status
		// this.openHoldModal( unitData)
		// this.props.setHoldStatus(this.props.token, devlopmentid, unitid, status, availablesearchUnits);
		this.closeHoldModal()
		setHoldDevStatus(this.props.token, devlopmentid, unitid, status, parkingSpaces)
			.then((resp) => {
				if (resp.data.success) {
					if (status === 'hold') this.openModal(unitData?.high_risk)
				}
				else if (status === 'hold' && resp.data.type == "UNABLE_TO_UPDATE_ALREADY_HOLD") {
					this.openAlreadyonholdModal()
				}
			})
		// setTimeout(() => {
		// 	this.closeHoldModal()
		// 	if (status === 'hold') this.openModal()
		// }, 1000);
	}

	confirmShare = async () => {
		let { sendMail, unitSelect } = this.state
		let filteredParking = await this.filterParkingUnits(unitSelect)
		sendMail['unitid'] = unitSelect.unit_id;
		sendMail['developmentid'] = unitSelect.id;
		sendMail['discount'] = unitSelect.discount;
		sendMail['unitImage'] = unitSelect.image;
		sendMail['parkingSpaces'] = filteredParking;
		sendMail['floorplan'] = unitSelect.floorplan
		this.setState({ loading: true })
		const headers = { 'token': `Bearer ${this.props.token}` }
		const response = await axios.post(`${API_URL.sendShareMail}`, sendMail, { headers });
		if (response) {
			let res = response.data
			this.setState({ loading: false })
			if (res.statusCode === 200) {
				this.setState({ successMsg: 'Email is Sent to the user' })
				setTimeout(() => {
					this.setState({ successMsg: null })
					this.closeShareModal()
				}, 2000);
			}
		}
	}



	handleView = (e) => {
		// if (this.state.view === true) {
		this.setState({ tileView: e.target.checked })
		// 	this.setState({ buttonText: "List View" })
		// }
		// else if (this.state.view === false) {
		// 	this.setState({ view: true })
		// 	this.setState({ buttonText: "Grid View" })
		// }
	}

	filterParkingUnits = async (unitSelect) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let filteredParking = unitParkingSpace && unitParkingSpace.length > 0 && await unitParkingSpace.filter(park => park.unit_id == unitSelect.unit_id)
		return filteredParking
	}

	confirmPrint = async () => {
		let { sendMail, unitSelect } = this.state
		let filteredParking = await this.filterParkingUnits(unitSelect)
		sendMail['unitid'] = unitSelect.unit_id;
		sendMail['developmentid'] = unitSelect.id;
		sendMail['discount'] = unitSelect.discount;
		sendMail['unitImage'] = unitSelect.image;
		sendMail['parkingSpaces'] = filteredParking
		sendMail['floorplan'] = unitSelect.floorplan
		this.setState({ loading: true })
		const headers = { 'token': `Bearer ${this.props.token}` }
		const response = await axios.post(`${API_URL.sendPrintData}`, sendMail, { headers });
		if (response) {
			this.setState({ loading: false })
			let res = response.data
			if (res.statusCode === 200) {
				var myWindow = window.open('', '', '');
				myWindow.document.write(res.data);
				myWindow.document.close(); //missing code
				myWindow.focus();
				setTimeout(() => { myWindow.print(); }, 200)
			}
		}
		else {
			this.setState({ loading: false })
		}
	}

	seeView = (link) => {
		window.open("/#/doc-view?url=" + link, "_blank")
	}

	selectMultiUnits = (e, item) => {
		let { selectedMultiUnits, developmentid, davelopmentTitle, developmentLocation } = this.state
		if (selectedMultiUnits.length <= 0) {
			developmentid = item.id;
			davelopmentTitle = item.title
			developmentLocation = item.location
			this.setState({ developmentid, davelopmentTitle, developmentLocation })
		}
		if (e.target.checked == true) {
			let data = {}
			data['unitid'] = item.unit_id;
			data['discount'] = item.discount;
			data['unitImage'] = item.image;
			data['floorplan'] = item.floorplan
			data['unitNumber'] = item.unit_number
			selectedMultiUnits.push(data)
			this.setState({ selectedMultiUnits })
		} else {
			const filteredPeople = selectedMultiUnits.filter((val) => val.unitid !== item.unit_id);
			this.setState({ selectedMultiUnits: filteredPeople })
		}
	}

	returnUnitNo = (selectedMultiUnits) => {
		let nmbr = selectedMultiUnits.map(data => {
			return data.unitNumber
		})
		return nmbr
	}

	openMultiShareButton = () => {
		let { selectedMultiUnits, developmentLocation, davelopmentTitle, sendMail } = this.state
		sendMail.message = `Here’s some more information about apartments ${this.returnUnitNo(selectedMultiUnits)}, ${davelopmentTitle}, ${developmentLocation}.\n
		For more detailed information about this opportunity, please click-through the links below to download the development’s floor plan and brochures`
		this.setState({ sendMail: sendMail, isModalMultiShareOpen: true })
	}

	openMultiPrintModal = () => {
		let { selectedMultiUnits, developmentid, davelopmentTitle, developmentLocation, sendMail } = this.state
		sendMail.message = `Here’s some more information about apartments ${this.returnUnitNo(selectedMultiUnits)}, ${davelopmentTitle}, ${developmentLocation}.\n
		For more detailed information about this opportunity, please click-through the links below to download the development’s floor plan and brochures`
		this.setState({ sendMail: sendMail, isModalMultiPrintOpen: true })
	}

	sendMultiUnit = async () => {
		let { selectedMultiUnits, developmentid, davelopmentTitle, sendMail } = this.state
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		sendMail['developmentid'] = developmentid
		sendMail['davelopmentTitle'] = davelopmentTitle
		sendMail['parkingSpaces'] = unitParkingSpace?.length > 0 ? unitParkingSpace : []
		sendMail['unitsData'] = selectedMultiUnits
		this.setState({ loading: true })
		const headers = { 'token': `Bearer ${this.props.token}` }
		const response = await axios.post(`${API_URL.shareMultiUnit}`, sendMail, { headers });
		if (response) {
			let res = response.data
			this.setState({ loading: false })
			if (res.statusCode === 200) {
				this.setState({ successMsg: 'Email is Sent to the user', selectedMultiUnits: [] })
				setTimeout(() => {
					this.setState({ successMsg: null })
					this.closeShareModal()
				}, 2000);
			}
		}
	}

	printMultiUnit = async () => {
		let { selectedMultiUnits, developmentid, davelopmentTitle, sendMail } = this.state
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		sendMail['developmentid'] = developmentid
		sendMail['developmentTitle'] = davelopmentTitle
		sendMail['unitsData'] = selectedMultiUnits
		sendMail['parkingSpaces'] = unitParkingSpace?.length > 0 ? unitParkingSpace : []

		this.setState({ loading: true })
		const headers = { 'token': `Bearer ${this.props.token}` }
		const response = await axios.post(`${API_URL.printMultiUnit}`, sendMail, { headers });
		if (response) {
			this.setState({ loading: false })
			let res = response.data
			if (res.statusCode === 200) {
				var myWindow = window.open('', '', '');
				myWindow.document.write(res.data);
				myWindow.document.close(); //missing code
				myWindow.focus();
				setTimeout(() => { myWindow.print(); }, 200)
				this.closePrintModal()
			}
		}
		else {
			this.setState({ loading: false })
		}
	}

	addParkingModel = (item) => {
		let agentUserData = localStorage.getItem('agentUserData');
		agentUserData = JSON.parse(agentUserData);
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let parkingSpaces = unitParkingSpace?.length > 0 && unitParkingSpace.filter(space => space.unit_id == item.unit_id)
		this.setState({ openParkingPopup: true, selectedItem: item, agentUserData, parkingSpaces: parkingSpaces ?? [] });
	}
	handleAddParking = async (event) => {
		let { parkingSpaces, selectedItem } = this.state
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let data = await event.map(parking => {
			parking["unit_id"] = selectedItem.unit_id
			return parking
		})
		if (unitParkingSpace?.length > 0) {
			let checkUnitUse = unitParkingSpace.filter(spaces => spaces.unit_id != selectedItem.unit_id)
			let checkUnit = unitParkingSpace.filter(spaces => spaces.unit_id == selectedItem.unit_id)
			if (checkUnit.length > data.length) {
				unitParkingSpace = [...checkUnitUse, ...data]
			} else if (checkUnit.length < data.length) {
				data.map((dat, i) => {
					if (data.length == i + 1)
						unitParkingSpace.push(dat)
				})
			}
			localStorage.setItem("unitParkingSpace", JSON.stringify(unitParkingSpace))
		} else {
			localStorage.setItem("unitParkingSpace", JSON.stringify(data))
		}
		this.setState({ parkingSpaces: event })
	}
	submitParkingSpaces = async () => {
		this.setState({ openParkingPopup: false })
	}

	parkingOption = (data) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		if (unitParkingSpace && unitParkingSpace.length > 0) {

			unitParkingSpace.filter(space => {
				data.map((val, index) => {
					if (val.value == space.value) data.splice(index, 1)
				})
			})
			return data
		} else {
			return data
		}
	}

	calcParkingFigure = (item) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let price = 0
		unitParkingSpace?.length > 0 && unitParkingSpace.map(space => {
			if (item.unit_id == space.unit_id) price += 1
		})
		if (price > 0) {
			return price + " X £" + item?.parkingPrice
		} else {
			return 0
		}
	}

	calcParkingPrice = (item) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let price = 0
		unitParkingSpace?.length > 0 && unitParkingSpace.map(space => {
			if (item.unit_id == space.unit_id) price += item.parkingPrice
		})
		return price
	}
	retCalculationInvest = (item, per, useFor) => {
		const cal = this.calcParkingPrice(item)
		let amount = cal + parseInt(item.investor_price)
		if (item.overRideValus == true) {
			per = useFor == 'completion' ? item?.overridePercentage['completion'] : useFor == 'reservation' ? item?.overridePercentage['reservation'] : useFor == 'exchange' ? item?.overridePercentage['exchange'] : per
		}
		const retValue = (amount / 100) * per
		return Math.round(retValue).toLocaleString()
	}

	checkRECPercantage = (item, useFor) => {
		let { overridePercentage, overRideValus } = item
		if (!overRideValus || overRideValus == false) {
			return true
		} else {
			if (overridePercentage[useFor] && overridePercentage[useFor] > 0) {
				return true
			} else {
				return false
			}
		}
	}
	retParkingSPace = (item) => {
		let unitParkingSpace = JSON.parse(localStorage.getItem('unitParkingSpace'))
		let arr = []

		unitParkingSpace && unitParkingSpace.length > 0 && unitParkingSpace.map(res => {
			if (item.unit_id == res.unit_id) {
				arr.push(res.label)
			}
		})
		return arr
	}
	render() {
		const { marketingBrochure, parkingPrice, bedrooms, tileView, parkingSpaces, price, type, status, selectedMultiUnits, showPdfSuccessMsg, isModalOpen, isUnitHighRisk, isModalHoldOpen, selectedItem, isModalShareOpen, isModalMultiPrintOpen, isModalMultiShareOpen, successMsg, agentUserData, loading, isModalPrintOpen, settingValues, isHoldModalOpen, toolTipStatus, unitsForCSV } = this.state;
		let { availablesearchUnits, developmentDetail } = this.props;
		let imagealt = 'image'
		let user_detail = JSON.parse(localStorage.getItem("agentUserData"))
		let publicUrl = process.env.PUBLIC_URL + '/'
		let Customclass = this.props.Customclass ? this.props.Customclass : '';
		let availableParkingList = []
		let setparkingsToLS = []
		developmentDetail?.parking?.length > 0 && developmentDetail.parking.map(async park => {
			if (park?.status?.value == 'available') {
				availableParkingList.push({ value: park?._id, label: park?.parking_no })
			}
		})
		developmentDetail?.marketing?.brochure?.length > 0 && developmentDetail.marketing.brochure.map(data => {
			if (data.selectedAgent && data.selectedAgent.selected === 'seleted') {
				let containdata = data.selectedAgent.agentids.find(ele => user_detail.userType == 'agent' ? (ele.value === '' + user_detail['admin_id']) : (ele.value === '' + user_detail['id']))
				if (containdata && !marketingBrochure.includes(data.name)) {
					marketingBrochure.push(data.name)
					this.setState({ marketingBrochure })
				}
			} else if (data.fileInfo && data.fileInfo.filename) {
				if (!marketingBrochure.includes(data.name)) {
					marketingBrochure.push(data.name)
					this.setState({ marketingBrochure })
				}
			}
		})

		let dataUsage = developmentDetail?.reservation && developmentDetail.reservation.defaultSetting ? settingValues?.data_usage ?? '' : developmentDetail?.reservation?.dataUsage ?? settingValues?.data_usage ?? '';
		let ternsAndCond = developmentDetail?.reservation && developmentDetail.reservation.defaultSetting ? settingValues?.terms ?? '' : developmentDetail?.reservation?.termsConditions ?? settingValues?.terms ?? '';
		let pdfLogo = developmentDetail?.reservation?.logo ?? '';
		let locationUnit = developmentDetail?.details?.location ?? '';

		return <div>
			<div className="container">
				<Modal
					open={isModalOpen}
					onClose={() => { this.closeModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeModal}>&times;</span>
						<div className="modal-custom">
							<div className="modal-content-custom">
								We’ve held this apartment for you. It will be released in {isUnitHighRisk ? "120" : "48"} hours unless we receive a reservation payment.
							</div>
						</div>
					</Grid>
				</Modal>
				<Modal
					open={isModalHoldOpen}
					onClose={() => { this.closeHoldModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeHoldModal}>&times;</span>
						<div className="modal-custom">
							{this.state.unitSelect && <div className="modal-content-custom">
								<p>Do you want to {this.state.unitSelect.status !== 'hold' ? 'hold' : 'release'} the unit {this.state.unitSelect.unit_number}</p>
								<a onClick={e => this.confirmHoldOnhold()} className="imgHold">Yes</a>&nbsp;
								<a className="imgHold" onClick={() => this.setState({ isModalHoldOpen: false })}>Cancel</a>
							</div>}
						</div>
					</Grid>
				</Modal>
				<Modal
					open={isHoldModalOpen}
					onClose={() => { this.closeAlreadyonholdModal() }}
					className={'modal-classes-data'}
				>
					<Grid className="rejectBoxCntnt modal-share">
						<span class="close" onClick={this.closeAlreadyonholdModal}>&times;</span>
						<div className="modal-custom">
							<div className="modal-content-custom">
								Unfortunately this property is now on hold with another agent. The reason the 'hold' button was still displaying is because your page has not been refreshed recently.
							</div>
						</div>
					</Grid>

				</Modal>
				<div className="section-title text-center margin-title">
					<h2 className="title">{'Availability'}</h2>
				</div>
				<div className="rld-main-search">
					<div className="row">
						<div className="inline_conatiner">
							<div className="inline_view">
								<div className="rld-single-select">
									<select name="status" className="select single-select" onChange={(e) => this.onSelect(e)}>
										<option value="" selected disabled>Status</option>
										{status && status.map((item) => (
											<option value={item.value} >{item.label}</option>
										))}
									</select>
								</div>
							</div>
							<div className="inline_view">
								<div className="rld-single-select">
									<select name="type" className="select single-select" onChange={(e) => this.onSelect(e)}>
										<option value="" selected disabled>Type</option>
										{type && type.map((item) => (
											<option value={item.value} >{item.label}</option>
										))}
									</select>
								</div>
							</div>
							<div className="inline_view">
								<div className="rld-single-select">
									<select className="select single-select" name="bedrooms" onChange={(e) => this.onSelect(e)}>
										<option value="" selected disabled>Bedrooms</option>
										{bedrooms && bedrooms.map(item => (
											<option value={item.value}>{item.label}</option>
										))}
									</select>
								</div>
							</div>
							<div className="inline_view">
								<div className="rld-single-select">
									<select className="select single-select" name="min_price" onChange={(e) => this.onSelect(e)}>
										<option value="" selected disabled>Min Price</option>
										{price && price.map((item) => (
											<option value={item.value}>{item.label}</option>
										))}
									</select>
								</div>
							</div>
							<div className="inline_view">
								<div className="rld-single-select">
									<select className="select single-select" name="max_price" onChange={(e) => this.onSelect(e)}>
										<option value="" selected disable>Max Price</option>
										{price && price.map((item) => (
											<option value={item.value}>{item.label}</option>
										))}
									</select>
								</div>
							</div>
							<div className="inline_view inline_view_btn">
								<div>
									<div className="btn btn-yellow" onClick={this.search}>SEARCH</div>
								</div>
							</div>
							<div className="inline_view inline_view_btn">
								<div>
									{this.state.csvReport?.data && this.state.csvReport.data.length > 0 &&
										<CSVLink {...this.state.csvReport}>

											<div className="btn btn-yellow" onClick={this.handleDownload} >DOWNLOAD CSV</div>
										</CSVLink>
									}
								</div>
							</div>
							{(!this.state.searchform['status'] || this.state.searchform['status'] !== 'notAvailable') && <div className="col-xl-12 col-lg-12 col-md-12">
								<p><input type="checkbox" name="getHold" onClick={e => this.onSelect(e)} /> Show properties currently on hold </p>
								{/* <button className="btn-yellow ListType" onClick={this.handleView}>Change View</button> */}
							</div>}
						</div>
					</div>
				</div>
			</div >
			<div className={"featured-area  " + Customclass}>
				<div className="container">
					<div className="rld-main-search">
						<div className="row">
							<div className="inline_conatiner">
								<div className="inline_view">
									<div className="rld-single-select">
										<select className="select sortBy" name="sort_by_investor" >
											<option value="" selected disabled>Sort by Investor price:</option>
											<option value="ascendinng">Price low to high</option>
											<option value="descending">Price high to low</option>
										</select>
									</div>
								</div>
								<div className="inline_view">
									<div className="rld-single-select">
										<select className="select sortBy" name="sort_by_unit" onChange={(e) => this.onSortByUnit(e)} >
											<option value="" selected disabled>Sort by Unit</option>
											<option value="ascendinng">Ascending</option>
											<option value="descending">Descending</option>
										</select>
									</div>
								</div>
								<div className="inline_view">
									<div >
										<label>{'Detail View'}</label>&nbsp;
										<Switch className='MuiSwitch-colorPrimary switchColor' color="primary" onChange={this.handleView} checked={this.state.tileView} />
										&nbsp;<label>{"List View"}</label>
									</div>
								</div>
								{selectedMultiUnits && selectedMultiUnits.length > 1 && <div className="inline_view">
									<div className="holdShrRght holdShrRghtNew holdShrRghtNewFor" >
										<a onClick={() => this.openMultiShareButton()} data-tip="Share units via mail" ><i class="fas fa-share-alt"></i></a>
									</div>
									<div className="holdShrRght holdShrRghtNew holdShrRghtNewFor" style={{ marginRight: '12px' }}>
										<a onClick={() => this.openMultiPrintModal()} data-tip="Print Unit" ><i class="fas fa-print"></i></a>
									</div>
								</div>}

							</div>
						</div>
					</div>

					{tileView === false &&
						<div className="row justify-content-center">
							<div>
								{/* "sip_progress_management" */}
								{console.log("availablesearchUnits", availablesearchUnits)}
								{availablesearchUnits?.map((item, i) => (
									<div className="row avblContnt">
										<div className="avblLft">
											<img src={item.image} /><a className={item.status == 'available' ? 'available' : 'notAvailable'}>{item.availStatus == 'available' ? 'coming soon' : item.status == 'available' ? 'available' : 'not Available'}</a>
										</div>
										<div className="avblRght">
											<div className="apprtSec">
												<div className="apprtSecLft">
													<Checkbox className='avlcheck' color="#1ca7a7" checked={selectedMultiUnits.some(unit => unit.unitid == item.unit_id)} onChange={(e) => this.selectMultiUnits(e, item)} />
													<label>{item.title} <b>{item.unit_number} {item.floor} Floor</b> {item.type}</label>
												</div>
												<div className="apprtSecRgth">
													{item.beds &&
														<a><i className="fa fa-bed" data-tip="Bedroom"></i><span>{item.beds}</span></a>}
													{item.bath && <a><i className="fa fa-bath" data-tip="Bathroom"></i><span>{item.bath}</span></a>}
													{item.study && <a><img width='15px' src={require("../../assets/img/study.svg")} data-tip="Study room" /><span>{item.study}</span></a>}
													{item.balcony && <a><img width='15px' src={require("../../assets/img/balcony.svg")} data-tip="Balcony" /><span>{item.balcony}</span></a>}

													{item.area && <a><img src={publicUrl + "assets/img/icons/7.png"} data-tip="Area" /><span>{item.area} ft<sup>2</sup></span></a>}
													{item.aream2 && <a><img src={publicUrl + "assets/img/icons/7.png"} data-tip="Area m2" /><span>{item.aream2} m<sup>2</sup></span></a>}
													<a><img src={this.calcParkingPrice(item) > 0 ? require("../../assets/img/p.png") : require("../../assets/img/grayP.png")} data-tip="Parking Space" /><span>{this.calcParkingFigure(item)}</span></a>
													<div className="holdShrRght holdShrRghtNew holdShrRghtNewFor">
														{(validUser.includes(this.props.userType) === true || validAgentAdmin.includes(user_detail.sales_agency) === true) &&
															<a onClick={() => {
																this.props.history.push(`/sample/${btoa(JSON.stringify({
																	Investor_price: item.investor_price.toLocaleString(),
																	Development_name: item.title,
																	unit_no: item.unit_number,
																	dev_location: item.location,
																	email: user_detail.email,
																	sales_agent_contact: user_detail.sales_agent_contact,
																	firstName: user_detail.firstName,
																	lastName: user_detail.lastName,
																	image: item.image,
																	capGrowth: developmentDetail.capitalGrowth,
																	assured_rental: item.assuredRentel,
																	ground_rent_option: item.ground_rent_option,
																	ground_rent: item.ground_rent_option === 'amount' ? item.ground_rent : ((item.investor_price * item.ground_rent) / 100),
																	serviceCharge: item.serviceCharge ? item.serviceCharge : '',
																	area: item && item.area ? item.area : 0
																}))}`);
															}}><i class="fas fa-chart-line"></i></a>}
														<a onClick={e => this.openShareButton(item)} data-tip="Share Unit via mail"><i class="fas fa-share-alt"></i></a>
														<a onClick={e => this.openPrintModal(item)} data-tip="Print Unit"><i class="fas fa-print"></i></a>
													</div>

												</div>
											</div>
											<div className="salDiscount">
												<a><label>Sale price</label><span>£{item.sales_price != undefined && ((250 * Math.ceil(item.sales_price / 250))).toLocaleString()}</span></a>
												<a><label>Discount</label><span>£{item.discount != undefined && item.sales_price && ((250 * Math.ceil(item.sales_price / 250)) - (250 * Math.ceil(item.investor_price / 250))).toLocaleString()}</span></a>
												<a className='investLabel'><label>Investor price</label><span>£{item.investor_price != undefined && ((250 * Math.ceil(item.investor_price / 250))).toLocaleString()}</span></a>
												{this.calcParkingPrice(item) > 0 && <a><label className='parkingLabel'>Parking Included</label><span>£{item.investor_price != undefined && this.calcParkingPrice(item).toLocaleString()}</span></a>}
											</div>
											<div className="holdShr">
												<div className="holdShrLft">
													{this.checkRECPercantage(item, 'reservation') &&
														<a><label>{item.overRideValus == true && item.overridePercentage?.reservation ? item.overridePercentage?.reservation : item.overRideValus == true && item.overridePercentage?.reservation == 0 ? 0 : 5}% Reservation</label><span>£{item.reservation != undefined && this.retCalculationInvest(item, 5, 'reservation')}</span></a>
													}
													{this.checkRECPercantage(item, 'exchange') &&
														<a><label>{item.overRideValus == true && item.overridePercentage?.exchange ? item.overridePercentage?.exchange : item.overRideValus == true && item.overridePercentage?.exchange == 0 ? 0 : 25}% Exchange</label><span>£{item.exchange != undefined && this.retCalculationInvest(item, 25, 'exchange')}</span></a>
													}
													{this.checkRECPercantage(item, 'completion') &&
														<a><label>{item.overRideValus == true && item.overridePercentage?.completion ? item.overridePercentage?.completion : item.overRideValus == true && item.overridePercentage?.completion == 0 ? 0 : 70}% Completion</label><span>£{item.completion != undefined && this.retCalculationInvest(item, 70, 'completion')}</span></a>
													}
													<div className="bottomSec">
														{item.floorplan && <a onClick={() => this.seeView(API_URL.BucketURl + item.floorplan)} className="imgHold floorPlan">{'Floorplan'}</a>}
														{(item.availStatus !== 'available' && item.status == 'available' || item.status == 'hold') && <a onClick={e => this.holdUnhold(item, i)} className={item.status == 'hold' ? item['hold_by_me'] ? "hold-by-me imgHold" : "hold-by-other imgHold" : "imgHold"} disabled={item.status == 'hold' && !item['hold_by_me']}>{item.status !== 'hold' ? 'Hold' : 'On Hold'} </a>}
														{(this.props.userType === 'sip_admin' && item.availStatus !== 'available' && item.status == 'available' || (this.props.userType === 'sip_admin' && item.status != 'hold' && item['hold_by_me'])) && <a onClick={e => this.showModel(item)} className="imgHold reserve">{'Reserve'}</a>}
														{item.parking == true && availableParkingList && availableParkingList.length > 0 && <a onClick={e => this.addParkingModel(item)} className="imgHold addparking">{'+ Parking'}</a>}
													</div>
												</div>
												<div className="holdShrRght">
													{item.isMultiAgent && <img src={publicUrl + 'assets/img/multi_agent.png'} data-tip data-for='agentid' className="multi-img-avail" />}

												</div>
												<ReactTooltip id='agentid' type='warning' effect='solid'>
													<span>Multi agent listing</span>
												</ReactTooltip>
											</div>
										</div>
										<ReactTooltip />

									</div>
								))}

							</div>
						</div>
					}
				</div>
				{/* Grid view  */}
				{tileView === true &&
					<div className="container-fluid LiStVIECONTAINER">
						<div className="row apartUpr">
							{availablesearchUnits && availablesearchUnits.map((item, i) => (
								<div className="apartMain">
									<div className="apartSec">
										<div className="apartSecLft">
											<Checkbox color="#1ca7a7" checked={selectedMultiUnits.some(unit => unit.unitid == item.unit_id)} onChange={(e) => this.selectMultiUnits(e, item)} />
											<label>{item.title} <b>{item.unit_number} {item.floor} Floor</b> {item.type}</label>
											<a class={item.status == 'available' ? "availblBtn" : "notAvailableBtn"}>{item.availStatus == 'available' ? 'COMING SOON' : item.status == 'available' ? 'AVAILABLE' : 'NOT AVAILABLE'}</a>
										</div>
										<div className="apartSecMid">
											{item.beds && <a><i className="fa fa-bed" data-tip="Bedroom"></i><span>{item.beds}</span></a>}
											{item.bath && <a><i className="fa fa-bath" data-tip="Bathroom"></i><span>{item.bath}</span></a>}
											{item.study && <a><img width='15px' src={require("../../assets/img/study.svg")} data-tip="Study room" /><span>{item.study}</span></a>}
											{item.balcony && <a><img width='15px' src={require("../../assets/img/balcony.svg")} data-tip="Balcony" /><span>{item.balcony}</span></a>}

											{item.area && <a><img src={publicUrl + "assets/img/icons/7.png"} data-tip="Area" /><span>{item.area} ft<sup>2</sup></span></a>}
											{item.aream2 && <a><img src={publicUrl + "assets/img/icons/7.png"} data-tip="Area m2" /><span>{item.aream2} m<sup>2</sup></span></a>}
											<a><img src={this.calcParkingPrice(item) > 0 ? require("../../assets/img/p.png") : require("../../assets/img/grayP.png")} data-tip="Parking Space" /><span>{this.calcParkingFigure(item)}</span></a>
										</div>
										<div className="apartSecRght">
											{item.floorplan && <a onClick={() => this.seeView(API_URL.BucketURl + item.floorplan)} className="imgHold floorPlan">{'Floorplan'}</a>}
											{(item.availStatus !== 'available' && item.status == 'available' || item.status == 'hold') && <a onClick={e => this.holdUnhold(item, i)} className={item.status == 'hold' ? item['hold_by_me'] ? "hold-by-me imgHold" : "hold-by-other imgHold" : "imgHold"} disabled={item.status == 'hold' && !item['hold_by_me']} >{item.status !== 'hold' ? 'Hold' : 'On Hold'} </a>}
											{(this.props.userType === 'sip_admin' && item.availStatus !== 'available' && item.status == 'available' || (item.status != 'hold' && item['hold_by_me'])) && <a onClick={e => this.showModel(item)} className="imgHold reserve">{'Reserve'}</a>}
											{item.parking && availableParkingList && availableParkingList.length > 0 && <a onClick={e => this.addParkingModel(item)} className="imgHold addparking">{'+ Parking'}</a>}
											{/* <a className="holdBtn btnPair">Hold</a>
											<a className="reservBtn btnPair">Reserve</a> */}
											<span className="holdShrRght holdShrRghtNew">
												{(validUser.includes(this.props.userType) === true || validAgentAdmin.includes(user_detail.sales_agency) === true) &&
													<a onClick={() => {
														this.props.history.push(`/sample/${btoa(JSON.stringify({
															Investor_price: item.investor_price.toLocaleString(),
															Development_name: item.title,
															unit_no: item.unit_number,
															dev_location: item.location,
															email: user_detail.email,
															sales_agent_contact: user_detail.sales_agent_contact,
															firstName: user_detail.firstName,
															lastName: user_detail.lastName,
															image: item.image,
															capGrowth: developmentDetail.capitalGrowth,
															assured_rental: item.assuredRentel,
															ground_rent_option: item.ground_rent_option,
															ground_rent: item.ground_rent_option === 'amount' ? item.ground_rent : ((item.investor_price * item.ground_rent) / 100),
															serviceCharge: item.serviceCharge ? item.serviceCharge : '',
															area: item && item.area ? item.area : 0
														}))}`)
													}}><i class="fas fa-chart-line"></i></a>
												}
												<a onClick={e => this.openShareButton(item)} data-tip="Share Unit via mail"><i class="fas fa-share-alt"></i></a>
												<a onClick={e => this.openPrintModal(item)} data-tip="Print Unit"><i class="fas fa-print"></i></a>
											</span>
										</div>
									</div>
									<div class="apartDiscount">
										<a><label>Sale price</label><span>£{item.sales_price != undefined && ((250 * Math.ceil(item.sales_price / 250))).toLocaleString()}</span></a>
										<a><label>Discount</label><span>£{item.discount != undefined && item.sales_price && ((250 * Math.ceil(item.sales_price / 250)) - (250 * Math.ceil(item.investor_price / 250))).toLocaleString()}</span></a>
										<a><label className='investLabel'>Investor price</label><span>£{item.investor_price != undefined && ((250 * Math.ceil(item.investor_price / 250))).toLocaleString()}</span></a>
										{this.calcParkingPrice(item) > 0 && <a><label className='parkingLabel'>Parking Included</label><span>£{item.investor_price != undefined && this.calcParkingPrice(item).toLocaleString()}</span></a>}
										{this.checkRECPercantage(item, 'reservation') &&
											<a><label>{item.overRideValus == true && item.overridePercentage?.reservation ? item.overridePercentage?.reservation : item.overRideValus == true && item.overridePercentage?.reservation == 0 ? 0 : 5}% Reservation</label><span>£{item.reservation != undefined && this.retCalculationInvest(item, 5, 'reservation')}</span></a>
										}
										{this.checkRECPercantage(item, 'exchange') &&
											<a><label>{item.overRideValus == true && item.overridePercentage?.exchange ? item.overridePercentage?.exchange : item.overRideValus == true && item.overridePercentage?.exchange == 0 ? 0 : 25}% Exchange</label><span>£{item.exchange != undefined && this.retCalculationInvest(item, 25, 'exchange')}</span></a>
										}
										{this.checkRECPercantage(item, 'completion') &&
											<a><label>{item.overRideValus == true && item.overridePercentage?.completion ? item.overridePercentage?.completion : item.overRideValus == true && item.overridePercentage?.completion == 0 ? 0 : 70}% Completion</label><span>£{item.completion != undefined && this.retCalculationInvest(item, 70, 'completion')}</span></a>
										}</div>
								</div>
							))}
						</div>
					</div>
				}
			</div>

			<Modal
				open={isModalShareOpen}
				onClose={() => { this.closeShareModal() }}
				className={'modal-classes-data'}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
			>
				<Grid className="rejectBoxCntnt modal-share">
					<span class="close" onClick={this.closeShareModal}>&times;</span>
					<div className="col-sm-12">
						{successMsg && <span>{successMsg}</span>}
						<div className="row">
							<div className="col-sm-4">
								<label>Email: </label>
							</div>
							<div className="col-sm-8">
								<input type="email" name="email" id="email" value={this.state.sendMail?.email ?? ''} onChange={this.handleChangeShare} className="" />
							</div>

						</div>
						<div className="row">
							<div className="col-sm-4">
								<label>First name: </label>
							</div>
							<div className="col-sm-8">
								<input type="text" name="fullname" id="fullname" value={this.state.sendMail?.fullname ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<label>Message: </label>
							</div>
							<div className="col-sm-8">
								<textarea name="message" id="message" value={this.state.sendMail?.message ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-8">
								<div><label>Include link too: </label></div>
								{marketingBrochure && marketingBrochure.length > 0 && marketingBrochure.map(file => (
									<div className='optioninShare'>
										<a><input type='checkbox' name="development_brochure" id="development_brochure" className="" onChange={(e) => this.handleChangeShare(e, file)} /></a>
										<label>{file} </label>
									</div>
								))}
							</div>
						</div>

						<div className="row" style={{ justifyContent: 'center' }}>
							{!loading && <a onClick={this.confirmShare} className="floorPlan imgHold">Send</a>}
							{loading && <CircularProgress />}
						</div>
					</div>
				</Grid>
			</Modal>
			<Modal
				open={isModalPrintOpen}
				onClose={() => { this.closePrintModal() }}
				className={'modal-classes-data'}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
			>
				<Grid className="rejectBoxCntnt modal-share">
					<span class="close" onClick={this.closePrintModal}>&times;</span>
					<div className="col-sm-12">
						{successMsg && <span>{successMsg}</span>}
						<div className="row">
							<div className="col-sm-4">
								<label>First name: </label>
							</div>
							<div className="col-sm-8">
								<input type="text" name="fullname" id="fullname" value={this.state.sendMail?.fullname ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<label>Message: </label>
							</div>
							<div className="col-sm-8">
								<textarea name="message" id="message" value={this.state.sendMail?.message ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>

						<div className="row" style={{ justifyContent: 'center' }}>
							{!loading && <a onClick={this.confirmPrint} className="floorPlan imgHold">Print</a>}
							{loading && <CircularProgress />}
						</div>
					</div>
				</Grid>
			</Modal>
			<ParkingPopupModel
				handleClosePopup={this.handleClosePopup}
				submitParkingSpaces={this.submitParkingSpaces}
				parkingSpaces={parkingSpaces}
				parkingOption={this.parkingOption}
				availableParkingList={availableParkingList}
				openParkingPopup={this.state.openParkingPopup}
				selectedItem={selectedItem}
				developmentDetail={developmentDetail}
				handleAddParking={this.handleAddParking} />

			<PopupFrom
				pdfLogo={pdfLogo}
				handleClosePopup={this.handleClosePopup}
				openPopupForm={this.state.openPopupForm}
				selectedItem={selectedItem}
				getPdfFormValues={this.state.getPdfFormValues}
				handleChangeForm={this.handleChangeForm}
				getCurrentDate={getCurrentDate}
				retParkingSPace={this.retParkingSPace}
				calcParkingPrice={this.calcParkingPrice}
				locationUnit={locationUnit}
				ternsAndCond={ternsAndCond}
				dataUsage={dataUsage}
				showPdfSuccessMsg={showPdfSuccessMsg}
				agentUserData={agentUserData}
				retCalculationInvest={this.retCalculationInvest}
				sendMail={this.sendMail}
			/>

			<Modal
				open={isModalMultiShareOpen}
				onClose={() => { this.closeShareModal() }}
				className={'modal-classes-data'}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
			>
				<Grid className="rejectBoxCntnt modal-share">
					<span class="close" onClick={this.closeShareModal}>&times;</span>
					<div className="col-sm-12">
						{successMsg && <span>{successMsg}</span>}
						<div className="row">
							<div className="col-sm-4">
								<label>Email: </label>
							</div>
							<div className="col-sm-8">
								<input type="email" name="email" id="email" value={this.state.sendMail?.email ?? ''} onChange={this.handleChangeShare} className="" />
							</div>

						</div>
						<div className="row">
							<div className="col-sm-4">
								<label>First name: </label>
							</div>
							<div className="col-sm-8">
								<input type="text" name="fullname" id="fullname" value={this.state.sendMail?.fullname ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<label>Message: </label>
							</div>
							<div className="col-sm-8">
								<textarea name="message" id="message" value={this.state.sendMail?.message ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-8">
								<div><label>Include link too: </label></div>
								{marketingBrochure && marketingBrochure.length > 0 && marketingBrochure.map(file => (
									<div className='optioninShare'>
										<a><input type='checkbox' name="development_brochure" id="development_brochure" className="" onChange={(e) => this.handleChangeShare(e, file)} /></a>
										<label>{file} </label>
									</div>
								))}
							</div>
						</div>
						<div className="row" style={{ justifyContent: 'center' }}>
							{!loading && <a onClick={this.sendMultiUnit} className="floorPlan imgHold">Send</a>}
							{loading && <CircularProgress />}
						</div>
					</div>
				</Grid>
			</Modal>
			<Modal
				open={isModalMultiPrintOpen}
				onClose={() => { this.closePrintModal() }}
				className={'modal-classes-data'}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
			>
				<Grid className="rejectBoxCntnt modal-share">
					<span class="close" onClick={this.closePrintModal}>&times;</span>
					<div className="col-sm-12">
						{successMsg && <span>{successMsg}</span>}
						<div className="row">
							<div className="col-sm-4">
								<label>First name: </label>
							</div>
							<div className="col-sm-8">
								<input type="text" name="fullname" id="fullname" value={this.state.sendMail?.fullname ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<label>Message: </label>
							</div>
							<div className="col-sm-8">
								<textarea name="message" id="message" value={this.state.sendMail?.message ?? ''} onChange={this.handleChangeShare} className="" />
							</div>
						</div>

						<div className="row" style={{ justifyContent: 'center' }}>
							{!loading && <a onClick={this.printMultiUnit} className="floorPlan imgHold">Print</a>}
							{loading && <CircularProgress />}
						</div>
					</div>
				</Grid>
			</Modal>
			<ReactTooltip />
		</div >
	}
}

const mapStateToProps = (state) => {
	const { user, token, userType, userId } = state.authUser;
	const { availablesearchUnits, developmentDetail } = state.development;
	return {
		user, token, userId, userType, availablesearchUnits, developmentDetail
	};
}
const mapDispatchToProps = dispatch => bindActionCreators({ getAvailableSearch, setHoldStatus }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Avaibility);