import React from 'react'
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { API_URL } from '../../url/apiUrl'
import Select from 'react-select';

export const ParkingPopupModel = ({ openParkingPopup, searchResult, handleClosePopup, selectedItem, developmentDetail, handleAddParking, availableParkingList, parkingOption, parkingSpaces, submitParkingSpaces }) => {
	return (
		<Modal
			open={openParkingPopup}
			onClose={() => { handleClosePopup() }}
			className={'modal-classes-data'}
		>
			<Grid className="rejectBoxCntnt1">
				<div className="SPCAvilPoup">
					{/* <a className="close">x</a> */}
					<div ><a className='parkingtilte'>Parking for  </a>{selectedItem?.title}</div>
					<div>
						<a>
							{searchResult === true ?
								<img src={selectedItem?.parkingPlan?.filename ? API_URL.BucketURl + selectedItem?.parkingPlan?.filename : ''} title="" alt="" width="800px" />
								: <img src={developmentDetail.details && developmentDetail.details?.parkingPlan?.filename ? API_URL.BucketURl + developmentDetail.details?.parkingPlan?.filename : ''} title="" alt="" width="800px" />
							}
						</a>
					</div>
					<div className="SELtSals">
						Select from the available and then click next to the sales price
					</div>
					<Grid className="SpcAvail_Container">
						<Grid container direction="row">
							<Grid item xs={12} md={6}>
								<div className="SpcaVailLeft">
									<div className="rld-single-select">
										<Select
											isMulti
											name="colors"
											onChange={handleAddParking}
											options={parkingOption(availableParkingList)}
											className="basic-multi-select"
											classNamePrefix="select"
											value={parkingSpaces}
										/>
									</div>

								</div>
							</Grid>
							<Grid item xs={12} md={6}>
							</Grid>
							<Grid item xs={12} md={12}>
								<div className="inline_view_btn sBMT">
									<div className="AdDMor">
										<div className="btn btn-yellow BtnAVILSPC" onClick={() => submitParkingSpaces()}>SUBMIT</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Grid>

		</Modal>
	)
}