import React from 'react';
import HeaderLogin from './global-components/login-header';
import Footer from './global-components/footer';
import ResetPassword from './section-components/resetPassword';

const resetPwdPage = (props) => {

    return <div>
        <HeaderLogin />
        <ResetPassword history={props.history} />
        <Footer
            footerCss={{ position: 'fixed', bottom: 0, width: '100%' }}
        />
    </div>
}

export default resetPwdPage

