import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Button, StepButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL, mainUrl } from '../../url/apiUrl';
import axios from 'axios';
import { signinUserSuccess, getDevlopmentFailure, getSearchFailure, getDevelopmentUnitFailure } from "../../actions"
import { emailFormatValidation } from "../section-components/validator"

const Sample = (props) => {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let [isModalShareOpen, setisModalShareOpen] = useState(false);
    let [loading, setloading] = useState(false);

    let [currentDate, setcurrentDate] = useState();
    let [Client, setClient] = useState();
    let [Development, setDevelopment] = useState();
    let [Unit, setUnit] = useState();
    let [Property_Market_Value, setProperty_Market_Value] = useState();
    let [Deposit_rate, setDeposit_rate] = useState();
    let [Deposit, setDeposit] = useState();
    let [Mortgage_Value, setMortgage_Value] = useState();
    let [Solicitor_Fee, setSolicitor_Fee] = useState("£ 0");
    let [Mortgage_Broker_Fee, setMortgage_Broker_Fee] = useState("£ 0");
    let [Disbursements, setDisbursements] = useState("£ 0");
    let [Stamp_Duty, setStamp_Duty] = useState("£ 0");
    let [Valuation_Fee, setValuation_Fee] = useState("£ 0");
    let [Furniture, setFurniture] = useState("£ 0");
    let [Total_Fees_2, setTotal_Fees_2] = useState();
    let [Total_Fees, setTotal_Fees] = useState();
    let [Mortgage_period_yrs, setMortgage_period_yrs] = useState();
    let [Mortgage_period_Month, setMortgage_period_Month] = useState();
    let [Mortgage_rate_approx, setMortgage_rate_approx] = useState();
    let [Rate, setRate] = useState();
    let [PV, setPV] = useState();
    let [Monthly_repayments, setMonthly_repayments] = useState();
    let [Assured_annual_rate, setAssured_annual_rate] = useState();
    let [Assured_annual_amount, setAssured_annual_amount] = useState();
    let [Monthly_assured_rent, setMonthly_assured_rent] = useState();
    let [Yr1_Anticipated_Service_Charge, setYr1_Anticipated_Service_Charge] = useState();
    let [Yr2_Anticipated_Service_Charge, setYr2_Anticipated_Service_Charge] = useState();
    let [Yr3_Anticipated_Service_Charge, setYr3_Anticipated_Service_Charge] = useState();
    let [Yr4_Anticipated_Service_Charge, setYr4_Anticipated_Service_Charge] = useState();
    let [Yr5_Anticipated_Service_Charge, setYr5_Anticipated_Service_Charge] = useState();
    let [Yr1_Ground_Rent, setYr1_Ground_Rent] = useState();
    let [Yr2_Ground_Rent, setYr2_Ground_Rent] = useState();
    let [Yr3_Ground_Rent, setYr3_Ground_Rent] = useState();
    let [Yr4_Ground_Rent, setYr4_Ground_Rent] = useState();
    let [Yr5_Ground_Rent, setYr5_Ground_Rent] = useState();
    let [Yr1_Annual_Gross_Assured_Rental, setYr1_Annual_Gross_Assured_Rental] = useState();
    let [Yr2_Annual_Gross_Assured_Rental, setYr2_Annual_Gross_Assured_Rental] = useState();
    let [Yr3_Annual_Gross_Assured_Rental, setYr3_Annual_Gross_Assured_Rental] = useState();
    let [Yr4_Annual_Gross_Assured_Rental, setYr4_Annual_Gross_Assured_Rental] = useState();
    let [Yr5_Annual_Gross_Assured_Rental, setYr5_Annual_Gross_Assured_Rental] = useState();
    let [Yr1_Annual_Mortgage_repayments, setYr1_Annual_Mortgage_repayments] = useState();
    let [Yr2_Annual_Mortgage_repayments, setYr2_Annual_Mortgage_repayments] = useState();
    let [Yr3_Annual_Mortgage_repayments, setYr3_Annual_Mortgage_repayments] = useState();
    let [Yr4_Annual_Mortgage_repayments, setYr4_Annual_Mortgage_repayments] = useState();
    let [Yr5_Annual_Mortgage_repayments, setYr5_Annual_Mortgage_repayments] = useState();
    let [Yr1_Management_Fee, setYr1_Management_Fee] = useState();
    let [Yr2_Management_Fee, setYr2_Management_Fee] = useState();
    let [Yr3_Management_Fee, setYr3_Management_Fee] = useState();
    let [Yr4_Management_Fee, setYr4_Management_Fee] = useState();
    let [Yr5_Management_Fee, setYr5_Management_Fee] = useState();
    let [Yr1_Cashflow, setYr1_Cashflow] = useState();
    let [Yr2_Cashflow, setYr2_Cashflow] = useState();
    let [Yr3_Cashflow, setYr3_Cashflow] = useState();
    let [Yr4_Cashflow, setYr4_Cashflow] = useState();
    let [Yr5_Cashflow, setYr5_Cashflow] = useState();
    let [Yr1_Projected_Property_Value, setYr1_Projected_Property_Value] = useState();
    let [Yr2_Projected_Property_Value, setYr2_Projected_Property_Value] = useState();
    let [Yr3_Projected_Property_Value, setYr3_Projected_Property_Value] = useState();
    let [Yr4_Projected_Property_Value, setYr4_Projected_Property_Value] = useState();
    let [Yr5_Projected_Property_Value, setYr5_Projected_Property_Value] = useState();
    let [Yr1_Annual_Capital_Growth_Percent, setYr1_Annual_Capital_Growth_Percent] = useState();
    let [Yr2_Annual_Capital_Growth_Percent, setYr2_Annual_Capital_Growth_Percent] = useState();
    let [Yr3_Annual_Capital_Growth_Percent, setYr3_Annual_Capital_Growth_Percent] = useState();
    let [Yr4_Annual_Capital_Growth_Percent, setYr4_Annual_Capital_Growth_Percent] = useState();
    let [Yr5_Annual_Capital_Growth_Percent, setYr5_Annual_Capital_Growth_Percent] = useState();
    let [Yr1_Annual_Capital_Growth, setYr1_Annual_Capital_Growth] = useState();
    let [Yr2_Annual_Capital_Growth, setYr2_Annual_Capital_Growth] = useState();
    let [Yr3_Annual_Capital_Growth, setYr3_Annual_Capital_Growth] = useState();
    let [Yr4_Annual_Capital_Growth, setYr4_Annual_Capital_Growth] = useState();
    let [Yr5_Annual_Capital_Growth, setYr5_Annual_Capital_Growth] = useState();
    let [Yr1_Annual_Return_on_Investment, setYr1_Annual_Return_on_Investment] = useState();
    let [Yr2_Annual_Return_on_Investment, setYr2_Annual_Return_on_Investment] = useState();
    let [Yr3_Annual_Return_on_Investment, setYr3_Annual_Return_on_Investment] = useState();
    let [Yr4_Annual_Return_on_Investment, setYr4_Annual_Return_on_Investment] = useState();
    let [Yr5_Annual_Return_on_Investment, setYr5_Annual_Return_on_Investment] = useState();
    let [Yr1_Cumulative_Cashflow, setYr1_Cumulative_Cashflow] = useState();
    let [Yr2_Cumulative_Cashflow, setYr2_Cumulative_Cashflow] = useState();
    let [Yr3_Cumulative_Cashflow, setYr3_Cumulative_Cashflow] = useState();
    let [Yr4_Cumulative_Cashflow, setYr4_Cumulative_Cashflow] = useState();
    let [Yr5_Cumulative_Cashflow, setYr5_Cumulative_Cashflow] = useState();
    let [Yr1_Cumulative_Capital_Growth, setYr1_Cumulative_Capital_Growth] = useState();
    let [Yr2_Cumulative_Capital_Growth, setYr2_Cumulative_Capital_Growth] = useState();
    let [Yr3_Cumulative_Capital_Growth, setYr3_Cumulative_Capital_Growth] = useState();
    let [Yr4_Cumulative_Capital_Growth, setYr4_Cumulative_Capital_Growth] = useState();
    let [Yr5_Cumulative_Capital_Growth, setYr5_Cumulative_Capital_Growth] = useState();
    let [Yr1_Cumulative_Return_on_Investment, setYr1_Cumulative_Return_on_Investment] = useState();
    let [Yr2_Cumulative_Return_on_Investment, setYr2_Cumulative_Return_on_Investment] = useState();
    let [Yr3_Cumulative_Return_on_Investment, setYr3_Cumulative_Return_on_Investment] = useState();
    let [Yr4_Cumulative_Return_on_Investment, setYr4_Cumulative_Return_on_Investment] = useState();
    let [Yr5_Cumulative_Return_on_Investment, setYr5_Cumulative_Return_on_Investment] = useState();
    let [Yr1_Cumulative_Return_on_Investment_Percent, setYr1_Cumulative_Return_on_Investment_Percent] = useState();
    let [Yr2_Cumulative_Return_on_Investment_Percent, setYr2_Cumulative_Return_on_Investment_Percent] = useState();
    let [Yr3_Cumulative_Return_on_Investment_Percent, setYr3_Cumulative_Return_on_Investment_Percent] = useState();
    let [Yr4_Cumulative_Return_on_Investment_Percent, setYr4_Cumulative_Return_on_Investment_Percent] = useState();
    let [Yr5_Cumulative_Return_on_Investment_Percent, setYr5_Cumulative_Return_on_Investment_Percent] = useState();
    let [currentUser, setCurrentUser] = useState();
    let [mobNo, setmobNo] = useState();
    let [email, setemail] = useState();
    let [values, setvalues] = useState({});
    let [status, setstatus] = useState(true);
    let [check, setcheck] = useState(false);
    let [removeFooter, setremoveFooter] = useState();
    let [updateState, setUpdateState] = useState(0)
    let [emailColor, setEmailColor] = useState()
    let [nameColor, setNameColor] = useState()
    let [emailError, setEmailError] = useState()
    let [nameError, setNameError] = useState()
    let [button, setButton] = useState(true)
    let [reload, setReload] = useState(true)




    const handleClient = (e) => {
        setClient(e)
    }
    const handleDevelopment = (e) => {
        setDevelopment(e)
    }
    const handleUnit = (e) => {
        setUnit(e)
    }
    const updateInput = () => {
        let updated_value = parseFloat(Deposit_rate.split("%")) / 100
        setDeposit_rate(updated_value)
    }
    const updateInput2 = () => {
        let updated_value = Deposit_rate * 100 + "%"
        setDeposit_rate(updated_value)
        setDeposit("£ " + Math.round(parseFloat(Property_Market_Value.split(" ")[1]) * Deposit_rate))
    }
    const handleDeposit_rate = (e) => {
        setDeposit_rate(e)
    }

    const handleYr1_Annual_Capital_Growth_Percent = (e) => {
        setYr1_Annual_Capital_Growth_Percent(e)
    }
    const updateYr1_Annual_Capital_Growth_Percent = () => {
        let updated_value = parseFloat(Yr1_Annual_Capital_Growth_Percent.split("%")) / 100
        setYr1_Annual_Capital_Growth_Percent(updated_value)
    }
    const updateYr1_Annual_Capital_Growth_Percent2 = () => {
        let updated_value = (Yr1_Annual_Capital_Growth_Percent * 100).toFixed(2) + "%"
        setYr1_Annual_Capital_Growth_Percent(updated_value)
        setUpdateState(updateState + 1)

    }

    const handleYr2_Annual_Capital_Growth_Percent = (e) => {
        setYr2_Annual_Capital_Growth_Percent(e)
    }
    const updateYr2_Annual_Capital_Growth_Percent = () => {
        let updated_value = parseFloat(Yr2_Annual_Capital_Growth_Percent.split("%")) / 100
        setYr2_Annual_Capital_Growth_Percent(updated_value)
    }
    const updateYr2_Annual_Capital_Growth_Percent2 = () => {
        let updated_value = (Yr2_Annual_Capital_Growth_Percent * 100).toFixed(2) + "%"
        setYr2_Annual_Capital_Growth_Percent(updated_value)
        setUpdateState(updateState + 1)

    }

    const handleYr3_Annual_Capital_Growth_Percent = (e) => {
        setYr3_Annual_Capital_Growth_Percent(e)
    }
    const updateYr3_Annual_Capital_Growth_Percent = () => {
        let updated_value = parseFloat(Yr3_Annual_Capital_Growth_Percent.split("%")) / 100
        setYr3_Annual_Capital_Growth_Percent(updated_value)
    }
    const updateYr3_Annual_Capital_Growth_Percent2 = () => {
        let updated_value = (Yr3_Annual_Capital_Growth_Percent * 100).toFixed(2) + "%"
        setYr3_Annual_Capital_Growth_Percent(updated_value)
        setUpdateState(updateState + 1)

    }


    const handleYr4_Annual_Capital_Growth_Percent = (e) => {
        setYr4_Annual_Capital_Growth_Percent(e)
    }
    const updateYr4_Annual_Capital_Growth_Percent = () => {
        let updated_value = parseFloat(Yr4_Annual_Capital_Growth_Percent.split("%")) / 100
        setYr4_Annual_Capital_Growth_Percent(updated_value)
    }
    const updateYr4_Annual_Capital_Growth_Percent2 = () => {
        let updated_value = (Yr4_Annual_Capital_Growth_Percent * 100).toFixed(2) + "%"
        setYr4_Annual_Capital_Growth_Percent(updated_value)
        setUpdateState(updateState + 1)

    }

    const handleYr5_Annual_Capital_Growth_Percent = (e) => {
        setYr5_Annual_Capital_Growth_Percent(e)
    }
    const updateYr5_Annual_Capital_Growth_Percent = () => {
        let updated_value = parseFloat(Yr5_Annual_Capital_Growth_Percent.split("%")) / 100
        setYr5_Annual_Capital_Growth_Percent(updated_value)
    }
    const updateYr5_Annual_Capital_Growth_Percent2 = () => {
        let updated_value = (Yr5_Annual_Capital_Growth_Percent * 100).toFixed(2) + "%"
        setYr5_Annual_Capital_Growth_Percent(updated_value)
        setUpdateState(updateState + 1)

    }
    // ................................. PURCHASING DETAILS FUNCTIONALITY START ...............................
    const handleSolicitor_Fee = (e) => {
        setSolicitor_Fee(e)
    }
    const updateSolicitor_Fee = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setSolicitor_Fee(value)
    }
    const updateSolicitor_Fee2 = (e) => {
        setSolicitor_Fee("£ " + parseInt(e ? e : 0).toLocaleString())
        calculate_Total_Fees()
    }

    const handleMortgage_Broker_Fee = (e) => {
        setMortgage_Broker_Fee(e)

    }
    const updateMortgage_Broker_Fee = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setMortgage_Broker_Fee(value)
    }
    const updateMortgage_Broker_Fee2 = (e) => {
        setMortgage_Broker_Fee("£ " + parseInt(e ? e : 0).toLocaleString())
        calculate_Total_Fees()

    }

    const handleDisbursements = (e) => {
        setDisbursements(e)
    }
    const updateDisbursements = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setDisbursements(value)
    }
    const updateDisbursements2 = (e) => {
        setDisbursements("£ " + parseInt(e ? e : 0).toLocaleString())
        calculate_Total_Fees()

    }

    const handleStamp_Duty = (e) => {
        setStamp_Duty(e)
    }
    const updateStamp_Duty = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setStamp_Duty(value)
    }
    const updateStamp_Duty2 = (e) => {
        setStamp_Duty("£ " + parseInt(e ? e : 0).toLocaleString())
        calculate_Total_Fees()

    }

    const handleValuation_Fee = (e) => {
        setValuation_Fee(e)

    }
    const updateValuation_Fee = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setValuation_Fee(value)
    }
    const updateValuation_Fee2 = (e) => {
        setValuation_Fee("£ " + parseInt(e ? e : 0).toLocaleString())
        calculate_Total_Fees()

    }

    const handleFurniture = (e) => {
        setFurniture(e)

    }
    const updateFurniture = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setFurniture(value)
    }
    const updateFurniture2 = (e) => {
        setFurniture("£ " + parseInt(e ? e : 0).toLocaleString())
        calculate_Total_Fees()

    }

    // ................................. PURCHASING DETAILS FUNCTIONALITY STOP .................................
    // for calculating Total purchasing costs......... START...........
    const calculate_Total_Fees = () => {
        let a = Solicitor_Fee.split(" ")[1] ? parseInt(Solicitor_Fee.split(" ")[1].replace(/\,/g, '')) : parseInt(Solicitor_Fee.replace(/\,/g, ''))
        let b = Mortgage_Broker_Fee.split(" ")[1] ? parseInt(Mortgage_Broker_Fee.split(" ")[1].replace(/\,/g, '')) : parseInt(Mortgage_Broker_Fee.replace(/\,/g, ''))
        let c = Disbursements.split(" ")[1] ? parseInt(Disbursements.split(" ")[1].replace(/\,/g, '')) : parseInt(Disbursements.replace(/\,/g, ''))
        let d = Stamp_Duty.split(" ")[1] ? parseInt(Stamp_Duty.split(" ")[1].replace(/\,/g, '')) : parseInt(Stamp_Duty.replace(/\,/g, ''))
        let e = Valuation_Fee.split(" ")[1] ? parseInt(Valuation_Fee.split(" ")[1].replace(/\,/g, '')) : parseInt(Valuation_Fee.replace(/\,/g, ''))
        let f = Furniture.split(" ")[1] ? parseInt(Furniture.split(" ")[1].replace(/\,/g, '')) : parseInt(Furniture.replace(/\,/g, ''))
        let total = a + b + c + d + e + f;

        setTotal_Fees("£ " + parseInt(total).toLocaleString())
        calculate_Total_Fees_2()
    }
    // for calculating Total purchasing costs......... STOP...........

    // for calculating cash required......... START...........
    const calculate_Total_Fees_2 = () => {
        let a = Deposit ? parseInt(Deposit.split(" ")[1].replace(/\,/g, '')) : ""
        let b = Total_Fees ? parseInt(Total_Fees.split(" ")[1].replace(/\,/g, '')) : ""
        setTotal_Fees_2("£ " + (a + b).toLocaleString())
    }
    // for calculating cash required......... STOP...........

    // for calculating Mortgage_Value .............START.....................
    const calculate_Mortgage_Value = () => {
        let a = Deposit ? parseInt(Deposit.split(" ")[1].replace(/\,/g, '')) : ""
        let value = parseInt(Property_Market_Value) - a
        let final_value = "£ " + value.toLocaleString()
        setMortgage_Value(final_value)
        setPV(final_value)
        calculateRate(final_value)
    }
    // for calculating Mortgage_Value .............STOP.....................

    // ....................... Functionality for Interest only mortgage .....START..... ...........................
    const handleMortgage_period_yrs = (e) => {
        setMortgage_period_yrs(e)
    }
    const updateMortgage_period_yrs2 = (e) => {
        setMortgage_period_yrs(e ? e : 0)
        handleMortgage_period_Month();
    }
    const handleMortgage_period_Month = () => {
        let mnths = parseInt(Mortgage_period_yrs) * 12
        setMortgage_period_Month(mnths)
    }

    const updateMortgage_rate_approx = () => {
        let updated_value = parseFloat(Mortgage_rate_approx.split("%")) / 100
        setMortgage_rate_approx(updated_value)
    }
    const updateMortgage_rate_approx2 = () => {
        let updated_value = ((Mortgage_rate_approx) * 100).toFixed(2) + "%"
        setMortgage_rate_approx(updated_value)
        setRate("£ " + parseInt(Mortgage_Value) * Mortgage_rate_approx)
        setMonthly_repayments("£ " + ((parseFloat(parseInt(Mortgage_Value) * Mortgage_rate_approx / 12)).toFixed(2)).toLocaleString())
        setYr1_Annual_Mortgage_repayments("£ " + parseInt(Mortgage_Value) * Mortgage_rate_approx)
        setYr2_Annual_Mortgage_repayments("£ " + parseInt(Mortgage_Value) * Mortgage_rate_approx)
        setYr3_Annual_Mortgage_repayments("£ " + parseInt(Mortgage_Value) * Mortgage_rate_approx)
        setYr4_Annual_Mortgage_repayments("£ " + parseInt(Mortgage_Value) * Mortgage_rate_approx)
        setYr5_Annual_Mortgage_repayments("£ " + parseInt(Mortgage_Value) * Mortgage_rate_approx)

    }

    const handleMortgage_rate_approx = (e) => {
        setMortgage_rate_approx(e)
    }
    const calculateRate = (Mortgage_Value) => {
        let a = Mortgage_Value && Mortgage_Value.split(" ")[1] ? parseInt(Mortgage_Value.split(" ")[1].replace(/\,/g, '')) : parseInt(Mortgage_Value.replace(/\,/g, ''))
        let rate = Mortgage_rate_approx ? parseFloat(Mortgage_rate_approx.split("%")) : parseFloat(Mortgage_rate_approx)
        let final = parseFloat(a) * rate / 100
        setRate("£ " + parseInt(final).toLocaleString())
        setMonthly_repayments("£ " + (parseFloat(final / 12).toFixed(2)).toLocaleString())
        setYr1_Annual_Mortgage_repayments("£ " + parseInt(final).toLocaleString())
        setYr2_Annual_Mortgage_repayments("£ " + parseInt(final).toLocaleString())
        setYr3_Annual_Mortgage_repayments("£ " + parseInt(final).toLocaleString())
        setYr4_Annual_Mortgage_repayments("£ " + parseInt(final).toLocaleString())
        setYr5_Annual_Mortgage_repayments("£ " + parseInt(final).toLocaleString())

    }
    // ....................... Functionality for Interest only mortgage .....STOP..... ...........................

    // const updateAssured_annual_rate = () => {
    //     let updated_value = parseFloat(Assured_annual_rate.split("%")) / 100
    //     setAssured_annual_rate(updated_value)
    // }
    // const updateAssured_annual_rate2 = (e) => {
    //     let updated_value = (Assured_annual_rate * 100).toLocaleString() + "%"

    //     setAssured_annual_rate(updated_value)
    //     let value = parseInt(parseInt(Property_Market_Value.split(" ")[1].replace(/\,/g, '')) * Assured_annual_rate)
    //     setAssured_annual_amount("£ " + (value).toLocaleString())
    //     setMonthly_assured_rent("£ " + Math.floor((value) / 12).toLocaleString())
    //     setYr1_Annual_Gross_Assured_Rental("£ " + (value).toLocaleString())
    //     setYr2_Annual_Gross_Assured_Rental("£ " + (value).toLocaleString())
    //     setYr3_Annual_Gross_Assured_Rental("£ " + (value).toLocaleString())
    //     setYr4_Annual_Gross_Assured_Rental("£ " + (value).toLocaleString())
    //     setYr5_Annual_Gross_Assured_Rental("£ " + (value).toLocaleString())
    //     setYr1_Management_Fee("£ " + parseInt(value * 0.08).toLocaleString())
    //     setYr2_Management_Fee("£ " + parseInt(value * 0.08).toLocaleString())
    //     setYr3_Management_Fee("£ " + parseInt(value * 0.08).toLocaleString())
    //     setYr4_Management_Fee("£ " + parseInt(value * 0.08).toLocaleString())
    //     setYr5_Management_Fee("£ " + parseInt(value * 0.08).toLocaleString())



    // }
    // const handleAssured_annual_rate = (e) => {
    //     setAssured_annual_rate(e)
    // }

    const calculateAssured_annual_rate = () => {
        let a = Property_Market_Value && Property_Market_Value.split(" ")[1] ? parseInt(Property_Market_Value.split(" ")[1]) : parseInt(Property_Market_Value)
        let rate = Assured_annual_rate ? parseFloat(Assured_annual_rate.split("%")) : parseFloat(Assured_annual_rate)
        let final = parseFloat(a) * rate / 100
        setAssured_annual_amount("£ " + parseInt(final).toLocaleString())
        setMonthly_assured_rent("£ " + Math.round(parseInt(final) / 12).toLocaleString())
        setYr1_Annual_Gross_Assured_Rental("£ " + parseInt(final).toLocaleString())
        setYr2_Annual_Gross_Assured_Rental("£ " + parseInt(final).toLocaleString())
        setYr3_Annual_Gross_Assured_Rental("£ " + parseInt(final).toLocaleString())
        setYr4_Annual_Gross_Assured_Rental("£ " + parseInt(final).toLocaleString())
        setYr5_Annual_Gross_Assured_Rental("£ " + parseInt(final).toLocaleString())

        setYr1_Management_Fee("£ " + parseInt(final * 0.08).toLocaleString())
        setYr2_Management_Fee("£ " + parseInt(final * 0.08).toLocaleString())
        setYr3_Management_Fee("£ " + parseInt(final * 0.08).toLocaleString())
        setYr4_Management_Fee("£ " + parseInt(final * 0.08).toLocaleString())
        setYr5_Management_Fee("£ " + parseInt(final * 0.08).toLocaleString())


    }

    // .............................................. Rental Income START .................................................

    // .......................... Anticipated service charge Functionality START ...........................
    const handleYr1_Anticipated_Service_Charge = (e) => {
        setYr1_Anticipated_Service_Charge(e)

    }
    const updateYr1_Anticipated_Service_Charge = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr1_Anticipated_Service_Charge(value)
    }
    const updateYr1_Anticipated_Service_Charge2 = (e) => {
        setUpdateState(updateState + 1)
        setYr1_Anticipated_Service_Charge("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr2_Anticipated_Service_Charge = (e) => {
        setYr2_Anticipated_Service_Charge(e)

    }
    const updateYr2_Anticipated_Service_Charge = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr2_Anticipated_Service_Charge(value)
    }
    const updateYr2_Anticipated_Service_Charge2 = (e) => {
        setUpdateState(updateState + 1)
        setYr2_Anticipated_Service_Charge("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr3_Anticipated_Service_Charge = (e) => {
        setYr3_Anticipated_Service_Charge(e)

    }
    const updateYr3_Anticipated_Service_Charge = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr3_Anticipated_Service_Charge(value)
    }
    const updateYr3_Anticipated_Service_Charge2 = (e) => {
        setUpdateState(updateState + 1)
        setYr3_Anticipated_Service_Charge("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr4_Anticipated_Service_Charge = (e) => {
        setYr4_Anticipated_Service_Charge(e)

    }
    const updateYr4_Anticipated_Service_Charge = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr4_Anticipated_Service_Charge(value)
    }
    const updateYr4_Anticipated_Service_Charge2 = (e) => {
        setUpdateState(updateState + 1)
        setYr4_Anticipated_Service_Charge("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr5_Anticipated_Service_Charge = (e) => {
        setYr5_Anticipated_Service_Charge(e)

    }
    const updateYr5_Anticipated_Service_Charge = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr5_Anticipated_Service_Charge(value)
    }
    const updateYr5_Anticipated_Service_Charge2 = (e) => {
        setUpdateState(updateState + 1)
        setYr5_Anticipated_Service_Charge("£ " + parseInt(e ? e : 0).toLocaleString())

    }
    // .......................... Anticipated service charge Functionality STOP ...........................

    // .......................... Ground rent START ...........................

    const handleYr1_Ground_Rent = (e) => {
        setYr1_Ground_Rent(e)

    }
    const updateYr1_Ground_Rent = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr1_Ground_Rent(value)
    }
    const updateYr1_Ground_Rent2 = (e) => {
        setUpdateState(updateState + 1)
        setYr1_Ground_Rent("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr2_Ground_Rent = (e) => {
        setYr2_Ground_Rent(e)

    }
    const updateYr2_Ground_Rent = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr2_Ground_Rent(value)
    }
    const updateYr2_Ground_Rent2 = (e) => {
        setUpdateState(updateState + 1)

        setYr2_Ground_Rent("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr3_Ground_Rent = (e) => {
        setYr3_Ground_Rent(e)

    }
    const updateYr3_Ground_Rent = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr3_Ground_Rent(value)
    }
    const updateYr3_Ground_Rent2 = (e) => {
        setUpdateState(updateState + 1)

        setYr3_Ground_Rent("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr4_Ground_Rent = (e) => {
        setYr4_Ground_Rent(e)

    }
    const updateYr4_Ground_Rent = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr4_Ground_Rent(value)
    }
    const updateYr4_Ground_Rent2 = (e) => {
        setUpdateState(updateState + 1)

        setYr4_Ground_Rent("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    const handleYr5_Ground_Rent = (e) => {
        setYr5_Ground_Rent(e)

    }
    const updateYr5_Ground_Rent = (e) => {
        let data = e ? e : ""
        let value = data ? data.split(" ")[1].replace(/\,/g, '') : data
        setYr5_Ground_Rent(value)
    }
    const updateYr5_Ground_Rent2 = (e) => {
        setUpdateState(updateState + 1)

        setYr5_Ground_Rent("£ " + parseInt(e ? e : 0).toLocaleString())

    }

    // .......................... Ground rent STOP ...........................

    // ..................... calculation of rental income START............................
    const caluclateYr1_Cashflow = () => {
        // let a = Property_Market_Value && Property_Market_Value.split(" ")[1] ? parseInt(Property_Market_Value.split(" ")[1]) : parseInt(Property_Market_Value)

        let a = Yr1_Annual_Gross_Assured_Rental && Yr1_Annual_Gross_Assured_Rental.split(" ")[1] ? Yr1_Annual_Gross_Assured_Rental.split(" ")[1].replace(/\,/g, '') : 0
        let b = Yr1_Annual_Mortgage_repayments && Yr1_Annual_Mortgage_repayments.split(" ")[1] ? Yr1_Annual_Mortgage_repayments.split(" ")[1].replace(/\,/g, '') : 0
        let c = Yr1_Anticipated_Service_Charge && Yr1_Anticipated_Service_Charge.split(" ")[1] ? Yr1_Anticipated_Service_Charge.split(" ")[1].replace(/\,/g, '') : 0
        let d = Yr1_Ground_Rent && Yr1_Ground_Rent.split(" ")[1] ? Yr1_Ground_Rent.split(" ")[1].replace(/\,/g, '') : 0
        let e = Yr1_Management_Fee && Yr1_Management_Fee.split(" ")[1] ? Yr1_Management_Fee.split(" ")[1].replace(/\,/g, '') : 0

        let total = parseInt(a) - parseInt(b) - parseInt(c) - parseInt(d) - parseInt(e)

        setYr1_Cashflow("£ " + total.toLocaleString())

    }

    const caluclateYr2_Cashflow = () => {

        let a = Yr2_Annual_Gross_Assured_Rental && Yr2_Annual_Gross_Assured_Rental.split(" ")[1] ? Yr2_Annual_Gross_Assured_Rental.split(" ")[1].replace(/\,/g, '') : 0
        let b = Yr2_Annual_Mortgage_repayments && Yr2_Annual_Mortgage_repayments.split(" ")[1] ? Yr2_Annual_Mortgage_repayments.split(" ")[1].replace(/\,/g, '') : 0
        let c = Yr2_Anticipated_Service_Charge && Yr2_Anticipated_Service_Charge.split(" ")[1] ? Yr2_Anticipated_Service_Charge.split(" ")[1].replace(/\,/g, '') : 0
        let d = Yr2_Ground_Rent && Yr2_Ground_Rent.split(" ")[1] ? Yr2_Ground_Rent.split(" ")[1].replace(/\,/g, '') : 0
        let e = Yr2_Management_Fee && Yr2_Management_Fee.split(" ")[1] ? Yr2_Management_Fee.split(" ")[1].replace(/\,/g, '') : 0

        let total = parseInt(a) - parseInt(b) - parseInt(c) - parseInt(d) - parseInt(e)

        setYr2_Cashflow("£ " + total.toLocaleString())

    }

    const caluclateYr3_Cashflow = () => {

        let a = Yr3_Annual_Gross_Assured_Rental && Yr3_Annual_Gross_Assured_Rental.split(" ")[1] ? Yr3_Annual_Gross_Assured_Rental.split(" ")[1].replace(/\,/g, '') : 0
        let b = Yr3_Annual_Mortgage_repayments && Yr3_Annual_Mortgage_repayments.split(" ")[1] ? Yr3_Annual_Mortgage_repayments.split(" ")[1].replace(/\,/g, '') : 0
        let c = Yr3_Anticipated_Service_Charge && Yr3_Anticipated_Service_Charge.split(" ")[1] ? Yr3_Anticipated_Service_Charge.split(" ")[1].replace(/\,/g, '') : 0
        let d = Yr3_Ground_Rent && Yr3_Ground_Rent.split(" ")[1] ? Yr3_Ground_Rent.split(" ")[1].replace(/\,/g, '') : 0
        let e = Yr3_Management_Fee && Yr3_Management_Fee.split(" ")[1] ? Yr3_Management_Fee.split(" ")[1].replace(/\,/g, '') : 0

        let total = parseInt(a) - parseInt(b) - parseInt(c) - parseInt(d) - parseInt(e)

        setYr3_Cashflow("£ " + total.toLocaleString())

    }

    const caluclateYr4_Cashflow = () => {
        // let a = Property_Market_Value && Property_Market_Value.split(" ")[1] ? parseInt(Property_Market_Value.split(" ")[1]) : parseInt(Property_Market_Value)

        let a = Yr4_Annual_Gross_Assured_Rental && Yr4_Annual_Gross_Assured_Rental.split(" ")[1] ? Yr4_Annual_Gross_Assured_Rental.split(" ")[1].replace(/\,/g, '') : 0
        let b = Yr4_Annual_Mortgage_repayments && Yr4_Annual_Mortgage_repayments.split(" ")[1] ? Yr4_Annual_Mortgage_repayments.split(" ")[1].replace(/\,/g, '') : 0
        let c = Yr4_Anticipated_Service_Charge && Yr4_Anticipated_Service_Charge.split(" ")[1] ? Yr4_Anticipated_Service_Charge.split(" ")[1].replace(/\,/g, '') : 0
        let d = Yr4_Ground_Rent && Yr4_Ground_Rent.split(" ")[1] ? Yr4_Ground_Rent.split(" ")[1].replace(/\,/g, '') : 0
        let e = Yr4_Management_Fee && Yr4_Management_Fee.split(" ")[1] ? Yr4_Management_Fee.split(" ")[1].replace(/\,/g, '') : 0

        let total = parseInt(a) - parseInt(b) - parseInt(c) - parseInt(d) - parseInt(e)

        setYr4_Cashflow("£ " + total.toLocaleString())

    }

    const caluclateYr5_Cashflow = () => {

        let a = Yr5_Annual_Gross_Assured_Rental && Yr5_Annual_Gross_Assured_Rental.split(" ")[1] ? Yr5_Annual_Gross_Assured_Rental.split(" ")[1].replace(/\,/g, '') : 0
        let b = Yr5_Annual_Mortgage_repayments && Yr5_Annual_Mortgage_repayments.split(" ")[1] ? Yr5_Annual_Mortgage_repayments.split(" ")[1].replace(/\,/g, '') : 0
        let c = Yr5_Anticipated_Service_Charge && Yr5_Anticipated_Service_Charge.split(" ")[1] ? Yr5_Anticipated_Service_Charge.split(" ")[1].replace(/\,/g, '') : 0
        let d = Yr5_Ground_Rent && Yr5_Ground_Rent.split(" ")[1] ? Yr5_Ground_Rent.split(" ")[1].replace(/\,/g, '') : 0
        let e = Yr5_Management_Fee && Yr5_Management_Fee.split(" ")[1] ? Yr5_Management_Fee.split(" ")[1].replace(/\,/g, '') : 0

        let total = parseInt(a) - parseInt(b) - parseInt(c) - parseInt(d) - parseInt(e)

        setYr5_Cashflow("£ " + total.toLocaleString())

    }

    // ..................... calculation of rental income STOP............................


    // ................................................... Rental Income STOP ...............................................


    // ................................. Capital growth...START.....    ........................................
    const calculateAll_Projected_Property_Value = () => {
        let percentYr1 = Yr1_Annual_Capital_Growth_Percent ? parseFloat(Yr1_Annual_Capital_Growth_Percent.split('%')[0]) : 0
        let value = parseInt(Property_Market_Value)
        let capGrowthYr1 = value * percentYr1 / 100
        let yr1value = (capGrowthYr1) + value
        let cashFlowYr1 = Yr1_Cashflow ? parseInt(Yr1_Cashflow.split(" ")[1].replace(/\,/g, '')) : 0
        let ROIyr1 = Math.round(capGrowthYr1) + Math.round(cashFlowYr1)
        setYr1_Projected_Property_Value("£ " + Math.round(yr1value).toLocaleString())
        setYr1_Annual_Capital_Growth("£ " + Math.round(capGrowthYr1).toLocaleString())
        setYr1_Annual_Return_on_Investment("£ " + ROIyr1.toLocaleString())
        setYr1_Cumulative_Cashflow("£ " + Math.round(cashFlowYr1 * 1).toLocaleString())
        setYr1_Cumulative_Capital_Growth("£ " + Math.round(capGrowthYr1 * 1).toLocaleString())
        setYr1_Cumulative_Return_on_Investment("£ " + (ROIyr1 * 1).toLocaleString())


        let percentYr2 = Yr2_Annual_Capital_Growth_Percent ? parseFloat(Yr2_Annual_Capital_Growth_Percent.split('%')[0]) : 0
        let capGrowthYr2 = yr1value * percentYr2 / 100
        let yr2value = (capGrowthYr2) + yr1value
        let cashFlowYr2 = Yr2_Cashflow ? parseInt(Yr2_Cashflow.split(" ")[1].replace(/\,/g, '')) : 0
        let ROIyr2 = Math.round(capGrowthYr2) + Math.round(cashFlowYr2)
        setYr2_Projected_Property_Value("£ " + Math.round(yr2value).toLocaleString())
        setYr2_Annual_Capital_Growth("£ " + Math.round(capGrowthYr2).toLocaleString())
        setYr2_Annual_Return_on_Investment("£ " + ROIyr2.toLocaleString())
        let cumCashYr2 = cashFlowYr2 + cashFlowYr1 * 1
        let cumCpGrowthYr2 = capGrowthYr2 + capGrowthYr1 * 1
        setYr2_Cumulative_Cashflow("£ " + Math.round(cumCashYr2).toLocaleString())
        setYr2_Cumulative_Capital_Growth("£ " + Math.round(cumCpGrowthYr2).toLocaleString())
        setYr2_Cumulative_Return_on_Investment("£ " + Math.round(cumCashYr2 + cumCpGrowthYr2).toLocaleString())


        let percentYr3 = Yr3_Annual_Capital_Growth_Percent ? parseFloat(Yr3_Annual_Capital_Growth_Percent.split('%')[0]) : 0
        let capGrowthYr3 = yr2value * percentYr3 / 100
        let yr3value = (capGrowthYr3) + yr2value
        let cashFlowYr3 = Yr3_Cashflow ? parseInt(Yr3_Cashflow.split(" ")[1].replace(/\,/g, '')) : 0
        let ROIyr3 = Math.round(capGrowthYr3) + Math.round(cashFlowYr3)
        setYr3_Projected_Property_Value("£ " + Math.round(yr3value).toLocaleString())
        setYr3_Annual_Capital_Growth("£ " + Math.round(capGrowthYr3).toLocaleString())
        setYr3_Annual_Return_on_Investment("£ " + ROIyr3.toLocaleString())
        let cumCashYr3 = cashFlowYr3 + cashFlowYr2 + cashFlowYr1 * 1
        let cumCpGrowthYr3 = capGrowthYr3 + capGrowthYr2 + capGrowthYr1 * 1
        setYr3_Cumulative_Cashflow("£ " + Math.round(cumCashYr3).toLocaleString())
        setYr3_Cumulative_Capital_Growth("£ " + Math.round(cumCpGrowthYr3).toLocaleString())
        setYr3_Cumulative_Return_on_Investment("£ " + Math.round(cumCashYr3 + cumCpGrowthYr3).toLocaleString())



        let percentYr4 = Yr4_Annual_Capital_Growth_Percent ? parseFloat(Yr4_Annual_Capital_Growth_Percent.split('%')[0]) : 0
        let capGrowthYr4 = yr3value * percentYr4 / 100
        let yr4value = (capGrowthYr4) + yr3value
        let cashFlowYr4 = Yr4_Cashflow ? parseInt(Yr4_Cashflow.split(" ")[1].replace(/\,/g, '')) : 0
        let ROIyr4 = Math.round(capGrowthYr4) + Math.round(cashFlowYr4)
        setYr4_Projected_Property_Value("£ " + Math.round(yr4value).toLocaleString())
        setYr4_Annual_Capital_Growth("£ " + Math.round(capGrowthYr4).toLocaleString())
        setYr4_Annual_Return_on_Investment("£ " + ROIyr4.toLocaleString())
        let cumCashYr4 = cashFlowYr4 + cashFlowYr3 + cashFlowYr2 + cashFlowYr1 * 1
        let cumCpGrowthYr4 = capGrowthYr4 + capGrowthYr3 + capGrowthYr2 + capGrowthYr1 * 1
        setYr4_Cumulative_Cashflow("£ " + Math.round(cumCashYr4).toLocaleString())
        setYr4_Cumulative_Capital_Growth("£ " + Math.round(cumCpGrowthYr4).toLocaleString())
        setYr4_Cumulative_Return_on_Investment("£ " + Math.round(cumCashYr4 + cumCpGrowthYr4).toLocaleString())


        let percentYr5 = Yr5_Annual_Capital_Growth_Percent ? parseFloat(Yr5_Annual_Capital_Growth_Percent.split('%')[0]) : 0
        let capGrowthYr5 = yr4value * percentYr5 / 100
        let yr5value = (capGrowthYr5) + yr4value
        let cashFlowYr5 = Yr5_Cashflow ? parseInt(Yr5_Cashflow.split(" ")[1].replace(/\,/g, '')) : 0
        let ROIyr5 = Math.round(capGrowthYr5) + Math.round(cashFlowYr5)
        setYr5_Projected_Property_Value("£ " + Math.round(yr5value).toLocaleString())
        setYr5_Annual_Capital_Growth("£ " + Math.round(capGrowthYr5).toLocaleString())
        setYr5_Annual_Return_on_Investment("£ " + ROIyr5.toLocaleString())
        let cumCashYr5 = cashFlowYr5 + cashFlowYr4 + cashFlowYr3 + cashFlowYr2 + cashFlowYr1 * 1
        let cumCpGrowthYr5 = capGrowthYr5 + capGrowthYr4 + capGrowthYr3 + capGrowthYr2 + capGrowthYr1 * 1
        setYr5_Cumulative_Cashflow("£ " + Math.round(cumCashYr5).toLocaleString())
        setYr5_Cumulative_Capital_Growth("£ " + Math.round(cumCpGrowthYr5).toLocaleString())
        setYr5_Cumulative_Return_on_Investment("£ " + Math.round(cumCashYr5 + cumCpGrowthYr5).toLocaleString())

        // .................................Return on investment (%) START....................
        let a1 = Math.round(ROIyr1 * 1)
        let a2 = Math.round(cumCashYr2 + cumCpGrowthYr2)
        let a3 = Math.round(cumCashYr3 + cumCpGrowthYr3)
        let a4 = Math.round(cumCashYr4 + cumCpGrowthYr4)
        let a5 = Math.round(cumCashYr5 + cumCpGrowthYr5)

        let b = Total_Fees_2 ? parseInt(Total_Fees_2.split(" ")[1].replace(/\,/g, '')) : 0

        if (b == 0) {
            setYr1_Cumulative_Return_on_Investment_Percent("")
            setYr2_Cumulative_Return_on_Investment_Percent("")
            setYr3_Cumulative_Return_on_Investment_Percent("")
            setYr4_Cumulative_Return_on_Investment_Percent("")
            setYr5_Cumulative_Return_on_Investment_Percent("")

        }
        else {
            setYr1_Cumulative_Return_on_Investment_Percent((((a1 / b) * 100).toFixed(2)) + "%")
            setYr2_Cumulative_Return_on_Investment_Percent((((a2 / b) * 100).toFixed(2)) + "%")
            setYr3_Cumulative_Return_on_Investment_Percent((((a3 / b) * 100).toFixed(2)) + "%")
            setYr4_Cumulative_Return_on_Investment_Percent((((a4 / b) * 100).toFixed(2)) + "%")
            setYr5_Cumulative_Return_on_Investment_Percent((((a5 / b) * 100).toFixed(2)) + "%")
        }
        // .................................Return on investment (%) STOP....................

    }

    // ................................. Capital growth...STOP.....    ........................................

    const handleShare = () => {
        setisModalShareOpen(true);
        setcheck(true)
        let state = values;
        state["check"] = true;
        state["removeFooter"] = true;
        setvalues(state)
    };

    const closeShareModal = () => {
        setisModalShareOpen(false);
        setcheck(false)
        let state = values;
        state["check"] = false;
        state["removeFooter"] = false;

        setvalues(state)
    }

    const handleShareChange = (e) => {
        let { name, value } = e.target
        let userValue = values
        userValue[name] = value
        setvalues(userValue)
        if (name == "email") {
            if (emailFormatValidation(value) == true) {
                setEmailColor("")
                setEmailError("")
                setButton(false)
            }
            else {
                if (value.length < 1) {
                    setEmailColor("InValidation_Input")
                    setEmailError("Email is required")

                }
                else if (value.length > 0) {
                    setEmailColor("InValidation_Input")
                    setEmailError("Invalid email format")
                }
                setButton(true)
            }
        }
    }

    const confirmShare = async () => {
        setloading(true)
        let urlData = UrlGetter()
        let data = values
        data['curr_date'] = moment().format("DD/MM/YYYY");
        data["urlData"] = urlData
        const token = localStorage.getItem('tokenweb');
        const headers = { 'token': `Bearer ${token}` }
        const response = await axios.post(`${API_URL.shareInvestment}`, data, { headers });
        if (response?.data?.statusCode == 200) {

        } else {

        }
        setTimeout(() => {
            setloading(false)
            setisModalShareOpen(false)
            setcheck(false)
        }, 1500)
    }

    const valueupade = (e) => {
        let state = values;
        state[e.target.name] = e.target.value;
        setvalues(state)
    }

    // const printDocument = () => {
    //     const input = document.getElementById('divToPrint');
    //     html2canvas(input)
    //         .then((canvas) => {
    //             let imgWidth = 50;
    //             let imgHeight = canvas.height * imgWidth / canvas.width;
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new jsPDF();
    //             pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
    //             // pdf.output('dataurlnewwindow');
    //             pdf.save("download.pdf");
    //             setloading(true);
    //         });
    // }
    //    {name: , email : url :)}
    const UrlGetter = () => {
        var sendUrl = mainUrl + "/#/sample/" + btoa(JSON.stringify(values))
        return sendUrl;
    }

    useEffect(() => {
        let data = props && props.history && props.history.match && props.history.match.params && props.history.match.params.id ? JSON.parse(atob(props.history.match.params.id)) : "";
        const token = localStorage.getItem('tokenweb');
        const headers = { 'token': `Bearer ${token}` }

        const response = axios.get(`${API_URL.getOneCalculation}`, { headers });
        if (reload == true) {
            response.then(resp => {
                let capGro = resp?.data?.data?.capitalGrowth;
                let rental1 = resp?.data?.data?.rental_return
                let ground_r = resp?.data?.data?.ground_rent
                let service_chrg = resp?.data?.data?.service_charge
                if (response) {
                    let gd_rent = data && data.ground_rent ? "£ " + parseInt(data.ground_rent).toLocaleString() : "£ " + parseInt(data.Investor_price * (ground_r / 100).toLocaleString())
                    setYr1_Ground_Rent(gd_rent)
                    setYr2_Ground_Rent(gd_rent)
                    setYr3_Ground_Rent(gd_rent)
                    setYr4_Ground_Rent(gd_rent)
                    setYr5_Ground_Rent(gd_rent)

                    let serviceCharge = data && data.serviceCharge ? "£ " + parseInt(data.area * data.serviceCharge).toLocaleString() : "£ " + parseInt(data.area * service_chrg).toLocaleString()


                    setYr1_Anticipated_Service_Charge(serviceCharge)
                    setYr2_Anticipated_Service_Charge(serviceCharge)
                    setYr3_Anticipated_Service_Charge(serviceCharge)
                    setYr4_Anticipated_Service_Charge(serviceCharge)
                    setYr5_Anticipated_Service_Charge(serviceCharge)


                    let Yr1_ground_precent = data && data?.capGrowth?.Year1_Percent ? (parseFloat(data.capGrowth.Year1_Percent).toFixed(2)) + "%" : (parseFloat(capGro?.Year1_Percent)).toFixed(2) + "%"
                    setYr1_Annual_Capital_Growth_Percent(Yr1_ground_precent)
                    let Yr2_ground_precent = data && data?.capGrowth?.Year2_Percent ? (parseFloat(data.capGrowth.Year2_Percent)).toFixed(2) + "%" : (parseFloat(capGro?.Year2_Percent)).toFixed(2) + "%"
                    setYr2_Annual_Capital_Growth_Percent(Yr2_ground_precent)
                    let Yr3_ground_precent = data && data?.capGrowth?.Year3_Percent ? (parseFloat(data.capGrowth.Year3_Percent)).toFixed(2) + "%" : (parseFloat(capGro?.Year3_Percent)).toFixed(2) + "%"
                    setYr3_Annual_Capital_Growth_Percent(Yr3_ground_precent)
                    let Yr4_ground_precent = data && data?.capGrowth?.Year4_Percent ? (parseFloat(data.capGrowth.Year4_Percent)).toFixed(2) + "%" : (parseFloat(capGro?.Year4_Percent)).toFixed(2) + "%"
                    setYr4_Annual_Capital_Growth_Percent(Yr4_ground_precent)
                    let Yr5_ground_precent = data && data?.capGrowth?.Year5_Percent ? (parseFloat(data.capGrowth.Year5_Percent)).toFixed(2) + "%" : (parseFloat(capGro?.Year5_Percent)).toFixed(2) + "%"
                    setYr5_Annual_Capital_Growth_Percent(Yr5_ground_precent)
                    let rental = data && data?.assured_rental ? (parseFloat(data.assured_rental)).toFixed(2) + "%" : (parseFloat(rental1)).toFixed(2) + "%"
                    setAssured_annual_rate(rental)
                    setUpdateState(updateState + 1)
                    setReload(false)

                }
            })
        }
        let fttr = data && data.removeFooter ? data.removeFooter : false
        setremoveFooter(fttr)
        let user = data && data.firstName && data.lastName ? data.firstName + " " + data.lastName : ""
        setCurrentUser(user)
        let mob = data && data.sales_agent_contact ? data.sales_agent_contact : ""
        setmobNo(mob)
        let email = data && data.email ? data.email : ""
        setemail(email)
        // setting values for customers start
        let clientname = values && values.Client ? values.Client : ""
        setClient(clientname)
        let checkDissabled = values && values.check ? values.check : false
        setcheck(checkDissabled)
        let depRate = values && values.Deposit_rate ? parseFloat(values.Deposit_rate) * 100 + "%" : "30%"
        setDeposit_rate(depRate)
        let Solicitor_Fee2 = values && values.Solicitor_Fee ? "£ " + parseInt(values.Solicitor_Fee).toLocaleString() : "£ 0"
        setSolicitor_Fee(Solicitor_Fee2)
        let Mortgage_Broker_Fee2 = values && values.Mortgage_Broker_Fee ? "£ " + parseInt(values.Mortgage_Broker_Fee).toLocaleString() : "£ 0"
        setMortgage_Broker_Fee(Mortgage_Broker_Fee2)
        let Disbursements2 = values && values.Disbursements ? "£ " + parseInt(values.Disbursements).toLocaleString() : "£ 0"
        setDisbursements(Disbursements2)
        let Stamp_Duty2 = values && values.Stamp_Duty ? "£ " + parseInt(values.Stamp_Duty).toLocaleString() : "£ 0"
        setStamp_Duty(Stamp_Duty2)
        let Valuation_Fee2 = values && values.Valuation_Fee ? "£ " + parseInt(values.Valuation_Fee).toLocaleString() : "£ 0"
        setValuation_Fee(Valuation_Fee2)
        let Furniture2 = values && values.Furniture ? "£ " + parseInt(values.Furniture).toLocaleString() : "£ 0"
        setFurniture(Furniture2)
        let mortgageRate = values && values.Mortgage_rate_approx ? (parseFloat(values.Mortgage_rate_approx) * 100).toFixed(2) + "%" : "2%"
        setMortgage_rate_approx(mortgageRate)
        let periodYrs = values && values.Mortgage_period_yrs ? parseInt(values.Mortgage_period_yrs) : 25
        setMortgage_period_yrs(periodYrs)
        // let Yr1ServiceCharge = values && values.Yr1_Anticipated_Service_Charge ? "£ " + parseInt(values.Yr1_Anticipated_Service_Charge).toLocaleString() : "£ 250"
        // setYr1_Anticipated_Service_Charge(Yr1ServiceCharge)
        // let Yr2ServiceCharge = values && values.Yr2_Anticipated_Service_Charge ? "£ " + parseInt(values.Yr2_Anticipated_Service_Charge).toLocaleString() : "£ 250"
        // setYr2_Anticipated_Service_Charge(Yr2ServiceCharge)
        // let Yr3ServiceCharge = values && values.Yr3_Anticipated_Service_Charge ? "£ " + parseInt(values.Yr3_Anticipated_Service_Charge).toLocaleString() : "£ 250"
        // setYr3_Anticipated_Service_Charge(Yr3ServiceCharge)
        // let Yr4ServiceCharge = values && values.Yr4_Anticipated_Service_Charge ? "£ " + parseInt(values.Yr4_Anticipated_Service_Charge).toLocaleString() : "£ 250"
        // setYr4_Anticipated_Service_Charge(Yr4ServiceCharge)
        // let Yr5ServiceCharge = values && values.Yr5_Anticipated_Service_Charge ? "£ " + parseInt(values.Yr5_Anticipated_Service_Charge).toLocaleString() : "£ 250"
        // setYr5_Anticipated_Service_Charge(Yr5ServiceCharge)

        // let Yr1GroundRent = values && values.Yr1_Ground_Rent ? "£ " + parseInt(values.Yr1_Ground_Rent).toLocaleString() : "£ 350"
        // setYr1_Ground_Rent(Yr1GroundRent)
        // let Yr2GroundRent = values && values.Yr2_Ground_Rent ? "£ " + parseInt(values.Yr2_Ground_Rent).toLocaleString() : "£ 350"
        // setYr2_Ground_Rent(Yr2GroundRent)
        // let Yr3GroundRent = values && values.Yr3_Ground_Rent ? "£ " + parseInt(values.Yr3_Ground_Rent).toLocaleString() : "£ 350"
        // setYr3_Ground_Rent(Yr3GroundRent)
        // let Yr4GroundRent = values && values.Yr4_Ground_Rent ? "£ " + parseInt(values.Yr4_Ground_Rent).toLocaleString() : "£ 350"
        // setYr4_Ground_Rent(Yr4GroundRent)
        // let Yr5GroundRent = values && values.Yr5_Ground_Rent ? "£ " + parseInt(values.Yr5_Ground_Rent).toLocaleString() : "£ 350"
        // setYr5_Ground_Rent(Yr5GroundRent)

        // let Yr1_ground_precent = values && values.Yr1_Annual_Capital_Growth_Percent ? (parseFloat(values.Yr1_Annual_Capital_Growth_Percent) * 100).toFixed(2) + "%" : "2.00%"
        // setYr1_Annual_Capital_Growth_Percent(Yr1_ground_precent)
        // let Yr2_ground_precent = values && values.Yr2_Annual_Capital_Growth_Percent ? (parseFloat(values.Yr2_Annual_Capital_Growth_Percent) * 100).toFixed(2) + "%" : "2.50%"
        // setYr2_Annual_Capital_Growth_Percent(Yr2_ground_precent)
        // let Yr3_ground_precent = values && values.Yr3_Annual_Capital_Growth_Percent ? (parseFloat(values.Yr3_Annual_Capital_Growth_Percent) * 100).toFixed(2) + "%" : "1.50%"
        // setYr3_Annual_Capital_Growth_Percent(Yr3_ground_precent)
        // let Yr4_ground_precent = values && values.Yr4_Annual_Capital_Growth_Percent ? (parseFloat(values.Yr4_Annual_Capital_Growth_Percent) * 100).toFixed(2) + "%" : "2.50%"
        // setYr4_Annual_Capital_Growth_Percent(Yr4_ground_precent)
        // let Yr5_ground_precent = values && values.Yr5_Annual_Capital_Growth_Percent ? (parseFloat(values.Yr5_Annual_Capital_Growth_Percent) * 100).toFixed(2) + "%" : "2.00%"
        // setYr5_Annual_Capital_Growth_Percent(Yr5_ground_precent)
        // setting values for customers end 
        if (status == true) {
            setvalues(data)
            setstatus(false)
        }
        let investor_price = data && data.Investor_price ? "£ " + parseInt(data.Investor_price).toLocaleString() : "£ 0"
        setProperty_Market_Value(investor_price)
        let date = moment().format("D MMMM YYYY");
        setcurrentDate(date)
        let development = data && data.Development_name ? data.Development_name : ""
        setDevelopment(development)
        let unitno = data && data.unit_no ? data.unit_no : ""
        setUnit(unitno)
        let propValue = investor_price.split(" ")[1].replace(/\,/g, '')
        Property_Market_Value = propValue
        setDeposit("£ " + Math.round(parseInt(Property_Market_Value) * parseFloat(Deposit_rate) / 100).toLocaleString())
        calculate_Total_Fees()
        calculate_Mortgage_Value()
        handleMortgage_period_Month()
        calculateAssured_annual_rate();
        caluclateYr1_Cashflow()
        caluclateYr2_Cashflow()
        caluclateYr3_Cashflow()
        caluclateYr4_Cashflow()
        caluclateYr5_Cashflow()
        calculateAll_Projected_Property_Value()


    }, [Deposit, Total_Fees, Rate, Assured_annual_amount, Yr1_Cashflow, Yr2_Cashflow, Yr3_Cashflow, Yr4_Cashflow,
        Yr5_Cashflow, Total_Fees_2, updateState])
    return (
        <Grid>
            <Grid id="divToPrint" className="calc_mainContainer">
                <Grid className="calc_Header">
                    <Grid container direction="row">
                        <Grid item xs={8} md={9}>
                            <Grid className="Header_Text">
                                <h4>Your personal investment forecast: {currentDate}</h4>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Grid className="Header_LOgo">
                                <img src={publicUrl + "assets/img/SIP_Logo.png"} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* table section  */}
                <Grid className="calc_TblContainer">
                    <Grid container direction="row">
                        <Grid item xs={12} md={6}>
                            {/* property details  */}
                            <Grid className="Tbl_pymtDetil">
                                <Grid container direction="row">
                                    <Grid item xs={12} md={12} className="caltHead_BtomSpace">
                                        <Grid className="Prsl_detl">
                                            <Grid container direction="row">
                                                <Grid item xs={8} md={4}>
                                                    <Grid className="cal_tHead HeadCurve">
                                                        <span>Property details</span>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4} md={8}>
                                                    <Grid className="PlanePSace">
                                                        <Grid className="cal_tHead BlnkHeight">

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid className="cal_tHead">
                                        <span>Property details</span>
                                    </Grid> */}
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Client:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2">
                                            <TextField
                                                className="txtColor"
                                                type="text"
                                                name="Client"
                                                placeholder="Enter Name"
                                                value={Client}
                                                fullWidth
                                                disabled={check}
                                                onChange={(e) => { handleClient(e.target.value); valueupade(e) }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Development:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2 TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Development}
                                                fullWidth
                                                onChange={(e) => handleDevelopment(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Unit:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2">
                                            <TextField
                                                type="text"
                                                value={Unit}
                                                fullWidth
                                                onChange={(e) => handleUnit(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Investment price:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2 TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Property_Market_Value}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Deposit @</span> <TextField className="txtColor" value={Deposit_rate} name="Deposit_rate" disabled={check}
                                                onChange={(e) => { handleDeposit_rate(e.target.value); valueupade(e); }}
                                                onFocus={(e) => updateInput(e.target.value)}
                                                onBlur={(e) => updateInput2(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2">
                                            <TextField
                                                type="text"
                                                value={Deposit}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Cash required:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2 TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Total_Fees_2}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow ToTalCostInvst ToTalCostInvst_title">
                                            <span>Mortgage required:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2 TblNextRow ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Mortgage_Value}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* end of property details */}

                            <Grid className="Tbl_cshFlw">
                                <Grid container direction="row">
                                    <Grid item xs={12} md={12} className="caltHead_BtomSpace">
                                        <Grid className="Prsl_detl">
                                            <Grid container direction="row">
                                                <Grid item xs={9} md={6} lg={5}>
                                                    <Grid className="cal_tHead HeadCurve">
                                                        <span>Interest only mortgage</span>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} md={6} lg={7}>
                                                    <Grid className="PlanePSace">
                                                        <Grid className="cal_tHead BlnkHeight">

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid className="cal_tHead">
                                        <span>Interest only Mortgage</span>
                                    </Grid> */}
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Mortgage period (yrs):</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2">
                                            <TextField
                                                className="txtColor"
                                                type="text"
                                                name="Mortgage_period_yrs"
                                                value={Mortgage_period_yrs}
                                                disabled={check}
                                                onChange={(e) => { handleMortgage_period_yrs(e.target.value); valueupade(e); }}
                                                onBlur={(e) => updateMortgage_period_yrs2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Mortgage period (mths):</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2 TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Mortgage_period_Month}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Mortgage rate (approx):</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2">
                                            <TextField
                                                className="txtColor"
                                                type="text"
                                                name="Mortgage_rate_approx"
                                                value={Mortgage_rate_approx}
                                                disabled={check}
                                                onChange={(e) => { handleMortgage_rate_approx(e.target.value); valueupade(e); }}
                                                onFocus={(e) => updateMortgage_rate_approx(e.target.value)}
                                                onBlur={(e) => updateMortgage_rate_approx2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Annual payment:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2 TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Rate}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Amount borrowed:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2">
                                            <TextField
                                                type="text"
                                                value={PV}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow1 ToTalCostInvst ToTalCostInvst_title">
                                            <span>Monthly repayments:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="TblCOl1">
                                        <Grid className="TblRow2 ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Monthly_repayments}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* purchasing details  */}
                            <Grid className="Tbl_pymtDetil purch_Container">
                                <Grid className="purchase_details">
                                    <Grid container direction="row">
                                        <Grid item xs={12} md={12} className="caltHead_BtomSpace">
                                            <Grid className="Prsl_detl">
                                                <Grid container direction="row">
                                                    <Grid item xs={8} md={5} lg={4}>
                                                        <Grid className="cal_tHead HeadCurve">
                                                            <span>Purchasing details</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={7} lg={8}>
                                                        <Grid className="PlanePSace">
                                                            <Grid className="cal_tHead BlnkHeight">

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid className="cal_tHead">
                                        <span>Purchasing details</span>
                                    </Grid> */}
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow1">
                                                <span>Solicitors fee:</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow2">
                                                <TextField
                                                    type="text"
                                                    className="txtColor"
                                                    value={Solicitor_Fee}
                                                    name="Solicitor_Fee"
                                                    disabled={check}
                                                    fullWidth
                                                    onChange={(e) => { handleSolicitor_Fee(e.target.value); valueupade(e) }}
                                                    onFocus={(e) => updateSolicitor_Fee(e.target.value)}
                                                    onBlur={(e) => updateSolicitor_Fee2(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow1 TblNextRow">
                                                <span>Mortgage broker fee:</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow2 TblNextRow">
                                                <TextField
                                                    type="text"
                                                    className="txtColor"
                                                    value={Mortgage_Broker_Fee}
                                                    name="Mortgage_Broker_Fee"
                                                    disabled={check}
                                                    fullWidth
                                                    onChange={(e) => { handleMortgage_Broker_Fee(e.target.value); valueupade(e) }}
                                                    onFocus={(e) => updateMortgage_Broker_Fee(e.target.value)}
                                                    onBlur={(e) => updateMortgage_Broker_Fee2(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow1">
                                                <span>Disbursements (est):</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow2">
                                                <TextField
                                                    type="text"
                                                    className="txtColor"
                                                    value={Disbursements}
                                                    name="Disbursements"
                                                    fullWidth
                                                    disabled={check}
                                                    onChange={(e) => { handleDisbursements(e.target.value); valueupade(e) }}
                                                    onFocus={(e) => updateDisbursements(e.target.value)}
                                                    onBlur={(e) => updateDisbursements2(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow1 TblNextRow">
                                                <span>Stamp Duty:</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow2 TblNextRow">
                                                <TextField
                                                    type="text"
                                                    className="txtColor"
                                                    value={Stamp_Duty}
                                                    name="Stamp_Duty"
                                                    disabled={check}
                                                    onChange={(e) => { handleStamp_Duty(e.target.value); valueupade(e) }}
                                                    onFocus={(e) => updateStamp_Duty(e.target.value)}
                                                    onBlur={(e) => updateStamp_Duty2(e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow1">
                                                <span>Valuation fee (est):</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow2">
                                                <TextField
                                                    type="text"
                                                    className="txtColor"
                                                    value={Valuation_Fee}
                                                    name="Valuation_Fee"
                                                    disabled={check}
                                                    onChange={(e) => { handleValuation_Fee(e.target.value); valueupade(e) }}
                                                    onFocus={(e) => updateValuation_Fee(e.target.value)}
                                                    onBlur={(e) => updateValuation_Fee2(e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow1 TblNextRow">
                                                <span>Furniture (ex VAT):</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow2 TblNextRow">
                                                <TextField
                                                    type="text"
                                                    className="txtColor"
                                                    value={Furniture}
                                                    name="Furniture"
                                                    disabled={check}
                                                    onChange={(e) => { handleFurniture(e.target.value); valueupade(e) }}
                                                    onFocus={(e) => updateFurniture(e.target.value)}
                                                    onBlur={(e) => updateFurniture2(e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow1 TblNextRow ToTalCostInvst ToTalCostInvst_title">
                                                <span>Total purchasing costs:</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6} className="TblCOl1">
                                            <Grid className="TblRow2 TblNextRow ToTalCostInvst">
                                                <TextField
                                                    type="text"
                                                    value={Total_Fees}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className="Tbl_cshFlw AsurdTbl">
                                    <Grid container direction="row" justifyContent="flex-end">
                                        <Grid item xs={6} md={6}>
                                            <Grid className="cal_tHead AnnulLeft MobAnnual">
                                                <span>Assured annual rent (gross) @ {Assured_annual_rate}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Grid className="cal_tHead AnnulRight">
                                                <span>{Assured_annual_amount}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Grid className="cal_tHead AnnulLeft AnnulMidd MobAnnual">
                                                <span>Initial monthly assured rent:</span>
                                                {/* <TextField value={Assured_annual_rate}
                                                // onChange={(e) => handleAssured_annual_rate(e.target.value)}
                                                // onFocus={(e) => updateAssured_annual_rate(e.target.value)}
                                                // onBlur={(e) => updateAssured_annual_rate2(e.target.value)}
                                            /> */}

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Grid className="cal_tHead AnnulRightBottom  AnnulRight AnnulMidd">
                                                <span>{Monthly_assured_rent}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                            {/* end of purchasing details */}
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                        <Grid className="BlnkSeprate"></Grid>
                    </Grid> */}

                        {/* shows details context */}
                        {/* <Grid item xs={12} md={12}>
                        <Grid className="SHOwsDetails">
                            <p>The details shown in this projection are provided solely as an example of possible growth and return for the property investment specified. Whilst every care has been taken to
                                provide realistic figures with the information available at the time, it must be accepted that they do not necessarily reflect the actual growth and return as this will be in the
                                future and can therefore not be guaranteed to be correct.
                            </p>
                            <p>The projections given are a combination of factual elements such as price and estimation such as potential return. No guarantee, warranty or assurance is given, and the
reader must assess and appraise the content of this document in that light with the clear understanding that this is an example only. * Other management costs may apply.</p>
                        </Grid>
                    </Grid> */}
                        {/* end of shows details context */}
                    </Grid>
                </Grid>

                <Grid className="Rent_TblContainer">
                    <Grid container direction="row">
                        <Grid item xs={12} md={12} className="caltHead_BtomSpace">
                            <Grid className="Prsl_detl">
                                <Grid container direction="row">
                                    <Grid item xs={8} md={2}>
                                        <Grid className="cal_tHead HeadCurve">
                                            <span>Rental income</span>
                                        </Grid>
                                    </Grid>
                                    <Grid calssName="minCol" item xs={4} md={10}>
                                        <Grid className="PlanePSace">
                                            <Grid className="YearBannerContainer">
                                                <Grid container direction="row" className="customRow2 mobileTabls">
                                                    {/* ----------------year Banner stuff ------------------ */}
                                                    <Grid item xs={12} md={3}></Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Grid className="HalfBox TblColorWhite">
                                                            <span>1 Year</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Grid className="HalfBox">
                                                            <span>2 Year</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Grid className="HalfBox TblColorWhite">
                                                            <span>3 Year</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Grid className="HalfBox">
                                                            <span>4 Year</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Grid className="HalfBox TblColorWhite">
                                                            <span>5 Year</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className="cal_tHead BlnkHeight">

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 1 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Annual gross assured rental:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr1_Annual_Gross_Assured_Rental}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Annual_Gross_Assured_Rental}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr3_Annual_Gross_Assured_Rental}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Annual_Gross_Assured_Rental}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr5_Annual_Gross_Assured_Rental}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 2 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Annual mortgage repayments:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Yr1_Annual_Mortgage_repayments}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Annual_Mortgage_repayments}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Yr3_Annual_Mortgage_repayments}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Annual_Mortgage_repayments}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Yr5_Annual_Mortgage_repayments}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 3  */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Anticipated service charge:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr1_Anticipated_Service_Charge"
                                                value={Yr1_Anticipated_Service_Charge}
                                                disabled={check}
                                            // onChange={(e) => { handleYr1_Anticipated_Service_Charge(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr1_Anticipated_Service_Charge(e.target.value)}
                                            // onBlur={(e) => updateYr1_Anticipated_Service_Charge2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr2_Anticipated_Service_Charge"
                                                value={Yr2_Anticipated_Service_Charge}
                                                disabled={check}
                                            // onChange={(e) => { handleYr2_Anticipated_Service_Charge(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr2_Anticipated_Service_Charge(e.target.value)}
                                            // onBlur={(e) => updateYr2_Anticipated_Service_Charge2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr3_Anticipated_Service_Charge"
                                                value={Yr3_Anticipated_Service_Charge}
                                                disabled={check}
                                            // onChange={(e) => { handleYr3_Anticipated_Service_Charge(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr3_Anticipated_Service_Charge(e.target.value)}
                                            // onBlur={(e) => updateYr3_Anticipated_Service_Charge2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr4_Anticipated_Service_Charge"
                                                value={Yr4_Anticipated_Service_Charge}
                                                disabled={check}
                                            // onChange={(e) => { handleYr4_Anticipated_Service_Charge(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr4_Anticipated_Service_Charge(e.target.value)}
                                            // onBlur={(e) => updateYr4_Anticipated_Service_Charge2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr5_Anticipated_Service_Charge"
                                                value={Yr5_Anticipated_Service_Charge}
                                                disabled={check}
                                            // onChange={(e) => { handleYr5_Anticipated_Service_Charge(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr5_Anticipated_Service_Charge(e.target.value)}
                                            // onBlur={(e) => updateYr5_Anticipated_Service_Charge2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 4 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Ground rent:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr1_Ground_Rent"
                                                value={Yr1_Ground_Rent}
                                                disabled={check}
                                            // onChange={(e) => { handleYr1_Ground_Rent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr1_Ground_Rent(e.target.value)}
                                            // onBlur={(e) => updateYr1_Ground_Rent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                name="Yr2_Ground_Rent"
                                                className="txtColor"
                                                value={Yr2_Ground_Rent}
                                                disabled={check}
                                            // onChange={(e) => { handleYr2_Ground_Rent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr2_Ground_Rent(e.target.value)}
                                            // onBlur={(e) => updateYr2_Ground_Rent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr3_Ground_Rent"
                                                value={Yr3_Ground_Rent}
                                                disabled={check}
                                            // onChange={(e) => { handleYr3_Ground_Rent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr3_Ground_Rent(e.target.value)}
                                            // onBlur={(e) => updateYr3_Ground_Rent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr4_Ground_Rent"
                                                value={Yr4_Ground_Rent}
                                                disabled={check}
                                            // onChange={(e) => { handleYr4_Ground_Rent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr4_Ground_Rent(e.target.value)}
                                            // onBlur={(e) => updateYr4_Ground_Rent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                name="Yr5_Ground_Rent"
                                                value={Yr5_Ground_Rent}
                                                disabled={check}
                                            // onChange={(e) => { handleYr5_Ground_Rent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr5_Ground_Rent(e.target.value)}
                                            // onBlur={(e) => updateYr5_Ground_Rent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* row 5 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Management fee (8% of gross rental income)*:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr1_Management_Fee}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Management_Fee}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr3_Management_Fee}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Management_Fee}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr5_Management_Fee}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 6 */}
                        <Grid item item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 ToTalCostInvst ToTalCostInvst_title">
                                            <span>Rental income:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr1_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr3_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr5_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item item xs={12} md={12}>
                                        <Grid className="TblBotom">
                                            <Grid container direction="row">
                                                <Grid calssName="minCol" item xs={4} md={12}>
                                                    <Grid className="PlanePSace">
                                                        <Grid className="YearBannerContainer">
                                                            <Grid container direction="row" className="customRowBotom2          mobileTabls">
                                                                {/* ----------------year Banner stuff ------------------            */}
                                                                <Grid item xs={12} md={3}></Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2 TblColorWhite">

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2">

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2 TblColorWhite">

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2">

                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                {/* capital growth table  */}

                <Grid className="TBLSPca">
                    <Grid container direction="row">
                        <Grid item xs={12} md={12} className="caltHead_BtomSpace">
                            <Grid className="Prsl_detl">
                                <Grid container direction="row">
                                    <Grid item xs={8} md={2}>
                                        <Grid className="cal_tHead HeadCurve">
                                            <span>Capital growth</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} md={10}>
                                        <Grid className="PlanePSace">
                                            <Grid className="cal_tHead BlnkHeight">

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 1 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 ToTalCostInvst_title">
                                            <span>Projected property value:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr1_Projected_Property_Value}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Projected_Property_Value}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr3_Projected_Property_Value}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Projected_Property_Value}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr5_Projected_Property_Value}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 2 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow  ToTalCostInvst_title">
                                            <span>Annual capital growth (%):</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                value={Yr1_Annual_Capital_Growth_Percent}
                                                name="Yr1_Annual_Capital_Growth_Percent"
                                                disabled={check}
                                            // onChange={(e) => { handleYr1_Annual_Capital_Growth_Percent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr1_Annual_Capital_Growth_Percent(e.target.value)}
                                            // onBlur={(e) => updateYr1_Annual_Capital_Growth_Percent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                value={Yr2_Annual_Capital_Growth_Percent}
                                                name="Yr2_Annual_Capital_Growth_Percent"
                                                disabled={check}
                                            // onChange={(e) => { handleYr2_Annual_Capital_Growth_Percent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr2_Annual_Capital_Growth_Percent(e.target.value)}
                                            // onBlur={(e) => updateYr2_Annual_Capital_Growth_Percent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                value={Yr3_Annual_Capital_Growth_Percent}
                                                name="Yr3_Annual_Capital_Growth_Percent"
                                                disabled={check}
                                            // onChange={(e) => { handleYr3_Annual_Capital_Growth_Percent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr3_Annual_Capital_Growth_Percent(e.target.value)}
                                            // onBlur={(e) => updateYr3_Annual_Capital_Growth_Percent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                value={Yr4_Annual_Capital_Growth_Percent}
                                                name="Yr4_Annual_Capital_Growth_Percent"
                                                disabled={check}
                                            // onChange={(e) => { handleYr4_Annual_Capital_Growth_Percent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr4_Annual_Capital_Growth_Percent(e.target.value)}
                                            // onBlur={(e) => updateYr4_Annual_Capital_Growth_Percent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                className="txtColor"
                                                value={Yr5_Annual_Capital_Growth_Percent}
                                                name="Yr5_Annual_Capital_Growth_Percent"
                                                disabled={check}
                                            // onChange={(e) => { handleYr5_Annual_Capital_Growth_Percent(e.target.value); valueupade(e); }}
                                            // onFocus={(e) => updateYr5_Annual_Capital_Growth_Percent(e.target.value)}
                                            // onBlur={(e) => updateYr5_Annual_Capital_Growth_Percent2(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 3  */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 ToTalCostInvst ToTalCostInvst_title">
                                            <span>Annual capital growth (£):</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr1_Annual_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Annual_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr3_Annual_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Annual_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr5_Annual_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 4 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow ToTalCostInvst ToTalCostInvst_title">
                                            <span>Annual return on investment:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr1_Annual_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Annual_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr3_Annual_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Annual_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr5_Annual_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* cumulative ROI */}
                <Grid className="TBLSPca">
                    <Grid container direction="row">
                        <Grid item xs={12} md={12} className="caltHead_BtomSpace">
                            <Grid className="Prsl_detl">
                                <Grid container direction="row">
                                    <Grid item xs={8} md={2}>
                                        <Grid className="cal_tHead HeadCurve">
                                            <span>Cumulative ROI</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} md={10}>
                                        <Grid className="PlanePSace">
                                            <Grid className="cal_tHead BlnkHeight">

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 1 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1">
                                            <span>Rental income:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr1_Cumulative_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Cumulative_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr3_Cumulative_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Cumulative_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl">
                                            <TextField
                                                type="text"
                                                value={Yr5_Cumulative_Cashflow}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 2 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow">
                                            <span>Capital growth:</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Yr1_Cumulative_Capital_Growth} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Cumulative_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Yr3_Cumulative_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Cumulative_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow">
                                            <TextField
                                                type="text"
                                                value={Yr5_Cumulative_Capital_Growth}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 3  */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid item xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 ToTalCostInvst ToTalCostInvst_title">
                                            <span>Return on investment (£):</span>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid item className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr1_Cumulative_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} md={2} className="TblCOl1">
                                        <Grid item className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Cumulative_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr3_Cumulative_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Cumulative_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr5_Cumulative_Return_on_Investment}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* row 4 */}
                        <Grid item xs={12} md={12}>
                            <Grid className="Rental_rows">
                                <Grid container direction="row" className="customRow">
                                    <Grid xs={12} md={3} className="TblCOl1">
                                        <Grid className="TblRow1 TblNextRow ToTalCostInvst ToTalCostInvst_title">
                                            <span>Return on investment(%):</span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr1_Cumulative_Return_on_Investment_Percent}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr2_Cumulative_Return_on_Investment_Percent}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr3_Cumulative_Return_on_Investment_Percent}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow Rent_ColrCol">
                                            <TextField
                                                type="text"
                                                value={Yr4_Cumulative_Return_on_Investment_Percent}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} className="TblCOl1">
                                        <Grid className="TblRow2 BigTblCOl TblNextRow ToTalCostInvst">
                                            <TextField
                                                type="text"
                                                value={Yr5_Cumulative_Return_on_Investment_Percent}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item item xs={12} md={12}>
                                        <Grid className="TblBotom">
                                            <Grid container direction="row">
                                                <Grid calssName="minCol" item xs={4} md={12}>
                                                    <Grid className="PlanePSace">
                                                        <Grid className="YearBannerContainer">
                                                            <Grid container direction="row" className="customRowBotom2          mobileTabls">
                                                                {/* ----------------year Banner stuff ------------------            */}
                                                                <Grid item xs={12} md={3}></Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2 TblColorWhite">

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2">

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2 TblColorWhite">

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Grid className="HalfBox HalfBox2">

                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="Info_Container">
                    <Grid container direction="row">
                        <Grid item xs={12} md={12}>
                            <Grid className="propertyInfo">
                                <p>Prepared by {currentUser}</p>
                                <p>Solomon Investment Partners</p>
                                <p><span>m.</span> {mobNo}</p>
                                <p><span>e.</span> {email}</p>
                                <p><span>a.</span> Knights Court, Weaver Street, Chester CH1 2BQ</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* shows details context */}
                <Grid item xs={12} md={12}>
                    <Grid className="SHOwsDetails">
                        <p>The details shown in this projection are provided solely as an example of possible growth and return for the property investment specified. Whilst every care has been taken to
                            provide realistic figures with the information available at the time, it must be accepted that they do not necessarily reflect the actual growth and return as this will be in the
                            future and can therefore not be guaranteed to be correct.
                        </p>
                        <p>The projections given are a combination of factual elements such as price and estimation such as potential return. No guarantee, warranty or assurance is given, and the
                            reader must assess and appraise the content of this document in that light with the clear understanding that this is an example only. * Other management costs may apply.</p>
                    </Grid>
                </Grid>
            </Grid>
            {removeFooter == false &&
                <footer className="footer">
                    <Button className="btn btn-yellow" onClick={handleShare}>Share</Button>
                    <Modal
                        open={isModalShareOpen}
                        onClose={() => { closeShareModal() }}
                        className={'modal-classes-data'}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                    >
                        <Grid className="rejectBoxCntnt modal-share">
                            <span class="close" onClick={closeShareModal}>&times;</span>
                            <div className="col-sm-12">
                                {/* {successMsg && <span>{successMsg}</span>} */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label>Email: </label>
                                    </div>
                                    <div className={"col-sm-8 " + emailColor}>
                                        <input type="email" name="email" onChange={handleShareChange} id="email" className="" />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label>First name: </label>
                                    </div>
                                    <div className={"col-sm-8 " + nameColor}>
                                        <input type="text" name="fullname" id="fullname" onChange={handleShareChange} className="" />
                                    </div>
                                </div>
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    {!loading && <button onClick={confirmShare} disabled={button} className="imgHold">Send</button>}
                                    {loading && <CircularProgress />}
                                </div>
                            </div>
                        </Grid>
                    </Modal>
                </footer>
            }
        </Grid>
    )
}

const mapStateToProps = (state) => {
    const { searchUnits } = state.search;
    const { user, token, userType } = state.authUser;
    return {
        searchUnits, user, token, userType
    };
}
export default connect(mapStateToProps, { signinUserSuccess, getDevlopmentFailure, getSearchFailure, getDevelopmentUnitFailure })(Sample);