import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class DevelopmentDetails extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        return <div className="property-details-area">

        </div>
    }
}

export default DevelopmentDetails