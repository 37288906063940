/**
 * Todo Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// api
import { getNotificationApi, updateNotificationApi } from './../api';

import {
    GET_NOTIFICATION,
    UPDATE_NOTIFICATION
} from './../actions/types';

import {
	getNotificationFailure,
    getNotificationSuccess,
    updateNotificationFailure,
	updateNotificationSuccess
} from './../actions';

/**
 * Send Todos Request To Server
 */
const getNotificationRequest = async (token) =>
await getNotificationApi(token)
	.then(response => response)
	.catch(error => error);


/**
 * Send Todos Request To Server
 */
const updateNotificationRequest = async (token) =>
await updateNotificationApi(token)
	.then(response => response)
	.catch(error => error);


/**
 * Get Todos From Server 
 */
function* getNotificationServer({ payload }) {
	const { token } = payload;
	try {
		const response = yield call(getNotificationRequest, token);
		if (response.data.success) {
            let data = response.data.data.messages;
            console.log("data", data)
            yield put(getNotificationSuccess(data));
		}
		else {
			yield put(getNotificationFailure());
		
		}
	} catch (error) {
		console.log("error", error)
		yield put(getNotificationFailure(error));
		
	}

}

/**
 * Get Todos From Server 
 */
function* updateNotificationServer({ payload }) {
	const { token } = payload;
	try {
		const response = yield call(updateNotificationRequest, token);
		if (response.data.success) {
            let data = response.data.data.messages;
            console.log("data", data)
            yield put(updateNotificationSuccess(data));
		}
		else {
			yield put(updateNotificationFailure());
		
		}
	} catch (error) {
		console.log("error", error)
		yield put(updateNotificationFailure(error));
		
	}

}


/**
 * Get Notification
 */
export function* getNotification() {
	yield takeEvery(GET_NOTIFICATION, getNotificationServer);
}


/**
 * Get Notification
 */
export function* updateNotification() {
	yield takeEvery(UPDATE_NOTIFICATION, updateNotificationServer);
}



/**
 * Email Root Saga
 */
export default function* rootSaga() {
	yield all([
        fork(getNotification),
        fork(updateNotification)
	]);
}