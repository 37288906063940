import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import AlertMessage from './alertMessage';
import { API_URL } from '../../url/apiUrl';
import { connect } from 'react-redux';
import { signinUserInFirebase, checkToken, signinUserFailure, logoutUserFromFirebaseSuccess } from '../../actions'
import { bindActionCreators } from 'redux';
const publicUrl = process.env.PUBLIC_URL + '/'
class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: "",
			password: "",
			errorMessage: "",
			successMessage: "",
		}
	}

	componentDidMount = async () => {
		await this.getTokenForVerification()
	}
	componentDidUpdate(prevProps) {
		if (prevProps.message !== this.props.message) {
			this.setState({ errorMessage: this.props.message })
			setTimeout(() => {
				this.setState({ errorMessage: null })
			}, 3000)
		}
	}

	getTokenForVerification = async () => {
	//	this.props.checkToken(this.props.token, this.props.history)
	}

	handleChange = (event) => {
		const isCheckbox = event.target.type === 'checkbox'
		this.setState({
			[event.target.name]: isCheckbox ? event.target.checked : event.target.value
		})
	}

	loginUser = async (event) => {
		try {
			event.preventDefault()
			let formData = {
				email: this.state.email,
				password: this.state.password,
			}
			if (formData.email === '' || formData.email === null || formData.email === undefined) {
				this.setState({ errorMessage: 'Email is empty' })
				setTimeout(() => this.setState({ errorMessage: '' }), 3300);
			}
			else if (formData.password === '' || formData.password === null || formData.password === undefined) {
				this.setState({ errorMessage: 'Password is empty' })
				setTimeout(() => this.setState({ errorMessage: '' }), 3300);
			}
			else {
				this.props.signinUserInFirebase(formData, this.props.history)
			}
		}
		catch (error) {
			this.setState({ errorMessage: error.message })
			setTimeout(() => this.setState({ errorMessage: '' }), 4000);
		}
	}

	forgetPassword(){
		this.props.history.push("/forget-password")
	}


	render() {
		const { errorMessage, successMessage } = this.state

		return (
			<div className="container">
				{/* <AlertMessage errorMessage={errorMessage} successMessage={successMessage} /> */}
				<div className="mt-5 col-md-6" style={{ backgroundColor: '#e3e3e3', padding: '30px', marginBottom: '59px' }}>
					<form >
						<div className="form-group">
							<input type="text" name="email" placeholder="Email" value={this.email} onChange={this.handleChange} className="form-control" />
						</div>
						<div className="form-group">
							<input type="password" name="password" placeholder="Password" value={this.password} onChange={this.handleChange} className="form-control" />
						</div>
						<div>
							<button type="button" className="btn floorplan btn-blue" onClick={this.loginUser} disabled={this.props.loading}>LOGIN</button>
							<a className="forgetpasword" onClick={this.forgetPassword.bind(this)}>Forgot Password?</a>
						</div>
						
					</form>
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state) => {
	const { user, token, userType, message, loading } = state.authUser;
	return {
		user, token, userType, message, loading
	};
}

const mapDispatchToProps = dispatch => bindActionCreators({ signinUserInFirebase, checkToken, signinUserFailure, logoutUserFromFirebaseSuccess }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);