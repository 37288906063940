import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Document } from '../backends/ReactPdf';
import Modal from '@material-ui/core/Modal';
import PageList from '../backends/PageList';
import PdfDocument from '../backends/PdfDocument';
import PdfControls from '../backends/PdfControls';
import './DocumentPreview.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const noop = () => {};

export default class DocView extends React.PureComponent {
    static propTypes = {
        // ...Modal.propTypes,
        fitTo: PropTypes.oneOf(['page', 'width']),
        toolbar: PropTypes.node
    };
    static defaultProps = {
        fitTo: 'page',
        show: false,
        onClose: noop
    };
    // static modalPropTypes = _.keys(Modal.propTypes);
    state = {
        scale: 1,
        loadedPages: {}
    };

    constructor(props) {
        super(props);
        this.state.fitTo = props.fitTo;
        this.state.show = props.show;
        
    }
    componentDidMount(){
        document.getElementById('preloader').style["display"] = 'none';
        document.body.style.overflow = 'auto';
        let url = window.location.hash.split("=")[1];
        this.setState({fileUrl:url});
    }

    handleDocumentLoaded = ({ numPages: pages }) => {
        this.setState({ page: 1, pages });
    };

    handlePageChange = page => this.setState({ page });

    close = () => {
        this.setState({ show: false });
        this.props.onClose();
    };

    render() {
        const { fitTo, scale, pages: pageCount } = this.state;
        const { toolbar } = this.props;
        console.log("fitTo", fitTo)
        return (
            <div>
                {this.state.fileUrl &&<div
                    // {..._.pick(this.props, this.modalPropTypes)}
                    className={`document-preview-modal fit-to-${fitTo}`}
                    
                >
                    <div>
                        
                        <div className="toolbar">
                            {toolbar}
                            {/* <button
                                type="button"
                                className="close"
                                onClick={this.close}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button> */}
                        </div>
                    </div>
                    <div className="modal-contenst">
                        <PdfDocument
                            file={this.state.fileUrl}
                            scale={scale}
                            fitTo={fitTo}
                            onLoad={this.handleDocumentLoaded}
                            onPageChange={this.handlePageChange}
                        />
                    </div>
                    <PdfControls
                        fitTo={fitTo}
                        onZoomToFit={fitTo =>
                            this.setState({ scale: 1, fitTo })
                        }
                        onZoomIn={() => this.setState({ scale: scale * 1.1 })}
                        onZoomOut={() =>
                            this.setState({ scale: Math.max(0.1, scale * 0.9) })
                        }
                        page= {this.state.page} 
                        pageCount= {pageCount}
                    />
                    {this.props.footer && (
                        <div>{this.props.footer}</div>
                    )}
                </div>}
            </div>
        );
    }
}
