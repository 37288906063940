import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { API_URL } from '../../url/apiUrl'
import axios from 'axios'
import { connect } from 'react-redux';
import { getSearch } from '../../actions'
import { bindActionCreators } from 'redux';
import developmentDetails from '../development-details';


const $ = window.$
// const SelectFunc = ({ developments, searchform, developmentDetail, onSelect }) => {
//     const [returnVal, setReturnVal] = useState('')
//     useEffect(() => {
//         if (searchform && searchform['development'] && searchform['development'] !== '') {
//             setReturnVal(searchform['development'])
//         } else if (developmentDetail) {
//             setReturnVal(developmentDetail._id)
//         } else {
//             setReturnVal('')
//         }
//     }, [developmentDetail])

//     console.log("returnVal", returnVal)
//     return <select name="development" className="select single-select" onChange={(e) => onSelect(e)} value={returnVal}>
//         <option value="" selected disabled>Development</option>
//         {developments && developments.map((item) => (
//             <option value={item.value}>{item.label}</option>
//         ))}
//     </select>
// }

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            developments: [],
            bedrooms: [],
            price: [],
            searchform: {},
            devIDPresent: false
        }
    }

    componentDidMount() {
        this.getAgentDevelopment()
        let bedrooms = []
        bedrooms = [{ label: 'All', value: 'undefined' }, { value: 1, label: 'One' }, { value: 2, label: 'Two' }, { value: 3, label: 'Three' }, { value: 4, label: 'Four' }]
        let price = [{ label: 'All', value: 'undefined' }]
        let rate = 50000
        for (let i = 1; rate <= 500000; i++) {

            price.push({ value: rate, label: "£" + (rate).toLocaleString() });
            rate = rate + 25000
        }
        this.setState({ price: price, bedrooms: bedrooms })
    }

    componentDidUpdate(prevProps) {
        if (this.props.developmentDetail && this.props.developmentDetail.details) {
            if (prevProps.developmentDetail?.details !== this.props.developmentDetail.details) {
                $('div.development-select span').text(this.props.developmentDetail.details.name);
            }
        }
    }


    getAgentDevelopment = async () => {
        try {
            const token = localStorage.getItem('tokenweb')
            const headers = { 'token': `Bearer ${token}` }
            const response = await axios.get(`${API_URL.getAssignedDevelopment}`, { headers });
            if (response) {
                this.setState({ developments: [...[{ value: 'undefined', label: 'All' }], ...response.data.data.developments] })
            }
            const $ = window.$;
            let self = this
            if ($('.single-select').length) {
                $('.single-select').niceSelect();
                $('.single-select').change(function (e) {
                    self.onSelect(e)
                })
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    onSelect = (event) => {
        let { searchform } = this.state;
        const { value, name, checked } = event.target;
        if (event.target.type == 'checkbox') searchform[name] = checked ? checked : false
        else searchform[name] = value;

        this.setState({ searchform: searchform })
    }

    search = async () => {
        let { searchform } = this.state;
        let { developmentDetail } = this.props;
        if (developmentDetail && developmentDetail._id && !searchform['development']) {
            searchform['development'] = developmentDetail._id
        }
        this.props.getSearch(this.props.token, searchform)
    }

    changeValue = () => {
        let returnVal
        if (this.state.searchform && this.state.searchform['development'] && this.state.searchform['development'] !== '') {
            returnVal = this.state.searchform['development']
        } else if (this.props.developmentDetail) {
            returnVal = this.props.developmentDetail._id
        } else {
            returnVal = ''
        }
        return returnVal
    }




    render() {
        let { developments, bedrooms, price, searchform } = this.state;
        let publicUrl = process.env.PUBLIC_URL + '/'
        return <div className="container">
            <div className="rld-main-search">
                <div className="row">
                    <div className="inline_conatiner">
                        <div className="inline_view development-select">
                            <div className="rld-single-select">
                                <select name="development" className="select single-select" onChange={(e) => this.onSelect(e)} value={this.changeValue()}>
                                    <option value="" selected disabled>Development</option>
                                    {developments && developments.map((item) => (
                                        <option value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="inline_view">
                            <div className="rld-single-select">
                                <select className="select single-select" name="bedrooms" onChange={(e) => this.onSelect(e)}>
                                    <option value="" selected disabled>Bedrooms</option>
                                    {bedrooms && bedrooms.map(item => (
                                        <option value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="inline_view">
                            <div className="rld-single-select">
                                <select className="select single-select" name="min_price" onChange={(e) => this.onSelect(e)}>
                                    <option value="" selected disabled>Min Price</option>
                                    {price && price.map((item) => (
                                        <option value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="inline_view">
                            <div className="rld-single-select">
                                <select className="select single-select" name="max_price" onChange={(e) => this.onSelect(e)}>
                                    <option value="" selected disable>Max Price</option>
                                    {price && price.map((item) => (
                                        <option value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="inline_view inline_view_btn">
                            <div>
                                <div className="btn btn-yellow" onClick={this.search}>SEARCH NOW</div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <p><input type="checkbox" name="getHold" onClick={e => this.onSelect(e)} /> Show properties currently on hold </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

const mapStateToProps = (state) => {
    const { user, token, userType } = state.authUser;
    const { developmentDetail, parentSelect, childSelect } = state.development;
    const $ = window.$;

    if ($('div.development-select span') && developmentDetail && developmentDetail.details && (!($('div.development-select span').text()) || $('div.development-select span').text() == 'Development')) {
        $('div.development-select span').text(developmentDetail.details.name);
    }
    return {
        user, token, userType, developmentDetail, childSelect, parentSelect
    };


}

const mapDispatchToProps = dispatch => bindActionCreators({ getSearch }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);

