import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_URL } from './../../url/apiUrl';
import { menu_items } from './../../common/index';
import { logoutUserFromFirebase, getNotification, updateNotification } from "../../actions"
const agentType =["super_admin", "sip_admin", "agent_admin"]
class HeaderLogo extends Component {

	state = {
		lName: null,
		fName: null,
		userData: null,
		showMenu: false
	}

	getSelected = (parentSelect, childSelect) => {
		let select = menu_items.find(ele => ele.value === parentSelect)
		let selectedChild = { text: '', value: '' }
		if  (select && select.sub_item) {
			if (childSelect) selectedChild = select.sub_item.find(ele => ele.value === childSelect);
			selectedChild = selectedChild ? selectedChild : { text: '', value: '' }
		}
		else if (select) { }
		else {
			select = { text: parentSelect, value: parentSelect }
		}
		return `${select.text}  ${selectedChild.label && selectedChild.label !== '' ? '- ' + selectedChild.label : ''}`
	}

	getNotificationNumber = () => {
		this.props.getNotification(this.props.token)
	}

	componentDidMount() {
		if (localStorage.getItem("agentUserData")) {
			let userData = JSON.parse(localStorage.getItem("agentUserData"))
			let fName = userData.firstName.charAt(0)
			let lName = userData.lastName.charAt(0)
			this.setState({ fName: fName, lName: lName, userData: userData })
		}
		this.getNotificationNumber()
	}

	logoutClick = (e) => {
		e.preventDefault();
		this.props.logoutUserFromFirebase();
	}

	showMenu = (event) => {
		event.preventDefault();
		this.setState({ showMenu: true }, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	closeMenu = (event) => {
		if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
			this.setState({ showMenu: false }, () => {
				this.props.updateNotification(this.props.token)
				document.removeEventListener('click', this.closeMenu);
			});
		}
	}

	gotoUserView(e){
		if(this.props.userType && agentType.includes(this.props.userType)){
			let data = localStorage.getItem("agentUserData")
			let token = localStorage.getItem('tokenweb')?localStorage.getItem('tokenweb'):''
			localStorage.removeItem('userData')
			localStorage.removeItem('token')
			localStorage.setItem('userData', data)
			localStorage.setItem('token',JSON.stringify(token))
			window.open(API_URL.redirectURl+"/system_admin/app", '_blank')
		}
	}

	getDropdownMenu = (lName, fName, userData, notification, publicUrl) => {
		return (
			<div style={{ position: 'relative' }}>
				<ul className="profile_name">
					<li>
						<div onClick={this.showMenu} className="logoutSectionMenu">
							{fName && fName}{lName && lName}
							{notification && notification.length > 0 && !this.state.showMenu && <span className="numberNotification">{notification.length}</span>}
						</div>
					</li>
					{
						this.state.showMenu
							? (
								<ul
									className="openMenu"
									ref={(element) => {
										this.dropdownMenu = element;
									}}
								>
									{notification && notification.map(dada => (
										<li>{dada.message}</li>
									))}
									<li className="uppername">
										<span className="username"> {(userData && (userData.firstName && userData.firstName) + " " + (userData.lastName && userData.lastName))}</span>
									</li>
									{this.props.userType && agentType.includes(this.props.userType)&&
									<li style={{cursor:'pointer'}}>
										<a  onClick={(e) => this.gotoUserView(e)}>
											<span>Go to Agent Admin</span>
										</a>
									</li>}
									<li className="userInfo1" onClick={this.logoutClick.bind(this)}>
										Logout
										<img src={publicUrl + 'assets/img/icons/logout16.png'} style={{ maxHeight: "25px", marginLeft: 5 }} />
									</li>
								</ul>
							)
							: (
								null
							)
					}
				</ul>
			</div>
		);
	}

	render() {
		let { lName, fName, userData } = this.state
		let publicUrl = process.env.PUBLIC_URL + '/'
		const { developmentDetail, parentSelect, childSelect, showTitle, notification } = this.props;
		let DevelopmentTitle = developmentDetail && developmentDetail.details ? developmentDetail.details.name : null;
		let location = developmentDetail && developmentDetail.details ? developmentDetail.details.location : null;
		return (
			<div className="container">
				<div className="navbar-area">
					<nav className="navbar navbar-area navbar-expand-lg navbar-area-fixed">
						<div className="container pd-15 display-flex">
							<div className="logo readeal-top">
								<Link to="/"><img src={publicUrl + "assets/img/logo.png"} alt="logo" /></Link>
							</div>
							{showTitle && <div className="middle_section inner-page-container">
								{DevelopmentTitle &&
									<div>
										<h3>{DevelopmentTitle}</h3>
										<span>{location}</span>
										<p>{this.getSelected(parentSelect, childSelect)}</p>
									</div>
								}
							</div>}
							<div className="right_nav">
								{this.getDropdownMenu(lName, fName, userData, notification, publicUrl)}
							</div>
						</div>
					</nav>
				</div>
				<div className="middle_section middle-new1">
					{DevelopmentTitle &&
						<div>
							<h3>{DevelopmentTitle}</h3>
							<span>{location}</span>
							{/* <p>Marketing - Brochures</p> */}
						</div>
					}
				</div>
			</div>
		)
	}
}
const mapStateToProps = (state) => {

	const { developmentDetail, parentSelect, childSelect } = state.development;
	const { user, token, userType, userData } = state.authUser;
	const { notification } = state.notification

	return {
		developmentDetail, parentSelect, childSelect, user, token, userType, userData, notification
	};
}

export default connect(mapStateToProps, { logoutUserFromFirebase, getNotification, updateNotification })(HeaderLogo);