import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import AlertMessage from './alertMessage';
import { API_URL } from '../../url/apiUrl';
import { connect } from 'react-redux';
import { signinUserInFirebase, checkToken, signinUserFailure, logoutUserFromFirebaseSuccess } from '../../actions'
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';

const publicUrl = process.env.PUBLIC_URL + '/'
class Index extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: "",
			errorMessage: "",
			successMessage: "",
			loading: false
		}
	}

	componentDidMount = async () => {
		await this.getTokenForVerification()
	}
	componentDidUpdate(prevProps) {
		if (prevProps.message !== this.props.message) {
			this.setState({ errorMessage: this.props.message })
			setTimeout(() => {
				this.setState({ errorMessage: null })
			}, 3000)
		}
	}

	getTokenForVerification = async () => {
		//	this.props.checkToken(this.props.token, this.props.history)
	}

	handleChange = (event) => {
		const isCheckbox = event.target.type === 'checkbox'
		this.setState({
			[event.target.name]: isCheckbox ? event.target.checked : event.target.value
		})
	}

	sendLink() {
		let { email } = this.state
		this.setState({ loading: true })
		if (email === '' || email === null || email === undefined) {
			this.setState({ errorMessage: 'Email is empty', loading: false })
			setTimeout(() => this.setState({ errorMessage: '' }), 3300);
		} else {
			axios.post(API_URL.sendResetMail,
				{ email }, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}).then(res => {
				this.setState({loading: false })
				if (res.data.message == "Success") {
					NotificationManager.success("Password reset link sent successfully.");
					setTimeout(()=>{
						this.props.history.push("/#/login")
					})
				} else {
					NotificationManager.error(res.data.message)
				}
			}).catch(err => {
				console.log("sendLink Error", err)
			})
		}

	}


	render() {
		const { errorMessage, successMessage,loading } = this.state
		return (
			<div className="container">
				<AlertMessage errorMessage={errorMessage} successMessage={successMessage} />
				<div className=" mt-5 col-md-6" style={{ backgroundColor: '#e3e3e3', padding: '30px', marginBottom: '59px' }}>
					<form >
						<div className="form-group">
							<input type="text" name="email" placeholder="Email" value={this.email} onChange={this.handleChange} className="form-control" />
						</div>
						<div>
							<button type="button" className="btn floorplan btn-blue" onClick={this.sendLink.bind(this)} disabled={loading}>Send Password reset link</button>
							<a className="forgetpasword" onClick={()=>this.props.history.push("/#/login")}>Go to Login</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state) => {
	const { user, token, userType, message } = state.authUser;
	return {
		user, token, userType, message
	};
}

const mapDispatchToProps = dispatch => bindActionCreators({ signinUserInFirebase, checkToken, signinUserFailure, logoutUserFromFirebaseSuccess }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Index);