/**
 * Todo Sagas
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// api
import { getSearchResult } from './../api';

import {
	SEARCH_UNIT,
	AVAILABLE_SEARCH_UNIT
} from './../actions/types';

import {
	getSearchFailure,
	getSearchSuccess
} from './../actions';
import { API_URL } from '../url/apiUrl';

const special = ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth'];
const deca = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet'];

/**
* Send Todos Request To Server
*/
const getSearchRequest = async (token, searchForm) =>
	await getSearchResult(token, searchForm)
		.then(response => response)
		.catch(error => error);


/**
 * Get Todos From Server 
 */
function* getSearchFromServer({ payload }) {
	document.getElementById('loaderImageSearch').style.display = 'block';
	const { searchForm, token } = payload;
	try {
		const response = yield call(getSearchRequest, token, searchForm);
		if (response.data.success) {
			document.getElementById('loaderImageSearch').style.display = 'none';
			let data = []
			let resArray = response.data.data;
			resArray.map(res => {
				let discount = 0;
				let parkingPrice = res?.development?.details?.parkingPrice ?? 0
				let parkingPlan = res.development.details.parkingPlan
				let parkingUnits = res.development?.parking ?? []
				discount = res.development && res.development.costReturn ? res.development.costReturn.discount : res.rental_return ? res.rental_return : 0
				res.units.map(units => {
					let beds = units.bedrooms ? units.bedrooms + (units.bedrooms == 1 ? ' Bed' : ' Beds') : null;
					let bath = units.bathrooms ? units.bathrooms + (units.bathrooms == '1' ? ' Bathroom' : ' Bathrooms') : null;
					let study = units.study ? true : false;
					let balcony = units.balcony ? true : false;

					let bedsCount = []
					for (let i = 1; i <= units.bedrooms; i++) {
						bedsCount.push(i)
					}
					let decafloor = ''

					let floornumber = units.floor ? !isNaN(units.floor.value) ? parseInt(units.floor.value) : units.floor.value : 'Not Available'
					if (floornumber === 'groundfloor') {
						decafloor = floornumber === 'groundfloor' ? 'Ground' : 'Not Defined ';
					} else
						if (floornumber === 'basement') {
							decafloor = floornumber === 'basement' ? 'Basement' : 'Not Defined ';
						} else
							// if (floornumber === 'basement1' || isNaN(floornumber)) {
							// 	decafloor = floornumber === 'basement1' ? 'Basement1' : 'Not Defined ';
							// }else
							if (floornumber === 'basement2') {
								decafloor = floornumber === 'basement2' ? 'Basement2' : 'Not Defined ';
							} else
								if (floornumber === 'basement3') {
									decafloor = floornumber === 'basement3' ? 'Basement3' : 'Not Defined ';
								} else
									if (floornumber === 'basement4') {
										decafloor = floornumber === 'basement4' ? 'Basement4' : 'Not Defined ';
									}
									else
										if (floornumber < 20) { decafloor = special[floornumber]; }
										else if (floornumber % 10 === 0) { decafloor = deca[Math.floor(floornumber / 10) - 2] + 'ieth'; }
										else { decafloor = deca[Math.floor(floornumber / 10) - 2] + 'y-' + special[floornumber % 10]; }

					let image = process.env.PUBLIC_URL + '/' + 'assets/img/development/9.png';
					if (!units.assets || !units.assets.image) {
						if (res.development && res.development.genericAppartment && units.property_type) {
							let tempimage = res.development.genericAppartment.find(ele => ele.appartmentType && ele.appartmentType.value === units.property_type.value && units.bedrooms && ele.number_of_bedrooms === units.bedrooms)
							if (tempimage) image = API_URL.BucketURl + tempimage.thumbnail.filename
						}
					}
					else if (units.assets && units.assets.image) {
						image = API_URL.BucketURl + units.assets.image
					}
					let searchItem = {
						"title": res.development.details.name,
						"availStatus": units.availStatus,
						"unit_number": units.unit_number,
						"id": res.development._id,
						"unit_id": units._id,
						"floor": decafloor,
						"sales_price": units.cost_and_returns && units.cost_and_returns.sales_price ? units.cost_and_returns.sales_price : Math.round(100 / parseFloat(100 - discount) * units.cost_and_returns.investor_price),
						"discount": units.cost_and_returns?.discount ? units.cost_and_returns?.discount : 0,
						// "investor_price": units.cost_and_returns ?  parseInt(units.cost_and_returns.investor_price) : 0,
						"investor_price": units.cost_and_returns && units.cost_and_returns.investor_price ? parseInt(units.cost_and_returns.investor_price) : units.cost_and_returns && units.cost_and_returns.sales_price ? ((units.cost_and_returns.sales_price) - Math.round((discount / 100) * units.cost_and_returns.sales_price)) ?? 0 : 'Not defined',
						"reservation": units.cost_and_returns ? units.cost_and_returns.reservation : 0,
						"exchange": units.cost_and_returns ? units.cost_and_returns.exchange : 0,
						"completion": units.cost_and_returns ? units.cost_and_returns.completion : 0,
						"image": image,
						"url": "/development-details?unit=" + units._id,
						"area": units.floor_area_ft2 ? parseInt(units.floor_area_ft2) : '',
						"aream2": units.floor_area_m2 ? parseInt(units.floor_area_m2) : '',
						"status": units.status ? units.status : 'available',
						"status": units.status,
						"hold_by_me": units.hold_by_me,
						"type": units.property_type ? units.property_type.label : 'Apartment',
						'study': study,
						'balcony': balcony,
						"features": [
							{
								"icon": "fa fa-bed",
								"title": beds
							},
							{
								"icon": "fa fa-bath",
								"title": bath
							}
						],
						// "parking": false,
						"isMultiAgent": units.isMultiAgent ? units.isMultiAgent : false,
						"extraColumn": units?.extracolumn,
						"parkingSpaces": units?.parkingSpaces ?? [],
						"parkingPrice": parkingPrice,
						"parking": units?.parking ?? false,
						"parkingUnits": parkingUnits,
						"parkingPlan": parkingPlan,
						'totalParkingPrice': parkingPrice * (units?.parkingSpaces?.length ?? 0),
					}
					data.push(searchItem)
				})
			})
			yield put(getSearchSuccess(data));
		}
		else {
			yield put(getSearchFailure(response.data.message));
			document.getElementById('loaderImageSearch').style.display = 'none';
		}
	} catch (error) {
		console.log("error", error)
		yield put(getSearchFailure(error));
		document.getElementById('loaderImageSearch').style.display = 'none';
	}

}



/**
 * Ger Emails
 */
export function* getSearch() {
	yield takeEvery(SEARCH_UNIT, getSearchFromServer);
}

/**
 * Email Root Saga
 */
export default function* rootSaga() {
	yield all([
		fork(getSearch)
	]);
}