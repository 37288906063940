import React, { Component } from 'react';
import HeaderLogo from './global-components/header-logo';
import Banner from './section-components/banner';
import SearchForm from './section-components/search-form';
import SearchResult from './section-components/search-result';
import Developments from './section-components/developments';
import './section-components/custom.css'
import Footer from './global-components/footer';
import { connect } from 'react-redux';
// import { sendMailToUserWithAttachment } from '../api';
class SendMail extends Component{

	constructor(props){
		super(props);
		this.state={
		 type:'',
		 unit_number:'',
		 development:'',
		 pdf_file_name:'',
			getSenderData:{},
			user_profile:{}
		}
	}
    componentDidMount = () =>{
		console.log(this.props);
		const {state} = this.props.location;
		this.setState({type:state?.type, unit_number:state?.unit_number, 
			development: state?.development,
			pdf_file_name:state?.pdf_file_name,
			user_profile:state?.user_profile
		});
	}
	handleChangeForm = (event) => {
		const {getSenderData} = this.state;
		getSenderData[event.target.name] = event.target.value;
		this.setState({getSenderData});
	}
	SendMail = () => {
		const {getSenderData, type, unit_number, development, pdf_file_name, user_profile} = this.state;
		let getData = localStorage.getItem('tokenweb');
		var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if(getSenderData?.email != undefined){
			if(getSenderData?.email.match(mailformat)){
					let formData = {
				   senderName: getSenderData?.fullname ?? '',
				   senderMail: getSenderData?.email ?? '',
				   type : type,
				   unit_number : unit_number,
				   development : development,
				   agentname: `${user_profile?.firstName ?? ' '} ${user_profile?.lastName ?? ' '}`,
							agentphone: '',
							agentemail: `${user_profile?.email ?? ' '}`,
				   pdf_file_name:pdf_file_name 
					}
					// sendMailToUserWithAttachment(getData, formData).then((request) => {
					// 	console.log(request.data, ' ___request');
					// })
					// .catch((error) => {
					// 	console.log(error, ' ___error');
					// });
			}
			else{
				alert('Invalid email');
			}
		}
		else{
			alert('please enter email address');
		}
		console.log(getSenderData, type, unit_number, development)
	}
	render() {
		const { searchUnits, token , history} = this.props;
		return <div>
			<HeaderLogo props={history}/>
			<Banner history={history}/>
			<div className="container">
			<div className="section-title text-center margin-title">
				<h2 className="title">{'Send mail'}</h2>
			</div>
			<div className="rld-main-search">
				<div className="row">
					<div className="inline_conatiner">
						<div className="inline_view">
							<div>
							<input type="text" class="myForm" placeholder={'Enter Full Name'} name="fullname" value={this.state.getSenderData?.fullname ?? ''} onChange={this.handleChangeForm} required/>
							</div>
							<div>
							<input type="email" class="myForm" placeholder={'Enter email'} name="email" value={this.state.getSenderData?.email ?? ''} onChange={this.handleChangeForm} required/>
							</div>
							<div className="fg">
						<a onClick={(e )=> {this.SendMail()}} className="imgHold floorPlan">{'Submit'}</a>
					</div>
						</div>
					</div>
					</div>
			</div>
			</div>
			<Developments history={history}/>
			<Footer />
		</div>
	}
}


const mapStateToProps = (state) => {
	const { searchUnits } = state.search;
	const { user, token, userType } = state.authUser;
	return {
		searchUnits, user, token, userType
	};
}

export default connect(mapStateToProps)(SendMail);
