/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import {
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE

} from './types';

/**
 * Redux Action Get Search
 */
export const getNotification = (token) => ({
    type: GET_NOTIFICATION,
    payload: {  token: token}
});

/**
 * Redux Action Get Todos Success
 */
export const getNotificationSuccess = (response) => ({
    type: GET_NOTIFICATION_SUCCESS,
    payload: response
});

/**
 * Redux Action Get Todos Failure
 */
export const getNotificationFailure = (error) => ({
    type: GET_NOTIFICATION_FAILURE,
    payload: error
});


export const updateNotification = (token) => ({
    type: UPDATE_NOTIFICATION,
    payload: {  token: token}
});

/**
 * Redux Action Get Todos Success
 */
export const updateNotificationSuccess = (response) => ({
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: response
});

/**
 * Redux Action Get Todos Failure
 */
export const updateNotificationFailure = (error) => ({
    type: UPDATE_NOTIFICATION_FAILURE,
    payload: error
});
